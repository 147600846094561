import Cookies from 'js-cookie';

const localStorage = window.localStorage;
const host = window.location.hostname;

export const get = (key) =>
  !host.startsWith('localhost') ?
    Cookies.get(key) :
    localStorage.getItem(key)

export const set = (key, value) =>
  !host.startsWith('localhost') ?
    Cookies.set(key, value, {domain: host}) :
    localStorage.setItem(key, value)

export const remove = (key) =>
  !host.startsWith('localhost') ?
    Cookies.remove(key, {domain: host}) :
    localStorage.removeItem(key)
