import React, { useState, useContext } from "react";
import styled from "styled-components";

import HealthInfo from "../components/Info/HealthInfo";
import Lifestyle from "../components/Info/Lifestyle";
import Name from "../components/PatientNameLink";
import PersonalInfo from "../components/Info/PersonalInfo";
import PatientPresentation from "../components/Info/PatientPresentation";
import Stress from "../components/Info/Stress";

import PatientContext from "../context/patientContext";
import PatientInfoContext from "../context/patientInfoContext";
import NotificationContext from "../context/notificationContext";

import colors from "../config/colors";
import fonts from "../config/fonts";

const PatientInfo = () => {
  const [openedCategories, setOpenedCategories] = useState(["Personal information"]);
  const [editingCategories, setEditingCategories] = useState([]);

  const notificationContext = useContext(NotificationContext);

  const opened = (cat) => openedCategories.includes(cat);
  const triggerOpening = (cat) =>
    opened(cat)
      ? setOpenedCategories(openedCategories.filter((c) => c !== cat))
      : setOpenedCategories([...openedCategories, cat]);

  const editing = (cat) => editingCategories.includes(cat);
  const triggerEditing = (cat) => {
    if (editing(cat)) {
      setEditingCategories(editingCategories.filter((c) => c !== cat));
      notificationContext.onNotify("Information saved");
    } else {
      setEditingCategories([...editingCategories, cat]);
    }
  };

  const getHeader = (category) => {
    const isOpen = opened(category);
    const isEditing = editing(category);

    return (
      <Clickable>
        <TitleDiv onClick={() => triggerOpening(category)}>
          <CategoryTitle>
            <i className={`fa fa-chevron-${isOpen ? "down" : "right"}`} />
            &nbsp;&nbsp;
            {category}
          </CategoryTitle>
        </TitleDiv>

        <Actions>
          <Edit onClick={() => triggerEditing(category)}>
            <i className={`fas fa-${isEditing ? "save" : "pen"}`} />
            &nbsp;&nbsp;
            {isEditing ? "save " : "edit"}
          </Edit>
        </Actions>
      </Clickable>
    );
  };

  const { firstname, lastname } = useContext(PatientContext);

  const contextValues = {
    getHeader,
    opened,
    editing,
    Container: CategoryContainer,
    InfoContainer,
    InfoDiv,
    InfoDivBig,
    InfoEdit,
    InfoEditBig,
    Info,
    Text,
    SubTitle,
  };

  return (
    <Container>
      {firstname && lastname && (
        <Title>
          <Name postfix="'s" />
          &nbsp;information
        </Title>
      )}

      <PatientInfoContext.Provider value={contextValues}>
        <PersonalInfo />
        <PatientPresentation />
        <Lifestyle />
        <HealthInfo />
        <Stress />
      </PatientInfoContext.Provider>
    </Container>
  );
};

const Container = styled.div`
  flex: 1;
  overflow-x: hidden;
  min-height: 100vh;
  margin: 1em 2em;
`;

const Title = styled.h1`
  font-family: ${fonts.secondaryBold};
  font-size: 3em;
  letter-spacing: -0.03em;
  margin-bottom: 2em;
`;

// PatientInfo context components
const Edit = styled.div`
  font-size: 1.2em;
  color: ${colors.blue};
  font-family: ${fonts.primary};
  flex: 0.5;
`;

const Actions = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: center;
`;

const Clickable = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  &:hover {
    cursor: pointer;
  }
`;

const CategoryContainer = styled.div`
  color: ${colors.black};
`;

const Info = styled.span`
  color: ${colors.black};
  font-family: ${fonts.primaryBold};
  font-size: 1.1em;
  white-space: pre-line;
`;

const InfoContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  padding: 0.5em 2em;
  margin-left: 2.2em;
`;

const InfoDiv = styled.div`
  display: flex;
  flex-grow: 2;
  flex-shrink: 1;
  flex-basis: 40%;
  flex-flow: row wrap;
`;

const InfoDivBig = styled.div`
  display: flex;
  flex-grow: 2;
  flex-shrink: 1;
  flex-basis: 40%;
  flex-flow: column;

  > span {
    padding: 0.2em 0.5em;
    margin-right: 2em;
    margin-bottom: 2em;
    border: 1px solid rgba(0,0,0,.2);
    min-height: 2em;
    border-radius: 0.3em;
  }
`;

const InfoEdit = styled.div`
  display: flex;
  flex-basis: 40%;
  flex-direction: row;
  flex-grow: 2;
  flex-shrink: 1;
  justify-content: space-between;
  align-items: center;
  padding: 0.5em 4em 0.5em 0;
`;

const InfoEditBig = styled.div`
  display: flex;
  flex-basis: 40%;
  flex-direction: column;
  flex-grow: 2;
  justify-content: flex-start;
  padding: 0.5em 4em 0.5em 0;
`;

const Text = styled.div`
  font-family: ${fonts.primary};
  font-size: 1.1em;
  padding-right: 0.5em;
  white-space: nowrap;
  max-width: 100%;
  text-overflow: ellipsis;
  flex: 0 1 10%;
`;

const CategoryTitle = styled.h2`
  font-family: ${fonts.primaryBold};
  letter-spacing: -0.02em;
  font-size: 2em;
  padding-left: 1em;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  & i {
    width: 0.7em;
    vertical-align: middle;
    font-size: 0.8em;
    color: ${colors.blue};
  }
`;

const TitleDiv = styled.div`
  flex: 9;
`;

const SubTitle = styled.div`
  font-family: ${fonts.primaryBold};
  letter-spacing: -0.02em;
  text-transform: uppercase;
  font-size: 1em;
  padding: 1em 0;
  margin-top: 1em;
  width: 100%;
`;

export default PatientInfo;
