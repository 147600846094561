import React, { useContext } from "react";

import styled from "styled-components";
import TextareaAutosize from 'react-autosize-textarea';

import PatientContext from "../context/patientContext";

import colors from "../config/colors";
import fonts from "../config/fonts";

const ConnectedInput = ({ dbKey, textarea, ...props }) => {
  const patient = useContext(PatientContext);

  return textarea ?
    <TextareaAutosize
      style={TextArea}
      rows={3}
      maxRows={10}
      onResize={(e) => console.log(e)}
      onChange={(e) => patient.edit(dbKey, e.target.value)}
      {...props}
    >{patient[dbKey]}</TextareaAutosize>
   : <Info
      value={patient[dbKey]}
      onChange={(e) => patient.edit(dbKey, e.target.value)}
      {...props}
    />;
};

const Info = styled.input`
  border: none;
  border-bottom: 1px ${colors.black} solid;
  color: ${colors.black};
  font-family: ${fonts.primaryBold};
  flex: 1 1 55%;
  margin: 0 0em;
  padding: 0.1em 0.3em;

  :disabled {
    background-color: ${colors.lightGrey};
  }
`;

const TextArea = {
  border: `1px ${colors.black} solid`,
  borderRadius: "0.3em",
  color: colors.black,
  fontFamily: fonts.primaryBold,
  margin: 0,
  height: "auto",
  padding: "0.1em 0.3em",
};

export default ConnectedInput;
