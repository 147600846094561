import React, { useContext } from "react";
import styled from "styled-components";

import Card from "../components/DetailsCard";
import colors from "../config/colors";
import fonts from "../config/fonts";
import Loading from "./Loading";
import Name from "../components/PatientNameLink";
import PatientContext from "../context/patientContext";

const Conditions = () => {
  const { id, reports, deleteReport } = useContext(PatientContext);

  const filteredReports = reports.filter((r) => r.submittedBy === "patient");

  const handleDelete = (timestamp) => {
    deleteReport(timestamp, () => console.log(""));
  };

  if (!reports) return <Loading />;

  return (
    <Container>
      <Title>
        <Name postfix="'s" />
        &nbsp;condition reports
      </Title>

      <hr />

      {filteredReports.map((report, index) => (
        <Card
          key={index}
          index={index}
          data={report}
          id={id}
          type={"reports"}
          handleEdit={() => console.log("edit")}
          handleDelete={() => handleDelete(report.timestamp)}
        />
      ))}
    </Container>
  );
};

const Container = styled.div`
  flex: 1;
  overflow-x: hidden;
  min-height: 100vh;
  padding: 1em 2em;
`;

const Title = styled.h1`
  color: ${colors.black};
  font-family: ${fonts.secondaryBold};
  letter-spacing: -0.03em;
  font-size: 3em;
  margin-bottom: 0.5em;
`;
export default Conditions;
