const drawingColors = {
  green: "#5AAF78",
  greenDark: "#3C7C52",
  red: "#d4213c",
  redDark: "#9f192d",
  yellow: "#f9c526",
  yellowDark: "#DBA506",
};

export default drawingColors;
