import React, { useContext, useState } from "react";
import styled from "styled-components";

import Button from "../components/Button";
import colors from "../config/colors";
import fonts from "../config/fonts";
import PatientContext from "../context/patientContext";
import * as API from "../API";

const PersonnummerModal = ({ onClose }) => {
  const {
    firstname,
    lastname,
    personnummer,
    id,
  } = useContext(PatientContext);

  const [pn, setPn] = useState(personnummer);

  const handlePnUpdate = () => {
    API.updatePersonnummer(id, pn).then(() => onClose(pn))
  };

  return (
    <Container>
      <Modal>
        { personnummer && <div><strong>Note:</strong> After the change, {firstname} {lastname} will be disconnected from the app.</div> }
        <div>
          Indicate {firstname} {lastname}'s personnummer:
          <Input value={pn} onChange={(e) => setPn(e.target.value)} />
        </div>
        <ButtonContainer>
          <Button
            color={colors.grey}
            darkColor={colors.darkGrey}
            textColor={colors.white}
            textSize="1.05rem"
            title="Cancel"
            onClick={() => onClose(personnummer)}
          />
          <Spacer />
          <Button
            color={colors.green}
            darkColor={colors.darkGreen}
            textColor={colors.white}
            textSize="1.05rem"
            title="Save"
            onClick={() => handlePnUpdate()}
          />
        </ButtonContainer>
      </Modal>
    </Container>
  );
};

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.8);
`;

const Modal = styled.div`
  min-width: 400px;
  min-height: 300px;
  background: white;
  border: 1px solid #333;
  border-radius: 5px;
  padding: 1em 2em;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
`;

const Spacer = styled.div`
  min-width: 20px;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: space-around;
  width: 80%;
`;

const Input = styled.input`
  border: none;
  border-bottom: 1px ${colors.black} solid;
  color: ${colors.black};
  font-family: ${fonts.primaryBold};
  flex: 1 1 55%;
  margin: 0 0em;
  padding: 0.1em 0.3em;

  :disabled {
    background-color: ${colors.lightGrey};
  }
`;


export default PersonnummerModal;
