import React, { useState, useContext } from "react";
import styled from "styled-components";
import DatePicker from "react-datepicker";
import ReactTags from "react-tag-autocomplete";
import { useHistory } from "react-router-dom";
import TextareaAutosize from 'react-autosize-textarea';

import Button from "../components/Button";
import Drawer from "../components/DrawArea";
import colors from "../config/colors";
import fonts from "../config/fonts";
import timeConverter from "../config/timeConverter";
import Name from "../components/PatientNameLink";
import NotificationContext from "../context/notificationContext";
import PatientContext from "../context/patientContext";
import TherapistContext from "../context/therapistContext";
import "react-datepicker/dist/react-datepicker.css";

function viewportToPixels(value) {
  var parts = value.match(/([0-9.]+)(vh|vw)/);
  var q = Number(parts[1]);
  var side =
    window[["innerHeight", "innerWidth"][["vh", "vw"].indexOf(parts[2])]];
  return side * (q / 100);
}

function escapeForRegExp(string) {
  return string.replace(/[-\\^$*+?.()|[\]{}]/g, "\\$&");
}

function matchAny(string) {
  return new RegExp(escapeForRegExp(string), "gi");
}

const NewVisitReport = ({ match }) => {
  const edit = match.params.timestamp !== undefined;
  const now = edit ? new Date(parseInt(match.params.timestamp) * 1000) : new Date();
  const history = useHistory();
  const notificationContext = useContext(NotificationContext);
  const { visits, reports, addVisit, editVisit, deleteVisit, sendMessengerNotif } = useContext(PatientContext);
  const editPatientInfo = useContext(PatientContext).edit;
  const { firstname } = useContext(TherapistContext);

  const lastVisitTags =
    visits && visits[0] && visits[0].tags
      ? visits[0].tags.map((t) => ({
          name: t,
        }))
      : [];

  const currentVisit = edit ? visits.find(v => v.timestamp === match.params.timestamp) : {
    reason: "",
    additionalInfo: "",
    treatment: "",
    postTreatment: "",
    futureThoughts: "",
  }

  const currentVisitTags = currentVisit.tags?.length > 0 ?
    currentVisit.tags.map(t => ({ name: t })) : []

  const currentReport =  reports.find(r =>
    r.timestamp === match.params.timestamp && r.submittedBy === "therapist"
  )

  const currentPaths = currentReport ? currentReport.paths.map(p =>
    ({...p.path, points: p.path.data.map(coords => ({x: coords.split(',')[0], y: coords.split(',')[1] }))})
  ) : []

  const [timestamp, setTimestamp] = useState(
    now.getTime().toString().substr(0, 10)
  );
  const [date, setDate] = useState(now);
  const [reason, setReason] = useState(currentVisit.reason);
  const [additionalInfo, setAdditionalInfo] = useState(currentVisit.additionalInfo);
  const [treatment, setTreatment] = useState(currentVisit.treatment);
  const [postTreatment, setPostTreatment] = useState(currentVisit.postTreatment);
  const [futureThoughts, setFutureThoughts] = useState(currentVisit.futureThoughts);
  const [tags, setTags] = useState(edit ? currentVisitTags : lastVisitTags)
  const [paths, setPaths] = useState(currentPaths);

  const reactTags = React.createRef();

  const suggestions = Array.from(
    new Set([
      ...[
        "Left Neck",
        "Right Neck",
        "Neck Front",
        "Neck Back",
        "Upper Back",
        "Mid Back",
        "Lower Back",
        "Chest",
        "Left Shoulder",
        "Right Shoulder",
        "Left Upper Arm",
        "Right Upper Arm",
        "Left Lower Arm",
        "Right Lower Arm",
        "Left Elbow",
        "Right Elbow",
        "Left Wrist",
        "Right Wrist",
        "Left Hand",
        "Right Hand",
        "Hips",
        "Left Upper Leg",
        "Right Upper Leg",
        "Left Lower Leg",
        "Right Lower Leg",
        "Left Knee",
        "Right Knee",
        "Left Anke",
        "Right Anke",
        "Left Foot",
        "Right Foot",
        "Left Hip",
        "Right Hip",
      ],
      ...visits.flatMap((v) => (v.tags ? v.tags : [])),
    ])
  ).map((s) => ({ name: s }));

  const handleDelete = () => {
    if (window.confirm("Are you sure you want to delete this visit report?")) {
      deleteVisit(match.params.timestamp).then(() =>
        history.push(`/patients/${match.params.id}/timeline`)
      );
    }
  };

  const submitReport = async () => {
    const newVisit = {
      timestamp,
      reason,
      treatment,
      additionalInfo,
      postTreatment,
      futureThoughts,
      tags: Array.from(new Set(tags.map((tag) => tag.name))),
    };

    const newReport = {
      timestamp,
      submittedBy: "therapist",
      paths: paths.map((p) => ({
        drawer: null,
        id: Math.floor(Math.random() * 100000000),
        size: {
          width: document.getElementById("drawArea").clientWidth,
          height: document.getElementById("drawArea").clientHeight,
        },
        path: {
          width: viewportToPixels("0.6vh"),
          color: p.color,
          data: p.points.map((point) => `${point.x},${point.y}`),
        },
      })),
    };

    if (edit) {
      history.push(`/patients/${match.params.id}`);

      editVisit(match.params.timestamp, newVisit, newReport).then(() => {
        notificationContext.onNotify("Visit report saved");
      });
    } else {
      addVisit(newVisit, newReport).then(() => {
        editPatientInfo('newPatient', false)
        sendMessengerNotif(
          `You just visited ${firstname}`,
          `/visits/${timestamp}`,
          `Read the report`
        )
        history.push(`/patients/${match.params.id}`);
        notificationContext.onNotify("Visit report saved");
      });
    }
  };

  return (
    <Container>
      { edit && <Title>
        <Name postfix="'s" />
        &nbsp;visit of {timeConverter(match.params.timestamp)}
      </Title> ||
      <Title>
        New visit report for <Name />
      </Title> }

      <Drawer paths={paths} setPaths={setPaths} />

      <br />
      <br />

      <InputArea>
        <InputSubContainer>
          <SubTitle>Reason of the visit</SubTitle>
          <TextareaAutosize
            style={TextArea}
            rows={4}
            onChange={(e) => setReason(e.target.value)}
            value={reason}
          />
        </InputSubContainer>

        <InputSubContainer>
          <SubTitle>Additional information</SubTitle>
          <TextareaAutosize
            style={TextArea}
            rows={4}
            onChange={(e) => setAdditionalInfo(e.target.value)}
            value={additionalInfo}
          />
        </InputSubContainer>
      </InputArea>
      <hr />
      <InputArea>
        <InputSubContainer>
          <SubTitle>Treatment plan</SubTitle>
          <TextareaAutosize
            style={TextArea}
            rows={4}
            onChange={(e) => {
              setTreatment(e.target.value);
            }}
            value={treatment}
          />
        </InputSubContainer>

        <InputSubContainer>
          <SubTitle>Post-treatment evaluation</SubTitle>
          <TextareaAutosize
            style={TextArea}
            rows={4}
            onChange={(e) => {
              setPostTreatment(e.target.value);
            }}
            value={postTreatment}
          />
        </InputSubContainer>
      </InputArea>
      <hr />
      <InputArea>
        <InputSubContainer>
          <SubTitle>Future thoughts</SubTitle>
          <TextareaAutosize
            style={TextArea}
            rows={4}
            onChange={(e) => {
              setFutureThoughts(e.target.value);
            }}
            value={futureThoughts}
          />
        </InputSubContainer>
        <TagContainer>
          <SubTitle>Tags for the patient</SubTitle>
          <TagsDiv>
            <ReactTags
              allowBackspace
              allowNew
              ref={reactTags}
              tags={tags}
              placeholderText="Add a tag"
              suggestions={suggestions}
              suggestionComponent={({ item, query }) => {
                return (
                  <span
                    id={item.id}
                    className={item.name === query ? "match" : "no-match"}
                  >
                    {item.name}
                  </span>
                );
              }}
              onAddition={(tag) => {
                const regexp = matchAny(tag.name);
                const matchingSug = suggestions.filter((s) =>
                  regexp.test(s.name)
                );

                if (matchingSug.length > 0) {
                  setTags([...tags, matchingSug[0]]);
                } else {
                  setTags([...tags, tag]);
                }
              }}
              onDelete={(tag) => setTags(tags.filter((t, i) => i !== tag))}
            />
            <div style={{ flexGrow: "2" }} />
          </TagsDiv>
          <br />
          <SubTitle>Date</SubTitle>
          <DatePicker
            className="new-visit-date-picker"
            selected={date}
            dateFormat="yyyy-MM-dd"
            onChange={(newDate) => {
              setDate(newDate);
              setTimestamp(newDate.getTime().toString().substr(0, 10));
            }}
          />
        </TagContainer>
      </InputArea>
      <hr />
      <BottomContainer>
            { edit && <>
              <Button
                color={colors.copperRed}
                darkColor={colors.darkCopperRed}
                height="2em"
                onClick={handleDelete}
                textColor={colors.white}
                textSize="1.55em"
                title="Delete"
                width="12em"
              />
              <div style={{width: "2em"}} />
            </> }
            <Button
              color={colors.green}
              darkColor={colors.darkGreen}
              height="2em"
              onClick={() => submitReport()}
              textColor={colors.white}
              textSize="1.55em"
              title="Submit"
              width="12em"
            />
      </BottomContainer>
    </Container>
  );
};

const Container = styled.div`
  color: ${colors.black};
  flex: 1;
  min-height: 80vh;
  margin: 1em 2em;
`;

const InputSubContainer = styled.div`
  margin-bottom: 1em;
  width: 48%;

  @media all and (max-width: 1050px) {
    width: 100%;
  }
`;

const InputArea = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
`;

const BottomContainer = styled.div`
  width: 100%;
  display: flex;
  margin: 2em 0;
  align-items: center;
  justify-content: center;
`;

const TagContainer = styled.div`
  width: 48%;

  @media all and (max-width: 1050px) {
    width: 100%;
  }
`;

const SubTitle = styled.h2`
  font-family: ${fonts.primary};
  font-size: 1.3em;
  margin-bottom: 0.1em;
`;

const TagsDiv = styled.div`
  display: flex;
  flex-flow: row wrap;
`;

const TextArea = {
  border: `1px ${colors.black} solid`,
  width: "100%",
  borderRadius: "0.3em",
  color: colors.black,
  fontFamily: fonts.primaryBold,
  margin: 0,
  height: "auto",
  padding: "0.1em 0.3em",
  fontSize: "1.2em",
};

const Title = styled.h1`
  font-family: ${fonts.secondaryBold};
  font-size: 3em;
  letter-spacing: -0.03em;
  margin-bottom: 2vh;
`;

export default NewVisitReport;
