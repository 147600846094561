import React, { useContext } from "react";
import styled from "styled-components";

import Button from "../components/Button";
import Link from "../components/Link";
import colors from "../config/colors";
import fonts from "../config/fonts";
import Loading from "./Loading";
import Name from "../components/PatientNameLink";
import PatientContext from "../context/patientContext";
import timeConverter from "../config/timeConverter";
import BodyImage from "../components/BodyImage";
import { API_HOST } from "../API";

const TimeLine = () => {
  const {
    id,
    firstname,
    lastname,
    visits,
    workouts,
    reports,
  } = useContext(PatientContext);

  const events = [
    ...visits.map((v) => ({ timestamp: v.timestamp, type: "visit" })),
    ...reports
      .filter((r) => r.submittedBy === "patient")
      .map((r) => ({ timestamp: r.timestamp, type: "report" })),
    ...workouts.map((w) => ({
      timestamp: w.timestamp,
      type: "workout",
      id: w.id,
    })),
  ].sort((a, b) => parseInt(b.timestamp) - parseInt(a.timestamp));

  if (!visits) return <Loading />;

  const displayVisit = (visit) => (
    <Card>
      <CardHeader>
        <TimelineTitle>{timeConverter(visit.timestamp)}</TimelineTitle>
        <Link
          style={ShowButton}
          hoverStyle={{ textDecoration: "underline" }}
          to={`/patients/${id}/edit/visits/${visit.timestamp}`}
        >
          (edit)
        </Link>
      </CardHeader>

      <CardSubContainer>
        <CardLeftColumn>
          <BodyImage
            report={reports.find((r) => r.timestamp === visit.timestamp)}
            to={`/patients/${id}/edit/visits/${visit.timestamp}`}
          />
        </CardLeftColumn>
        <CardRightColumn>
          {visit.reason && (
            <Paragraph>
              <TimelineTitle>Reason of the visit:</TimelineTitle>
              {visit.reason}
            </Paragraph>
          )}
          {visit.additionalInfo && (
            <Paragraph>
              <TimelineTitle>Additional information:</TimelineTitle>
              {visit.additionalInfo}
            </Paragraph>
          )}
          {visit.treatment && (
            <Paragraph>
              <TimelineTitle>Treatment plan:</TimelineTitle>
              {visit.treatment}
            </Paragraph>
          )}
          {visit.postTreatment && (
            <Paragraph>
              <TimelineTitle>Post-treatment evaluation:</TimelineTitle>
              {visit.postTreatment}
            </Paragraph>
          )}
          {visit.futureThoughts && (
            <Paragraph>
              <TimelineTitle>Future thoughts:</TimelineTitle>
              {visit.futureThoughts}
            </Paragraph>
          )}
          {visit.tags?.length > 0 && (
            <Paragraph>
              <TimelineTitle>Tags:</TimelineTitle>
              {visit.tags.join(", ")}
            </Paragraph>
          )}
        </CardRightColumn>
      </CardSubContainer>
    </Card>
  );

  return (
    <Container>
      {firstname && lastname ? (
        <TitleDiv>
          <Title>
            <Name postfix="'s" />
            &nbsp;timeline
          </Title>
          <ButtonDiv>
            <Button
              color={colors.green}
              darkColor={colors.darkGreen}
              link={`/patients/${id}/visits/new`}
              textColor={colors.white}
              textSize="1.05rem"
              icon="file-alt"
              title="New visit report"
              width="13rem"
            />
            <Button
              color={colors.copperRed}
              darkColor={colors.darkCopperRed}
              link={`/patients/${id}/workouts/upload`}
              icon="dumbbell"
              textColor={colors.white}
              textSize="1.05rem"
              title="Upload workout"
              width="13rem"
            />
          </ButtonDiv>
        </TitleDiv>
      ) : (
        <Title />
      )}

      {events.map((event, index) => {
        const visit = event.type === "visit" ?
          visits.find((v) => v.timestamp === event.timestamp) : null
        const workout = event.type === "workout" ?
          workouts.find((w) => w.timestamp === event.timestamp) : null
        const workoutLink = workout ? workout.type === "youtube" ?
          `https://www.youtube.com/watch?v=${workout.youtubeID}` :
          `${API_HOST}/uploads/${id}/${workout.data.md5}${workout.data.ext}`
        : null

        return <div key={index}>
          {index !== 0 && <VerticalLine />}
          {event.type === "visit" && displayVisit(visit)}
          {event.type === "report" && (
            <LineDiv>
              <HorizontalLine />
              <Dot />
              <Event>
                <TimelineTitle>{timeConverter(event.timestamp)}</TimelineTitle>
                <Bold>{firstname}</Bold>&nbsp;reported a condition update&nbsp;
                <Link
                  style={ShowButton}
                  hoverStyle={{ textDecoration: "underline" }}
                  to={`/patients/${id}/conditions/${event.timestamp}`}
                >
                  (show)
                </Link>
              </Event>
            </LineDiv>
          )}
          { event.type === "workout" && (
            <LineDiv>
              <HorizontalLine />
              <Dot />
              <Event>
                <TimelineTitle>{timeConverter(event.timestamp)}</TimelineTitle>
                You uploaded a workout&nbsp;
                <OpenLink href={workoutLink} target="_blank">
                  (show)
                </OpenLink>
              </Event>
            </LineDiv>
          )}
        </div>
      })}
    </Container>
  );
};

const Card = styled.div`
  border: 1px solid ${colors.black};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  font-family: ${fonts.primary};
  padding: 1.5em 1.8em;
`;

const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

const CardLeftColumn = styled.div`
  flex: 1 0 300px;
  margin-right: 1em;
`;

const CardRightColumn = styled.div`
  flex: 4;
`;

const CardSubContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 1.2em;
`;

const ButtonDiv = styled.div`
  display: flex;
  flex-direction: row wrap;
  min-height: 4em;
  align-items: center;

  > div:first-child {
    margin: 0 0.8em 0 0;
  }

  @media (max-width: 1050px) {
    flex-flow: column wrap;

    > div:first-child {
      margin: 0 0 0.8em 0;
    }
  }
`;

const Container = styled.div`
  flex: 1;
  overflow-x: hidden;
  min-height: 100vh;
  padding: 1em 2em;
`;

const TimelineTitle = styled.div`
  font-family: ${fonts.primary};
`;

const OpenLink = styled.a`
  color: ${colors.blue};

  &:hover {
    color: ${colors.blue};
    cursor: pointer;
    text-decoration: underline;
  }
`;

const ShowButton = {
  color: colors.blue,
};

const Dot = styled.div`
  background-color: ${colors.black};
  width: 0.5em;
  height: 0.5em;
  border-radius: 0.5em;
`;

const Event = styled.div`
  padding-left: 1em;
`;

const HorizontalLine = styled.div`
  border-bottom: solid 1px ${colors.black};
  width: 2em;
`;

const Title = styled.h1`
  color: ${colors.black};
  font-family: ${fonts.secondaryBold};
  letter-spacing: -0.03em;
  font-size: 3em;
`;

const TitleDiv = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 2em;
`;

const VerticalLine = styled.div`
  border-left: solid 1px ${colors.black};
  margin-left: 1em;
  padding-bottom: 1.5em;
`;

const LineDiv = styled.div`
  align-items: center;
  border-left: solid 1px ${colors.black};
  font-family: ${fonts.primary};
  display: flex;
  margin-left: 1em;
  padding: 2em 0;
`;

const Paragraph = styled.div`
  margin-bottom: 1rem;
  white-space: pre-line;
  font-family: ${fonts.primaryBold};
`

const Bold = styled.span`
  font-family: ${fonts.primaryBold};
`

export default TimeLine;

