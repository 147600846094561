import { React, useEffect, useState } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import styled from "styled-components";
import { useHistory, useLocation } from "react-router-dom";

import Login from "./pages/Login";
import Loading from "./pages/Loading";
import NavBar from "./components/NavBar";
import NotFound from "./pages/NotFound";
import NotificationContext from "./context/notificationContext";
import TherapistContext from "./context/therapistContext";
import Booking from "./pages/Booking";
import PublicBooking from "./pages/PublicBooking";
import ManageBooking from "./pages/ManageBooking";
import NewPatient from "./pages/NewPatient";
import Patient from "./pages/Patient";
import PatientList from "./pages/PatientList";
import Profile from "./pages/Profile";
import "./App.css";
import * as DB from "./DB";
import * as FB from "./Facebook";
import * as API from "./API";
import * as Storage from "./Storage";

function App() {
  const [therapist, setTherapist] = useState({});
  const [patients, setPatients] = useState([]);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const location = useLocation();

  //TODO: Handle error messages as well
  //TODO: migrate to Sweetalert2
  const handleNotifications = (message) => {
    NotificationManager.success(message, "", 2000);
  };

  const updateTherapistsAndUsers = () =>
    DB.getTherapistInfo()
      .then((info) => {
        setTherapist(info);
        return API.getUserList(info.id);
      })
      .then((list) => {
        setPatients(list);
        setIsLoggedIn(true);
        if (location?.redirectTo) {
          history.push(location.redirectTo);
        }
      })
      .catch((e) => handleNotifications("Your session expired"))
      .finally(() => setLoading(false));

  const logout = () => {
    Storage.remove("Session");
    Storage.remove("Name");
    FB.clearToken();
    setIsLoggedIn(false);
    setPatients([]);
    history.push("/login");
    handleNotifications("Logged out");
  };

  useEffect(() => {
    if (Storage.get("Name")) {
      setLoading(true);
      updateTherapistsAndUsers();
    } else {
      setIsLoggedIn(false);
      setLoading(false);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (loading) return <Loading />;

  if (!isLoggedIn) {
    if (location.pathname.startsWith('/public') || location.pathname.startsWith('/b/')) {
      return (
        <PublicContainer>
          <Switch>
            <Route path="/public/booking/:therapistPublicName/:initialView/:initialDate" component={PublicBooking} />
            <Route path="/public/booking/:therapistPublicName" component={PublicBooking} />
            <Route path="/b/:bookingId" component={ManageBooking} />
          </Switch>
        </PublicContainer>
      );
    } else {
      if (location.pathname !== "/login") {
        history.push({ pathname: "/login", redirectTo: location.pathname });
      }

      return (
        <LoginContainer>
          <Login afterLogin={updateTherapistsAndUsers} />
        </LoginContainer>
      );
    }
  }

  document.title = "Webster Admin"

  const contextValues = {
    ...therapist,
    setTherapist,
    logout,
    patients,
    updateTherapistsAndUsers,
  }

  return (
    <Container>
      <TherapistContext.Provider value={contextValues}>
        <NotificationContext.Provider value={{ onNotify: handleNotifications }}>
          <NavBar logout={logout} />
          <NotificationContainer />
          <Switch>
            <Route path="/booking/:initialView/:initialDate" component={Booking} />
            <Route path="/booking" component={Booking} />
            <Route path="/patients/new" component={NewPatient} />
            <Route path="/patients/:id" component={Patient} />
            <Route path="/profile/" component={Profile} />
            <Route path="/patients" component={PatientList} />
            <Route path="/" component={() => <Redirect to="/patients" />} />
            <Route path="/not-found" component={NotFound} />
            <Redirect to="/" />
          </Switch>
        </NotificationContext.Provider>
      </TherapistContext.Provider>
    </Container>
  );
}

const LoginContainer = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: row;
  align-items: middle;
  justify-content: space-between;
`;

const Container = styled.div`
  align-items: stretch;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 1900px;
  min-height: 100vh;
`;

const PublicContainer = styled.div`
  align-items: stretch;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 1900px;
  min-height: 100vh;
`;

export default App;
