export const API_HOST = process.env.REACT_APP_API_HOST || "http://localhost:5000";

const jsonOrReject = (r) => {
  const contentType = r.headers.get('Content-Type')
  if (contentType && contentType.startsWith('application/json')) {
    return r.json().then(json => {
      if (r.status > 399) {
        return Promise.reject({ status: r.status, msg: json })
      } else {
        return json
      }
    })
  } else {
    return r.text().then(text => {
      if (r.status > 399) {
        return Promise.reject({ status: r.status, msg: text })
      } else {
        return text
      }
    })
  }
}

export const login = (email, password) => {
  return fetch(`${API_HOST}/login`, {
    credentials: 'include',
    method: "POST",
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ email, password }),
  })
  .then(jsonOrReject)
}

export const getUserList = (therapistId) => {
  return fetch(`${API_HOST}/patients`, {
    credentials: 'include',
  })
  .then(jsonOrReject)
  //return fetch(`${API_HOST}/patients/${therapistId}`, {
  //  credentials: 'include',
  //})
  //.then(jsonOrReject)
};

export const registerNewPatient = (fields) => {
  return fetch(`${API_HOST}/patients`, {
    method: "POST",
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(fields),
  })
  .then(jsonOrReject)
  .then(json => json.name)
};

export const uploadToPatient = (file, patientID) => {
   const fd = new FormData();
   fd.append('file', file);

  return fetch(`${API_HOST}/upload/${patientID}`, {
    method: "POST",
    credentials: 'include',
    body: fd
  })
  .then(jsonOrReject)
};

export const sendMessenger = (fields) => {
  return fetch(`${API_HOST}/messenger`, {
    method: "POST",
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(fields),
  })
  .then(jsonOrReject)
  .then(json => json.name)
};

export const getClinicBuddyID = (firstname, lastname) => {
  return fetch(`${API_HOST}/cb/search/${firstname}/${lastname}`, {
    method: "GET",
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
  })
  .then(res => res.ok ? res.text() : Promise.resolve(undefined))
};

export const getNextAppointment = (cbID) => {
  return fetch(`${API_HOST}/cb/next-treatment/${cbID}`, {
    method: "GET",
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
  })
  .then(res => res.ok ? res.text() : Promise.resolve(undefined))
};

export const includeClinicBuddy = async (p) => {
  if (p.firstname && p.lastname) {
    const cbID = p.cbID || await getClinicBuddyID(p.firstname, p.lastname)
    const nextAppointment = cbID ? await getNextAppointment(cbID) : undefined

    return { ...p, nextAppointment, cbID }
  } else {
    return p
  }
};

export const updatePersonnummer = (id, personnummer) => {
  return fetch(`${API_HOST}/patient/${id}/personnummer`, {
    method: "PUT",
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ personnummer }),
  })
  .then(jsonOrReject)
};

export const updatePatientInfo = (id, newFields) => {
  return fetch(`${API_HOST}/patient/${id}/info`, {
    method: "PUT",
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ newFields }),
  })
  .then(jsonOrReject)
};

export const getColleagues = (therapist) => {
  return fetch(`${API_HOST}/colleagues/${therapist}`, {
    method: "GET",
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
  })
  .then(jsonOrReject)
};

export const getColleagueBookings = (colleague, startStr, endStr) => {
  return fetch(`${API_HOST}/bookings/${colleague}?i${
    startStr ? `&start=${startStr}`: ''}${
    endStr   ? `&end=${endStr}` : ''}`, {
      method: "GET",
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
    })
    .then(jsonOrReject)
};


export const notifyPatient = (patientID, subject, message) => {
  return fetch(`${API_HOST}/patient/${patientID}/notify`, {
    method: "POST",
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ subject, message }),
  })
  .then(jsonOrReject)
};
