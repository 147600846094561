import * as Storage from "./Storage";
import PouchDB from "pouchdb-browser";
import find from "pouchdb-find";

PouchDB.plugin(find);

const { REACT_APP_DB_AUTH, REACT_APP_DB_ROLES } = process.env;

const DefaultOpts = () => {
  const therapist = Storage.get('Name')

  return REACT_APP_DB_AUTH ? { headers: {
    'X-Auth-CouchDB-UserName': therapist,
    'X-Auth-CouchDB-Token': REACT_APP_DB_AUTH,
    'X-Auth-CouchDB-Roles': REACT_APP_DB_ROLES,
  }} : { credentials: 'include' }
}

const jsonOrReject = (r) => {
  return r.json().then(json =>
    r.status > 399 ?
      Promise.reject({ status: r.status, msg: json }) :
      json
  ).catch(e => Promise.reject(r))
}

export const DB_HOST = process.env.REACT_APP_DB_HOST || "https://db.dinsdag.se";

export let RemoteUserPouch = null;
export let LocalUserPouch = null;

export let pushHandler = null;
export let pullHandler = null;
export let pullTimeout = null;

let currentOperation = new Promise((resolve, reject) => resolve());
let currentPushOperation = new Promise((resolve, reject) => resolve());

export const login = (name, password) => {
  return fetch(`${DB_HOST}/_session`, {
    method: "POST",
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ name, password }),
  })
  .then(jsonOrReject)
}

export const clean = () => {
  if(pullHandler?.cancel) pullHandler.cancel()
  if(pushHandler?.cancel) pushHandler.cancel()
  if(pullTimeout) clearTimeout(pullTimeout)
  currentOperation = new Promise((resolve, reject) => resolve());
  currentPushOperation = new Promise((resolve, reject) => resolve());
  RemoteUserPouch = null;
  LocalUserPouch = null;
}

export const initRemotePouch = (user) => {
  RemoteUserPouch = new PouchDB(`${DB_HOST}/${user}`, 
    !REACT_APP_DB_AUTH ? { skip_setup: true } :
    { fetch: (url, opts) => {
      opts.headers.set('X-Auth-CouchDB-UserName', Storage.get('Name'))
      opts.headers.set('X-Auth-CouchDB-Token', REACT_APP_DB_AUTH)
      opts.headers.set('X-Auth-CouchDB-Roles', REACT_APP_DB_ROLES)
      return PouchDB.fetch(url, opts)
    },
      skip_setup: true
    }
  )
}

export const pull = (user, onUpdate, onError, every=15, index=null) => {
  initRemotePouch(user)
  LocalUserPouch = new PouchDB(user)
  if (index) {
    console.log("Creating index")
    LocalUserPouch.createIndex({ index })
  }

  pullHandler = LocalUserPouch.replicate.from(RemoteUserPouch, {
    retry: true,
  })
  .on("change", (info) => { console.debug("pull info", info); })
  .on("denied", (err) => { console.debug("pull onDenied", err); })
  .on("error", (err) => { console.error("pull onError", err); })
  //.on("error", (err) => { console.error("pull onError", err); console.log(onError); onError(err) })
  .on("paused", (err) => { console.debug(`pull onPaused`, err); onUpdate(err) })
  .on("active", (info) => { console.debug("pull onActive"); onUpdate(info) })
  .on("complete", (info) => {
    console.debug("pull onComplete", info);
    onUpdate(info).then(() => {
      if (every > 0) {
        pullTimeout = setTimeout(() => pull(user, onUpdate), every * 1000)
      }
    })
  })
}

export const push = (user, onComplete) => {
  if (!(LocalUserPouch && RemoteUserPouch)) {
    return;
  }

  currentPushOperation.then(() =>
    new Promise((resolve, reject) => {
      pushHandler = LocalUserPouch.replicate.to(RemoteUserPouch, {
        live: true,
        retry: true,
      })
      .on("change", (info) => { console.debug("push info", info); })
      .on("paused", (err) => { console.debug(`push onPaused`, err); })
      .on("active", () => { console.debug("push onActive"); })
      .on("denied", (err) => { console.debug("push onDenied", err); })
      .on("complete", (info) => {
        console.debug("push onComplete", info);
        resolve()
      })
      .on("error", (err) => {
        console.error("push onError", err);
        reject()
      })
    })
  )
}


export const getPersonalInfo = (db) => {
  return LocalUserPouch &&
    LocalUserPouch.get("info:personalInfo").catch((err) => {
    return {}
  });
};

export const getWorkouts = (db) => {
  return LocalUserPouch &&
    LocalUserPouch.get("info:workouts")
    .then((doc) => doc.workouts)
    .catch((err) => { return [] })
};

export const getVisits = () => {
  return LocalUserPouch &&
    LocalUserPouch.get("info:visits")
    .then((doc) => doc.visits)
    .catch((err) => { return [] })
};

export const getReports = () => {
  return LocalUserPouch &&
    LocalUserPouch.get("info:reports")
    .then((doc) => doc.reports)
    .catch((err) => { return [] })
};

export const getEvents = (start, end) => {
  if (!LocalUserPouch) return currentOperation;

  //currentOperation = currentOperation.then(() =>
  //  LocalUserPouch.find({
  //    "selector": {
  //      "start": {
  //        "$gte": start,
  //        "$lt": end,
  //      }
  //    }
  //  }).then(results => {
  //    return results.docs
  //  }).catch((err) => { return [] })
  //)

  //return currentOperation
  
  return LocalUserPouch.allDocs({include_docs: true})
    .then((result) => {
      return result.rows.map(r => r.doc);
    })
    .catch((err) => { return [] })
};

export const createEvent = (event) => {
  currentOperation = currentOperation
    .then(() => {
      return LocalUserPouch.put({ ...event, _id: event.id });
    })
    .catch((err) => {
      console.error(err);
    });

  return currentOperation;
};

export const modifyEvent = (event) => {
  const { _rev, ...safeEvent } = event

  currentOperation = currentOperation
    .then(() => {
      return LocalUserPouch.get(event.id);
    })
    .then((currentDoc) => {
      return LocalUserPouch.put({ ...currentDoc, ...safeEvent});
    })
    .catch((err) => {
      console.error(err);
    });

  return currentOperation;
};

export const removeEvent = (eventId) => {
  currentOperation = currentOperation
    .then(() => {
      return LocalUserPouch.get(eventId);
    })
    .then((currentDoc) => {
      return LocalUserPouch.remove(currentDoc);
    })
    .catch((err) => {
      console.error(err);
    });

  return currentOperation;
};


// Duplicate this function for other docs
export const setPersonalInfo = (key, value) => {
  currentOperation = currentOperation
    .then(() => {
      return LocalUserPouch.get("info:personalInfo");
    })
    .then((currentDoc) => {
      return LocalUserPouch.put({ ...currentDoc, [key]: value });
    })
    .catch((err) => {
      console.error(err);
    });

  return currentOperation;
};

export const setVisits = (visits) => {
  currentOperation = currentOperation
    .then(() => {
      return LocalUserPouch.get("info:visits");
    })
    .then((currentDoc) => {
      return LocalUserPouch.put({ ...currentDoc, visits });
    })
    .catch((err) => {
      console.error(err);
    });

  return currentOperation;
};

export const setReports = (reports) => {
  currentOperation = currentOperation
    .then(() => {
      return LocalUserPouch.get("info:reports");
    })
    .then((currentDoc) => {
      return LocalUserPouch.put({ ...currentDoc, reports });
    })
    .catch((err) => {
      console.error(err);
    });

  return currentOperation;
};

export const setWorkouts = (workouts) => {
  currentOperation = currentOperation
    .then(() => {
      return LocalUserPouch.get("info:workouts");
    })
    .then((currentDoc) => {
      return LocalUserPouch.put({ ...currentDoc, workouts });
    })
    .catch((err) => {
      console.error(err);
    });

  return currentOperation;
};

export const getTherapistInfo = () => {
  const therapist = Storage.get('Name')

  return fetch(`${DB_HOST}/${therapist}/info`, DefaultOpts())
    .then(jsonOrReject)
    .then(d => ({...d, id: therapist}))
};

export const setPatientFBLink = (patient, link) => {
  return fetch(`${DB_HOST}/${patient}/info:personalInfo`, DefaultOpts())
    .then(jsonOrReject)
    .then(doc => fetch(`${DB_HOST}/${patient}/info:personalInfo`, {
      ...DefaultOpts(),
      method: "PUT",
      body: JSON.stringify({...doc, fbLink: link})
    }))
};

export const getNextBooking = (patient) => {
  const therapist = Storage.get('Name')

  return fetch(`${DB_HOST}/${therapist}_bookings/_design/byPatient/_view/nextBooking?key="${patient}"`, DefaultOpts())
    .then(jsonOrReject)
    .then(res => {
      return res.rows && res.rows.length && res.rows[0].value ? res.rows[0].value : null
    })
};

export const resetNextAppointment = (patient) => {
  return fetch(`${DB_HOST}/${patient}/info:personalInfo`, DefaultOpts())
    .then(jsonOrReject)
    .then(doc => fetch(`${DB_HOST}/${patient}/info:personalInfo`, {
      ...DefaultOpts(),
      method: "PUT",
      body: JSON.stringify({...doc, nextAppointment: null})
    }))
};

