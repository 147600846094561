import React, { useState, useContext, useEffect } from "react";
import TherapistContext from "../context/therapistContext";

import styled from "styled-components";
import colors from "../config/colors";
import fonts from "../config/fonts";
import * as FB from "../Facebook";

import Link from "./Link";

const AppNavBar = ({ logout }) => {
  const [unreadCount, setUnreadCount] = useState(0);

  const version = process.env.REACT_APP_VERSION || "2.0 beta";

  const { firstname, fbPageId } = useContext(TherapistContext);

  useEffect(() => {
    const interval = setInterval(() => {
      FB.AUTH_ENABLED &&
        FB.getUnreadCount(fbPageId)
          .then(setUnreadCount)
          .catch((e) => console.log(e));
    }, 60000);

    return () => {
      clearInterval(interval);
    };
  }, [fbPageId]);

  return (
    <Navbar>
      <TopPart>
        <Link style={LinkStyle} to="/">
          <Title>
            <Logo src="/logo-small-white.png" />
            <Webster>Webster</Webster>
            <Version>v{version}</Version>
          </Title>
        </Link>

        <Link style={LinkStyle} to="/patients">
          <SecondaryLinks>
            <i className="fas fa-users" />
            &nbsp;&nbsp;Patients
          </SecondaryLinks>
        </Link>

        <A href={`https://business.facebook.com/latest/inbox/all?asset_id=${fbPageId}`} target="_blank">
          <SecondaryLinks>
            <i
              className="fab fa-facebook-messenger"
              style={{ color: colors.white }}
            />
            {unreadCount > 0 && <Badge>{unreadCount}</Badge>}
            &nbsp;&nbsp;Messenger
          </SecondaryLinks>
        </A>

        <Link style={LinkStyle} to="/booking">
          <SecondaryLinks>
            <i className="fas fa-calendar-alt" />
            &nbsp;&nbsp;Booking
          </SecondaryLinks>
        </Link>
      </TopPart>

      <BottomPart>
        <Connected>Connected as:</Connected>
        <Link style={LinkStyle} to="/profile">
          <SecondaryLinks>
            <i className="fas fa-user-md" />
            &nbsp;&nbsp;{firstname}
          </SecondaryLinks>
        </Link>
        <Link style={LinkStyle} onClick={() => logout()}>
          <SecondaryLinks>
            <i className="fas fa-sign-out-alt" />
            &nbsp;&nbsp;Log out
          </SecondaryLinks>
        </Link>
      </BottomPart>
    </Navbar>
  );
};

const BottomPart = styled.div`
  position: fixed;
  bottom: 10px;
`;

const Connected = styled.div`
  color: ${colors.grey};
  font-family: ${fonts.primary};
  font-size: 1em;
  margin-bottom: 0.5em;
`;

const A = styled.a`
  color: ${colors.white};
  font-family: ${fonts.primary};
  font-size: 1em;

  &:hover {
    color: ${colors.white} !important;
    text-decoration: none !important;
  }
`;

const LinkStyle = {
  color: colors.white,
  fontFamily: fonts.primary,
  fontSize: "1em",
};

const Logo = styled.img`
  width: 25px;
  margin-right: 3px;
  margin-bottom: 10px;
`;

const SecondaryLinks = styled.div`
  font-size: 1.1em;
  margin-bottom: 1rem;
  font-family: ${fonts.secondary};
  position: relative;

  &:hover {
    opacity: 0.9;
  }
`;

const Navbar = styled.div`
  align-items: flex-start;
  background-color: ${colors.black};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  min-width: 8.4rem;
  overflow-x: hidden;
  padding-left: 0.8em;
  padding-top: 20px;
  text-align: left;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

const TopPart = styled.div`
  position: fixed;
  top: 10px;
`;

const Webster = styled.span`
  font-size: 1.6em;
  font-family: ${fonts.secondary};
  letter-spacing: -0.02em;

  &:hover {
    opacity: 0.9;
  }
`;

const Title = styled.div`
  margin-bottom: 1em;
`;

const Version = styled.div`
  color: ${colors.grey};
  font-size: 0.8em;
  text-align: right;
  margin-top: -0.7em;
`;

const Badge = styled.div`
  color: ${colors.white};
  background-color: ${colors.purple};
  font-family: ${fonts.secondary};
  position: absolute;
  top: -0.8em;
  left: 0.9em;
  height: 1.5em;
  min-width: 1.5em;
  border-radius: 0.5em;
  font-size: 0.7em;
  text-align: center;
  padding: 0 0.2em;
`;

export default AppNavBar;
