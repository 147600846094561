export const API_HOST = process.env.REACT_APP_API_HOST || "http://localhost:5000";

const jsonOrReject = (r) => {
  const contentType = r.headers.get('Content-Type')
  if (contentType && contentType.startsWith('application/json')) {
    return r.json().then(json => {
      if (r.status > 399) {
        return Promise.reject({ status: r.status, msg: json })
      } else {
        return json
      }
    })
  } else {
    return r.text().then(text => {
      if (r.status > 399) {
        return Promise.reject({ status: r.status, msg: text })
      } else {
        return text
      }
    })
  }
}

export const getUpcomingBookings = (therapistPublicName) => {
  return fetch(`${API_HOST}/public/bookings/${therapistPublicName}`, {
    method: "GET",
  })
  .then(jsonOrReject)
};

export const getUpcomingWorkshops = (therapistPublicName) => {
  return fetch(`${API_HOST}/public/workshops/${therapistPublicName}`, {
    method: "GET",
  })
  .then(jsonOrReject)
};

export const getTherapistInfo = (therapistPublicName) => {
  return fetch(`${API_HOST}/public/therapist/${therapistPublicName}`, {
    method: "GET",
  })
  .then(jsonOrReject)
};

export const getPatientInfo = (personnummer) => {
  return fetch(`${API_HOST}/public/patient/${personnummer}`, {
    method: "GET",
  })
  .then(jsonOrReject)
};

export const registerPatient = (
  therapist,
  firstname,
  lastname,
  phone,
  email,
  personnummer
) => {
  return fetch(`${API_HOST}/public/register`, {
    method: "POST",
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      therapist,
      firstname,
      lastname,
      phone,
      email,
      personnummer
    }),
  })
  .then(jsonOrReject)
};

export const book = (
  type,
  therapist,
  eventId,
  personnummer
) => {
  return fetch(`${API_HOST}/public/book/${type}`, {
    method: "POST",
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      therapist,
      eventId,
      personnummer
    }),
  })
  .then(jsonOrReject)
};

export const getEventInfo = (bookingId, personnummer) => {
  return fetch(`${API_HOST}/public/event/${bookingId}?personnummer=${personnummer}`, {
    method: "GET",
  })
  .then(jsonOrReject)
};

export const unbookEvent = (bookingId, personnummer) => {
  return fetch(`${API_HOST}/public/unbook`, {
    method: "POST",
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      bookingId,
      personnummer
    }),
  })
  .then(jsonOrReject)
};
