import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";

import * as API from "../API";
import * as DB from "../DB";
import * as Storage from "../Storage";

import Button from "../components/Button";
import colors from "../config/colors";
import fonts from "../config/fonts";

const Login = ({ afterLogin }) => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [loading, setLoading] = useState(false)
  const [passwordIsFocused, setPasswordIsFocused] = useState(false)

  const emailInput = useRef(null);

  const login = () => {
    setLoading(true)
    API.login(email, password)
      .then((json) => {
        Storage.set('Name', json.name)
        return DB.login(json.name, password)
      })
      .then(() => afterLogin())
      .then(() => setLoading(false))
      .catch(e => setErrorMessage(e.msg))
  }

  const handleKeyPress = (event) => {
    if (passwordIsFocused) {
      if (event.keyCode === 13) {
        login()
      } else if (event.keyCode === 27) {
        setPassword("");
      }
    }
  };

  useEffect(() => {
    if (emailInput.current && !passwordIsFocused) emailInput.current.focus();

    document.addEventListener("keydown", handleKeyPress, false);

    return () => {
      document.removeEventListener("keydown", handleKeyPress, false);
    };
  });

  return (
    <Container>
      <Top>
        <Logo src="/logo-small-white.png" />
        <Webster>Webster</Webster>
      </Top>

      <SubContainer>
        { errorMessage && <div>{errorMessage}</div> }
        <Title>E-mail address</Title>
        <Input
          ref={emailInput}
          type="text"
          placeholder=""
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <Title>Password</Title>
        <Input
          type="password"
          placeholder=""
          value={password}
          onFocus={() => setPasswordIsFocused(true)}
          onBlur={() => setPasswordIsFocused(false)}
          onChange={(e) => setPassword(e.target.value)}
        />

        <ButtonContainer>
          <ButtonDiv>
            <Button
              color={colors.blue}
              darkColor={colors.darkBlue}
              onClick={() => login()}
              textColor={colors.white}
              loading={loading}
              loadingSize={"sm"}
              title="Login"
              width="8em"
            />
          </ButtonDiv>
          <Link href="#forgot-password">Forgot password?</Link>
        </ButtonContainer>
      </SubContainer>
    </Container>
  );
};

const ButtonContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  margin-top: 1em;
`;

const ButtonDiv = styled.div`
  margin-right: 1.5em;
  width: 8em;
`;

const Container = styled.div`
  background-color: ${colors.black};
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  justify-content: center;
`;

const Input = styled.input`
  border: 1px ${colors.black} solid;
  border-radius: 8px;
  color: ${colors.black};
  font-family: ${fonts.primary};
  font-size: 1.2em;
  margin-bottom: 1.5em;
  max-width: 450px;
  padding: 0.2em 0.8em;
  width: 30vw;

  :focus {
    outline-style: none;
    border-radius: 8px;
  }
`;

const Link = styled.a`
  color: ${colors.blue};

  :hover {
    color: ${colors.blue};
    cursor: pointer;
    text-decoration: none;
  }
`;

const SubContainer = styled.div`
  background-color: ${colors.white};
  border: solid ${colors.black} 1px;
  border-radius: 8px;
  flex-direction: column;
  display: flex;
  padding: 3.5em;
  margin-bottom: 1em;
`;

const Title = styled.h1`
  font-family: ${fonts.primaryBold};
  font-size: 2em;
  letter-spacing: -0.02em;
`;

const Top = styled.div`
  margin-bottom: 3em;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 1em;
`

const Logo = styled.img`
  width: 100px;
  margin-right: 1.5em;
`;

const Webster = styled.span`
  font-size: 3.6em;
  font-family: ${fonts.secondary};
  color: ${colors.white};
  letter-spacing: -0.03em;
`;

export default Login;
