function timeConverter(UNIX_timestamp) {
  var a = new Date(UNIX_timestamp * 1000);
  // var months = [
  //   "Jan",
  //   "Feb",
  //   "Mar",
  //   "Apr",
  //   "May",
  //   "Jun",
  //   "Jul",
  //   "Aug",
  //   "Sep",
  //   "Oct",
  //   "Nov",
  //   "Dec",
  // ];
  var months = [
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
  ];
  var year = a.getFullYear();
  var month = months[a.getMonth()];
  var day = a.getDate() < 10 ? "0" + a.getDate() : a.getDate();
  // var date = day + "/" + month + "/" + year;
  var date = year + "-" + month + "-" + day;
  // var date = `${month}. ${day}, ${year}`;
  return date;
}

export default timeConverter;
