import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

const Link = ({ style, hoverStyle, to, children, onClick, ...props }) => {
  const history = useHistory()
  const [hover, setHover] = useState(false)

  const switchToPage = (e) => {
    e.preventDefault();

    if (onClick) {
      onClick()
    } else {
      history.push(to)
    }
  }

  return <A
    style={hover ? {...style, ...hoverStyle} : style}
    onMouseEnter={() => setHover(true)}
    onMouseOut={() => setHover(false)}
    href={to}
    onClick={switchToPage}
    {...props}
  >
      { children }
  </A>
}

const A = styled.a`
  cursor: pointer;

  &:hover {
    text-decoration: none;
    color: inherit;
  }
`

export default Link;
