import React, { useContext } from "react";
import styled from "styled-components";

import colors from "../config/colors";
import fonts from "../config/fonts";
import { API_HOST } from "../API";
import PatientContext from "../context/patientContext";

const CurrentWorkouts = () => {
  const { workouts, id } = useContext(PatientContext);

  const workout = workouts
    .sort((r1, r2) => parseInt(r2.timestamp) - parseInt(r1.timestamp))
    .find((w) => w.isActive);

  if (!workout)
    return (
      <Field>
        <Title>No workout yet</Title>
      </Field>
    );

  const link = workout.type === "youtube" ?
    `https://www.youtube.com/watch?v=${workout.youtubeID}` :
    `${API_HOST}/uploads/${id}/${workout.data.md5}${workout.data.ext}`

  return (
    <Container>
      <Header>
        <SubTitle>Current workouts:&nbsp;&nbsp;</SubTitle>
        <OpenLink href={link} target="_blank" >
          <i className="fas fa-external-link-alt" />
          &nbsp; (open in a new tab)
        </OpenLink>
      </Header>

      <Card key={workout.id}>
        { workout.type === "youtube" ?
          <div><iframe frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0"
            title="youtube"
            width="788.54" height="443" type="text/html"
            src={`https://www.youtube.com/embed/${workout.youtubeID}?autoplay=0&fs=0&iv_load_policy=3&showinfo=0&rel=0&cc_load_policy=0&start=0&end=0&origin=http://youtubeembedcode.com`} />
          </div> :
          <embed
            src={`${API_HOST}/uploads/${id}/${workout.data.md5}${workout.data.ext}#toolbar=0&navpanes=0&scrollbar=0`}
            type="application/pdf"
            style={{
              width: "100%",
              minHeight: "100vh",
            }}
            zoom="page-width"
            page={1}
            navpanes={0}
          />
        }
      </Card>
    </Container>
  );
};

const Card = styled.div`
  flex: 1;
`;

const Container = styled.div`
  color: ${colors.black};
`;

const Title = styled.div`
  font-family: ${fonts.secondaryBold};
  letter-spacing: -0.03em;
  font-size: 2em;
  margin-bottom: 3em;
`;

const OpenLink = styled.a`
  color: ${colors.blue};
  font-family: ${fonts.primary};
  font-size: 1.2em;

  &:hover {
    color: ${colors.blue};
    cursor: pointer;
    text-decoration: underline;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 0.6em;
`;

const Field = styled.div`
  font-family: ${fonts.primary};
  margin-bottom: 1rem;
`;

const SubTitle = styled.div`
  font-family: ${fonts.primary};
  font-size: 1.3em;
  margin-right: 0.5em;
`;

export default CurrentWorkouts;
