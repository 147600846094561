import React, { useState } from "react";
import styled from "styled-components";
import Swal from 'sweetalert2';
import moment from 'moment';

import { Spinner } from "react-bootstrap";
import Button from "../components/Button";
import colors from "../config/colors";
import fonts from "../config/fonts";
import * as PublicAPI from "../PublicAPI";

const ManageBooking = ({ match }) => {
  const { bookingId } = match.params
  const [updating, setUpdating] = useState(false)
  const [event, setEvent] = useState({})
  const [patient, setPatient] = useState({})
  const [therapist, setTherapist] = useState({})
  const [personnummer, setPersonnummer] = useState('')
  const [error, setError] = useState(null)
  const [done, setDone] = useState(false)

  const handleSubmit = () => {
    console.log(`'Fetching booking info`);

    setUpdating(true)

    PublicAPI
      .getEventInfo(bookingId, personnummer)
      .then(info => {
        setEvent(info.event)
        setPatient(info.patient)
        setTherapist(info.therapist)
        setUpdating(false)
      })
      .catch((e) => {
        console.log(e)
        setError(e.msg ? e.msg : "Couldn't retrieve the event's information, please contact your therapist")
        setUpdating(false)
      })

  }

  const handleUnbook = () => {
    Swal.fire({
      text: `Are you sure you want to cancel your ${event.openBooking ? 'booking': 'participation'}?`,
      icon: 'question',
      confirmButtonText: 'Yes, cancel',
      cancelButtonText: 'Nevermind',
      confirmButtonColor: '#dc3545',
      cancelButtonColor: '#333333',
      showCancelButton: true,
      reverseButtons: true,
    }).then(result => {
      if (result.isConfirmed) {
        setUpdating(true)

        PublicAPI
          .unbookEvent(bookingId, personnummer)
          .then(info => {
            Swal.fire({
              text: `Your ${event.openBooking ? 'booking': 'participation'} has been cancelled`,
              icon: 'success'
            })
            setDone(true)
          })
          .catch((e) => {
            console.log(e)
            Swal.fire({
              text: `An error occurred during the cancellation. Feel free to try again or contact your therapist.`,
              icon: 'error'
            })
          }).finally(() => {
            setEvent({})
            setPatient({})
            setTherapist({})
            setUpdating(false)
          })
      }
    })
  }

  return <Container>
    <NavBar>
      <Title>
        <Logo src="/logo-small-white.png" />
        <Webster>Webster</Webster>
      </Title>
    </NavBar>
    { updating && <SpinnerContainer><Spinner animation="border" size="xl" /></SpinnerContainer>}
    { !updating && <BookingContainer>
      {!event.start && !done && <>
        { error && <Error>{error}</Error>}
        { !error && <p>
            Indicate your swedish personal number in order to verify your identity
        </p>}
        <InputContainer>
          <input
            required
            autoFocus
            onFocus={e => e.currentTarget.select()}
            type="text"
            style={error ? { borderColor: "red" } : {}}
            value={personnummer}
            onChange={(e) => {
              setError(null)
              setPersonnummer(e.target.value)
            }}
            onKeyPress={event => {
              if (event.key === 'Enter' && !error) {
                handleSubmit()
              }
            }}
          />
          <label className={ personnummer !== '' ? 'filled' : ''}>
            Personnummer (yyyymmdd-xxxx)
          </label>
        </InputContainer>
        <ButtonContainer>
          <Button
            disabled={personnummer === '' || error}
            color={colors.purple}
            darkColor={colors.darkPurple}
            onClick={(e) => { e.preventDefault(); handleSubmit(); }}
            textColor={colors.white}
            textSize="1em"
            title={"Next"}
          />
        </ButtonContainer>
      </>}
      {event.openBooking && <>
        <p>Hello {patient.firstname}, here is your next session with {therapist.firstname}:</p>
        <br />
        { event.description && <Left>
          <Bold>Description:</Bold> {event.description}
        </Left> }
        <Left><Bold>Date:</Bold> {new Date(event.start).toLocaleDateString('se-SE')}</Left>
        <Left><Bold>From:</Bold> {moment(event.start).format("HH:mm")}</Left>
        <Left><Bold>To:</Bold> {moment(event.end).format("HH:mm")}</Left>
        <br />
        <ButtonContainer2>
          <Button
            disabled={personnummer === ''}
            color={colors.copperRed}
            darkColor={colors.darkCopperRed}
            onClick={(e) => { e.preventDefault(); handleUnbook(); }}
            textColor={colors.white}
            textSize="1em"
            title={"Cancel my booking"}
          />
        </ButtonContainer2>

      </>}
      {event.workshop && <>
        <p>Hello {patient.firstname}, here is your next workshop with {therapist.firstname}:</p>
        <br />
        { event.workshopTitle && <Left>
          <Bold>{event.workshopTitle}</Bold>
        </Left> }
        { event.workshopURL && <Left>
          <Bold>More info:</Bold> <a href={event.workshopURL} target="_blank" rel="noreferrer">
            {event.workshopURL}</a>
        </Left> }
        <Left><Bold>Date:</Bold> {new Date(event.start).toLocaleDateString('se-SE')}</Left>
        <Left><Bold>From:</Bold> {moment(event.start).format("HH:mm")}</Left>
        <Left><Bold>To:</Bold> {moment(event.end).format("HH:mm")}</Left>
        <br />
        <ButtonContainer3>
          <Button
            disabled={personnummer === ''}
            color={colors.copperRed}
            darkColor={colors.darkCopperRed}
            onClick={(e) => { e.preventDefault(); handleUnbook(); }}
            textColor={colors.white}
            textSize="1em"
            title={"Cancel my participation"}
          />
        </ButtonContainer3>

      </>}
      {done && <>
        <p>You may close this window now.</p>
      </>}
    </BookingContainer>}
  </Container>
};

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  flex: 1;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;

  & .swal2-container {
    font-family: ${fonts.primary} !important;
  }
`;

const SpinnerContainer = styled.div`
  flex: 1;
  display: flex;
  width: 100vw;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  padding-top: 5em;
`;

const BookingContainer = styled.div`
  flex: 1;
  display: flex;
  width: 100vw;
  align-items: center;
  justify-content: flex-start;
  padding-top: 3em;
  flex-direction: column;
  font-family: ${fonts.primary};
`;

const ButtonContainer = styled.div`
  margin-top: 1.5em;
  width: 6em;
  height: 2em;
`;

const ButtonContainer2 = styled.div`
  margin-top: 1.5em;
  width: 10em;
  height: 2em;
`;

const ButtonContainer3 = styled.div`
  margin-top: 1.5em;
  width: 12em;
  height: 2em;
`;


const NavBar = styled.div`
  background-color: ${colors.black};
  width: 100%;
  padding: 0.5rem 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  
  > div {
    font-family: ${fonts.primary};
    color: ${colors.white};
  }
`

const Title = styled.div`
`;

const Logo = styled.img`
  width: 25px;
  margin-right: 3px;
  margin-bottom: 10px;
`;

const Webster = styled.span`
  font-size: 1.6em;
  font-family: ${fonts.secondary};
  letter-spacing: -0.02em;
  color: ${colors.white};
`;

const InputContainer = styled.div`
   position: relative;
   display: flex;
   flex-direction: column;
   margin: 1.5rem 0.5rem 0.5rem;
   width: 25em;

   &:focus-within label {
     transform: translate(0, -1rem) scale(0.8);
     color: #7066e0;
   }

   & .filled {
     transform: translate(0, -1rem) scale(0.8);
   }

   & label {
     position: absolute;
     pointer-events: none;
     transform: translate(0, 0.5rem) scale(1);
     transform-origin: top left;
     transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
     color: #6F81A5;
     font-size: 1rem;
     font-family: ${fonts.primary};
     line-height: 1;
     left: 1rem;
   }

   & input {
     height: 2rem;
     border-radius: 0.25rem;
     border: 1px solid #6F81A5;
     padding: 0.25rem 1rem 0.25rem 1rem;
     font-size: 1rem;
     font-family: ${fonts.primaryBold};
     line-height: 1;
     outline: none;
     box-shadow: none;
     transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
   }

   & input:focus {
     border: 1px solid #7066e0;
   }
`;

const Error = styled.p`
  color: red;
`

const Bold = styled.span`
  font-family: ${fonts.primaryBold};
`

const Left = styled.p`
  width: 20em;
`


export default ManageBooking;
