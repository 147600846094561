import React, { useContext } from "react";

import ConnectedSelect from "../ConnectedSelect";
import Input from "../ConnectedInput";

import PatientContext from "../../context/patientContext";
import PatientInfoContext from "../../context/patientInfoContext";

const Stress = () => {
  const {
    work_stress,
    work_stress_level,
    life_stress,
    life_stress_level,
    stress_details,
  } = useContext(PatientContext);

  const {
    getHeader,
    opened,
    editing,
    Container,
    InfoContainer,
    InfoDiv,
    InfoDivBig,
    InfoEdit,
    InfoEditBig,
    Info,
    Text,
  } = useContext(PatientInfoContext);

  const category = "Stress";
  const isOpen = opened(category);
  const isEditing = editing(category);
  const Header = () => getHeader(category, isOpen);

  const bool = (v) => (v === true ? "Yes" : v === false ? "No" : "");

  return (
    <Container>
      <Header />

      {isOpen && !isEditing && (
        <InfoContainer>
          <InfoDiv>
            <Text>Work stress:</Text>
            <Info>{bool(work_stress)}</Info>
          </InfoDiv>
          {work_stress === true && (
            <InfoDiv>
              <Text>Level of work stress</Text>
              <Info>{work_stress_level}</Info>
            </InfoDiv>
          )}
          <InfoDiv>
            <Text>General life stress:</Text>
            <Info>{bool(life_stress)}</Info>
          </InfoDiv>
          {life_stress === true && (
            <InfoDiv>
              <Text>Level of general life stress:</Text>
              <Info>{life_stress_level}</Info>
            </InfoDiv>
          )}
          {stress_details && stress_details.length > 0 && (
            <InfoDivBig>
              <Text>Stress details:</Text>
              <Info>{stress_details}</Info>
            </InfoDivBig>
          )}
        </InfoContainer>
      )}

      {isOpen && isEditing && (
        <InfoContainer>
          <InfoEdit>
            <Text>Work stress</Text>
            <ConnectedSelect dbKey="work_stress">
              <option value={true}>Yes</option>
              <option value={false}>No</option>
            </ConnectedSelect>
          </InfoEdit>
          <InfoEdit>
            <Text>Level of work stress</Text>
            <ConnectedSelect dbKey="work_stress_level">
              <option value={1}>1</option>
              <option value={2}>2</option>
              <option value={3}>3</option>
              <option value={4}>4</option>
              <option value={5}>5</option>
            </ConnectedSelect>
          </InfoEdit>
          <InfoEdit>
            <Text>General life stress</Text>
            <ConnectedSelect dbKey="life_stress">
              <option value={true}>Yes</option>
              <option value={false}>No</option>
            </ConnectedSelect>
          </InfoEdit>
          <InfoEdit>
            <Text>Level of work stress</Text>
            <ConnectedSelect dbKey="life_stress_level">
              <option value={1}>1</option>
              <option value={2}>2</option>
              <option value={3}>3</option>
              <option value={4}>4</option>
              <option value={5}>5</option>
            </ConnectedSelect>
          </InfoEdit>
          <InfoEditBig>
            <Text>Stress details</Text>
            <Input textarea dbKey="stress_details" />
          </InfoEditBig>

          <InfoEdit />
        </InfoContainer>
      )}
      <hr width="99%" />
    </Container>
  );
};

export default Stress;
