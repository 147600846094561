import React, { useContext } from "react";
import styled from "styled-components";

import Button from "../components/Button";
import colors from "../config/colors";
import fonts from "../config/fonts";
import { API_HOST } from "../API";

import TherapistContext from "../context/therapistContext";

const Admin = () => {
  const { logout, setTherapist, ...therapist } = useContext(TherapistContext);

  return (
    <Container>
      <Card>
        <CardRow>
          <Image src={`${API_HOST}/uploads/therapists/${therapist.id}-avatar.png`} />
          <CardColumn>
            <Name>
              {therapist.firstname} {therapist.lastname}
            </Name>
            <Email>{therapist.email}</Email>
          </CardColumn>
        </CardRow>

        <ButtonDiv>
          <Button
            color={colors.copperRed}
            darkColor={colors.darkCopperRed}
            onClick={() => logout()}
            icon="sign-out-alt"
            textColor={colors.white}
            textSize="1em"
            title="Log out"
            width="7em"
            height="2em"
          />
        </ButtonDiv>
      </Card>
    </Container>
  );
};

const Card = styled.div`
  border: 1px solid ${colors.black};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  height: 20em;
  justify-content: space-between;
  padding: 4em;
  width: 35em;
`;

const CardRow = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;

const CardColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const ButtonDiv = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const Email = styled.div`
  font-family: ${fonts.primary};
  font-size: 1em;
`;

const Name = styled.div`
  font-family: ${fonts.primaryBold};
  font-size: 2em;
  letter-spacing: -0.03em;
`;

const Container = styled.div`
  align-items: center;
  color: ${colors.black};
  display: flex;
  flex: 1;
  justify-content: center;
  min-height: 100vh;
`;

const Image = styled.img`
  margin-right: 1em;
  width: 5.5em;
`;

export default Admin;
