import * as Storage from "./Storage";

const FB_API = "https://graph.facebook.com/v10.0";

export const AUTH_ENABLED = process.env.REACT_APP_FB_AUTH === "true" || false;
export const APP_ID = process.env.REACT_APP_FB_APP_ID;
export const HOST = process.env.REACT_APP_HOST;

export const openDialog = (tmp) => {
  if (window.confirm('Do you want to authenticate on Facebook to retrieve patient messages?')) {
    window.location.href = `https://www.facebook.com/v10.0/dialog/oauth?client_id=${APP_ID}&response_type=token&redirect_uri=${encodeURIComponent(
      `${HOST}/patients`
    )}&state=${tmp}&scope=pages_show_list%2Cpages_messaging%2Cpages_read_engagement%2Cpublic_profile`;
  }
};

export const isLoggedIn = () => {
  const userToken = Storage.get("fbUserToken");
  const pageToken = Storage.get("fbPageToken");

  return new Promise((resolve, reject) => {
    if (!userToken || !pageToken) return reject();

    return fetch(
      `${FB_API}/debug_token?input_token=${pageToken}&access_token=${userToken}`
    )
      .then((r) => r.json())
      .then((res) => (res.data.is_valid ? resolve() : reject()))
      .catch((e) => reject(e));
  });
};

export const getUserID = () => {
  const userToken = Storage.get("fbUserToken");

  return fetch(
    `${FB_API}/debug_token?input_token=${userToken}&access_token=${userToken}`
  )
    .then((r) => r.json())
    .then((res) => res.data.user_id);
};

export const getPageToken = () => {
  const userID = Storage.get("fbUser");
  const userToken = Storage.get("fbUserToken");

  return fetch(`${FB_API}/${userID}/accounts?access_token=${userToken}`)
    .then((r) => r.json())
    .then((res) =>
      res.data[0]?.access_token ? res.data[0].access_token : null
    );
};

export const getPatientLink = (patientPSID, pageID) => {
  const pageToken = Storage.get("fbPageToken");

  return fetch(
    `${FB_API}/${pageID}/conversations?user_id=${patientPSID}&access_token=${pageToken}`
  )
    .then((r) => r.json())
    .then((res) => (res.data[0]?.link ? res.data[0].link : null));
};

export const getUnreadCount = (pageID) => {
  const pageToken = Storage.get("fbPageToken");

  return fetch(
    `${FB_API}/${pageID}/conversations?fields=unread_count&access_token=${pageToken}`
  )
    .then((r) => r.json())
    .then((res) =>
      res.data ? res.data.filter(c => c.unread_count > 0).length : 0
    );
};

export const login = (params) =>
  isLoggedIn().catch(() => {
    const tmp = Storage.get("fbState");

    if (!params.access_token && !tmp) {
      const newTmp = new Date().getTime();
      Storage.set("fbState", newTmp);
      openDialog(newTmp);
      return Promise.reject();
    } else if (tmp === params.state) {
      Storage.set("fbUserToken", params.access_token);
      Storage.remove("fbState");

      return getUserID().then((user) => {
        Storage.set("fbUser", user);
        return getPageToken().then((pageToken) =>
          Storage.set("fbPageToken", pageToken)
        );
      });
    }
  });

export const clearToken = () => {
  Storage.remove("fbState");
  Storage.remove("fbUserToken");
  Storage.remove("fbPageToken");
  Storage.remove("fbUser");
};
