import React from "react";
import { useHistory } from "react-router-dom";

const BodyImage = ({ report, to }) => {
  const history = useHistory();

  const handleClick = () => {
    if (to) history.push(to);
  };

  // Display
  if (!report) {
    return (
      <div
        style={{
          width: "100%",
          position: "relative",
          display: "block",
          minHeight: "434px",
        }}
      >
        <img
          alt="body"
          src="/bodyimage.png"
          width="100%"
          style={{ objectFit: "contain" }}
        />
      </div>
    );
  }

  const width = !report.paths
    ? 0
    : report.paths[0]?.size
    ? report.paths[0].size.width
    : 0;
  const height = !report.paths
    ? 0
    : report.paths[0]?.size
    ? report.paths[0].size.height
    : 0;
  const paths = report.paths ? report.paths : [];

  return (
    <div
      onClick={handleClick}
      style={{
        width: "100%",
        position: "relative",
        display: "block",
        cursor: to ? "pointer" : "inherit",
      }}
    >
      <img
          alt="body"
        src="/bodyimage.png"
        width="100%"
        style={{
          visibility: paths.length === 0 ? "shown" : "hidden",
            objectFit: "contain"
        }}
      />
      <svg
        viewBox={`0 0 ${width || 300} ${height || 200}`}
        style={{
          position: "absolute",
          top: "0",
          left: "0",
          backgroundImage: 'url("/bodyimage.png")',
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
      >
        {paths.map((p, index) => (
          <path
            key={index}
            d={`M ${p.path.data
              .map((coords) => coords.replace(",", " "))
              .join(" ")}`}
            stroke={p.path.color}
            strokeWidth={p.path.width}
            fill="none"
          />
        ))}
      </svg>
    </div>
  );
};

export default BodyImage;
