import React, { useContext } from "react";

import Input from "../ConnectedInput";

import PatientContext from "../../context/patientContext";
import PatientInfoContext from "../../context/patientInfoContext";

const PatientPresentation = () => {
  const {
    sign_symptoms,
    history_of_complaint,
    behavior_type_of_pain,
    aggravating_factors,
    relieving_factors,
  } = useContext(PatientContext);

  const {
    getHeader,
    opened,
    editing,
    Container,
    InfoContainer,
    InfoDivBig,
    InfoEditBig,
    Info,
    Text,
  } = useContext(PatientInfoContext);

  const category = "Current condition";
  const isOpen = opened(category);
  const isEditing = editing(category);
  const Header = () => getHeader(category, isOpen);

  return (
    <Container>
      <Header />

      {isOpen && !isEditing && (
        <InfoContainer>
          <InfoDivBig>
            <Text>Signs and Symptons:</Text>
            <Info>{sign_symptoms}</Info>
          </InfoDivBig>
          <InfoDivBig>
            <Text>
              History of complaint (how it happened, when, what first aid was
              applied):
            </Text>
            <Info>{history_of_complaint}</Info>
          </InfoDivBig>
          <InfoDivBig>
            <Text>
              Behaviour and type of pain (with movement/activity/pain/etc):
            </Text>
            <Info>{behavior_type_of_pain}</Info>
          </InfoDivBig>
          <InfoDivBig>
            <Text>Aggravating factors:</Text>
            <Info>{aggravating_factors}</Info>
          </InfoDivBig>
          <InfoDivBig>
            <Text>Relieving factors:</Text>
            <Info>{relieving_factors}</Info>
          </InfoDivBig>
        </InfoContainer>
      )}

      {isOpen && isEditing && (
        <InfoContainer>
          <InfoEditBig>
            <Text>Signs and Symptons</Text>
            <Input textarea dbKey="sign_symptoms" />
          </InfoEditBig>
          <InfoEditBig>
            <Text>
              History of complaint (how it happened, when, what first aid was
              applied)
            </Text>
            <Input textarea dbKey="history_of_complaint" />
          </InfoEditBig>
          <InfoEditBig>
            <Text>
              Behaviour and type of pain (with movement/activity/pain/etc)
            </Text>
            <Input textarea dbKey="behavior_type_of_pain" />
          </InfoEditBig>
          <InfoEditBig>
            <Text>Aggravating factors</Text>
            <Input textarea dbKey="aggravating_factors" />
          </InfoEditBig>
          <InfoEditBig>
            <Text>Relieving factors</Text>
            <Input textarea dbKey="relieving_factors" />
          </InfoEditBig>
          <InfoEditBig />
        </InfoContainer>
      )}
      <hr width="99%" />
    </Container>
  );
};

export default PatientPresentation;
