import React, { useState, useEffect, useContext } from "react";
import { Route, Switch, Redirect, useRouteMatch } from "react-router-dom";
import styled from "styled-components";

import Conditions from "./Conditions";
import PatientInfo from "./PatientInfo";
import Visit from "./Visit";
import Condition from "./Condition";
import NewVisitReport from "./NewVisitReport";
import TimeLine from "./TimeLine";
import UploadWorkout from "./UploadWorkout";

import CurrentWorkouts from "../components/DisplayCurrentWorkouts";
import LastUpdate from "../components/DisplayLastUpdate";
import LastVisits from "../components/DisplayLastVisits";
import Loading from "./Loading";
import NotificationContext from "../context/notificationContext";
import PatientContext from "../context/patientContext";
import TherapistContext from "../context/therapistContext";
import Top from "../components/PatientScreenTop";
import * as DB from "../DB";
import * as API from "../API";

const ask = (question) =>
  new Promise((resolve, reject) => {
    const accepted = window.confirm(question);
    return accepted ? resolve() : reject();
  });

const Patient = ({ match }) => {
  const [id, setId] = useState("");
  const [patient, setPatient] = useState({});
  const [nextBooking, setNextBooking] = useState(null);
  const [reports, setReports] = useState([]);
  const [visits, setVisits] = useState([]);
  const [workouts, setWorkouts] = useState([]);

  const [updating, setUpdating] = useState(false);

  const notificationContext = useContext(NotificationContext);

  const therapist = useContext(TherapistContext);

  let { path } = useRouteMatch();

  useEffect(() => {
    const user = match.params.id;
    console.log(`Switching to user ${user}`);

    DB.clean();

    setId(user);
    setUpdating(true);

    DB.getNextBooking(user).then((d) => setNextBooking(new Date(d)));

    DB.pull(user, () => {
      return updateUserInfo().then(() => {
        setUpdating(false)
      });
    }, (err) => {
      console.log('onErrorCheck');
    }, 15, null);

    // Start push live update
    DB.push(user);

    return function cleanup() {
      DB.clean();
    };
  }, [match.params.id]);

  const updateUserInfo = () =>
    Promise.all([
      DB.getPersonalInfo().then((p) => setPatient(p)),
      DB.getWorkouts().then((w) => setWorkouts(w)),
      DB.getVisits().then((v) => setVisits(v)),
      DB.getReports().then((r) => setReports(r)),
    ]);

  const edit = (dbKey, newValue) => {
    setPatient({ ...patient, [dbKey]: newValue });
    return DB.setPersonalInfo(dbKey, newValue);
  };

  const addVisit = (visit, report) => {
    return Promise.all([
      DB.setVisits([visit, ...visits]),
      DB.setReports([report, ...reports]),
    ]).then(() => {
      setVisits([visit, ...visits]);
      setReports([report, ...reports]);
    });
  };

  const editVisit = (timestamp, visit, report) => {
    const newVisits = visits.map(v => timestamp === v.timestamp ? visit : v);
    const reportExists = reports.find(r => timestamp === r.timestamp && r.submittedBy === "therapist");
    const newReports = reportExists ?
      reports.map(r => timestamp === r.timestamp && r.submittedBy === "therapist" ? report : r) :
      [report, ...reports];

    return Promise.all([
      DB.setVisits(newVisits),
      DB.setReports(newReports),
    ]).then(() => {
      setVisits(newVisits);
      setReports(newReports);
    });
  };


  const deleteVisit = (timestamp) => {
    const updatedVisits = visits.filter((v) => v.timestamp !== timestamp);
    const updatedReports = reports.filter((r) => r.timestamp !== timestamp);

    return ask("Are you sure you want to delete this entry?")
      .then(() =>
        Promise.all([
          DB.setVisits(updatedVisits),
          DB.setReports(updatedReports),
        ])
      )
      .then(() => {
        setVisits(updatedVisits);
        setReports(updatedReports);
      })
      .then(() => {
        notificationContext.onNotify("Visit deleted");
      });
  };

  const deleteReport = (timestamp) => {
    const updatedReports = reports.filter((r) => r.timestamp !== timestamp);

    return ask("Are you sure you want to delete this entry?")
      .then(() => DB.setReports(updatedReports))
      .then(() => setReports(updatedReports))
      .then(() => notificationContext.onNotify("Report deleted"));
  };

  const uploadPDF = (files) => {
    return Promise.all(
      Array.from(files).map((f) => API.uploadToPatient(f, id))
    ).then((results) => {
      const timestamp = new Date().getTime().toString().substr(0, 10);
      const newWorkouts = [
        ...results.map((r) => ({
          timestamp,
          id: `${r.data.md5}-${timestamp}`,
          isActive: true,
          type: "pdf",
          data: r.data,
        })),
        ...workouts,
      ];

      return DB.setWorkouts(newWorkouts).then(() => {
        setWorkouts(newWorkouts)

        const msg = `New workouts have been added by ${therapist.firstname}`
        const url = `/workouts/${newWorkouts[0].id}`
        return sendMessengerNotif(msg, url)
      });
    });
  };

  const addYoutubeWorkout = (youtubeID) => {
    const timestamp = new Date().getTime().toString().substr(0, 10);
    const newWorkouts = [{
        timestamp,
        id: `${youtubeID}-${timestamp}`,
        isActive: true,
        type: 'youtube',
        youtubeID
      },
      ...workouts,
    ];

    return DB.setWorkouts(newWorkouts).then(() => {
      setWorkouts(newWorkouts)

      const msg = `New workouts have been added by ${therapist.firstname}`
      const url = `/workouts/${newWorkouts[0].id}`
      return sendMessengerNotif(msg, url)
    });
  };

  const sendMessengerNotif = (title, url, buttonTitle="Show") => {
    if (Object.keys(patient).length > 0 &&
      patient.fbPSID && patient.messengerConsented) {
      return API.sendMessenger({
        psid: patient?.fbPSID,
        title,
        url,
        buttonTitle
      })
    } else {
      console.log(patient.fbPSID, patient.messengerConsented)
      return Promise.resolve()
    }
  }

  if (updating || !Object.keys(patient).length) return <Loading />;

  const contextValues = {
    ...patient,
    id,
    visits,
    reports,
    workouts,
    nextBooking,
    edit,
    addVisit,
    editVisit,
    deleteVisit,
    deleteReport,
    uploadPDF,
    addYoutubeWorkout,
    sendMessengerNotif,
  };

  return (
    <PatientContext.Provider value={contextValues}>
      <Switch>
        <Route path={`${path}/patient-info`} component={PatientInfo} />
        <Route path={`${path}/workouts/upload`} component={UploadWorkout} />
        <Route path={`${path}/workouts/:timestamp`} component={UploadWorkout} />
        <Route path={`${path}/conditions/:timestamp`} component={Condition} />
        <Route path={`${path}/conditions`} component={Conditions} />
        <Route path={`${path}/visits/new`} component={NewVisitReport} />
        <Route path={`${path}/visits/:timestamp`} component={Visit} />
        <Route path={`${path}/edit/visits/:timestamp`} component={NewVisitReport} />
        <Route path={`${path}/timeline`} component={TimeLine} />
        <Route exact path={path}>
          <Container>
            <Top />

            <Row>
              <Col style={{ marginRight: "1em" }}>
                <LastVisits />
              </Col>

              <Separator />

              <Col style={{ marginLeft: "1em" }}>
                <LastUpdate />
              </Col>
            </Row>

            <hr />
            <CurrentWorkouts />
          </Container>
        </Route>
        <Redirect to="/not-found" />
      </Switch>
    </PatientContext.Provider>
  );
};

const Col = styled.div`
  flex: 1;
`;

const Separator = styled.div`
  flex: 0 1 0px;
  border-right: 1px solid rgba(0,0,0,0.1);
  min-height: 100%;
`;

const Container = styled.div`
  flex: 1;
  overflow-x: hidden;
  min-height: 100vh;
  margin: 1em 2em;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

export default Patient;
