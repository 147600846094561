import React from "react";
import styled from "styled-components";
import colors from "../config/colors";
import fonts from "../config/fonts";

const NotFound = () => {
  return (
    <Container>
      <Title>Not found</Title>
    </Container>
  );
};

const Container = styled.div`
  flex: 1;
  min-height: 100vh;
  margin: 0 3em;
`;

const Title = styled.h1`
  color: ${colors.black};
  font-family: ${fonts.primaryBold};
  font-size: 4em;
  margin-bottom: 1em;
  margin-top: 1.5em;
  text-align: center;
`;

export default NotFound;
