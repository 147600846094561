const colors = {
  calendarBlue: "#007bff",
  black: "#333333",
  blue: "#419EA3",
  blueLowOpacity: "rgba(65, 158, 163, 0.31)",
  copperRed: "#A34641",
  green: "#6DA341",
  grey: "#AAAAAA",
  darkBlue: "#008C7D",
  darkCopperRed: "#8A3B37",
  darkGreen: "#5C8A37",
  darkGrey: "#666",
  darkPurple: "#64378A",
  danger: "#f03e3e",
  dangerDark: "#d50032",
  lightBlue: "#56D0D6",
  lightCopperRed: "#D65C56",
  lightGreen: "#90D656",
  lightGrey: "#EEE",
  lightPurple: "#9C56D6",
  messengerBlue: "#42a4ff",
  oldBlue: "#5E92CA",
  oldDarkBlue: "#3A72AE",
  purple: "#7742A3",
  red: "#d4213c",
  redDark: "#9f192d",
  white: "#f7f7f7",
  yellow: "#f9c526",
  yellowDark: "#DBA506",
};
export default colors;
