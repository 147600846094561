import React, { useContext } from "react";
import styled from "styled-components";

import BodyImage from "./BodyImage";
import colors from "../config/colors";
import fonts from "../config/fonts";
import Link from "./Link";
import PatientContext from "../context/patientContext";
import timeConverter from "../config/timeConverter";

const DisplayLastVisits = () => {
  const { id, visits, reports } = useContext(PatientContext);

  const lastVisit = visits.sort(
    (r1, r2) => parseInt(r2.timestamp) - parseInt(r1.timestamp)
  )[0];

  if (!lastVisit)
    return (
      <Field>
        <Title>No visit yet</Title>
      </Field>
    );

  const lastReport = reports.find((r) => r.timestamp === lastVisit.timestamp);

  return (
    <Container>
        <BodyImage
          report={lastReport}
          to={`/patients/${id}/visits/${lastVisit.timestamp}`}
        />

      <br />

      <FieldInline>
        <SubTitle>Last visit:</SubTitle>
        <span>
          {timeConverter(lastVisit.timestamp)}
          <Link
            style={ViewAllButton}
            hoverStyle={{ textDecoration: "underline" }}
            to={`/patients/${id}/timeline`}
          >
            (view timeline)
          </Link>
        </span>
      </FieldInline>

      {lastVisit.tags?.length > 0 && (
        <FieldInline>
          <SubTitle>Tags:</SubTitle>
          <span>{lastVisit.tags.join(", ")}</span>
        </FieldInline>
      )}

      {lastVisit.reason && (
        <Field>
          <SubTitle>Reason of the visit:</SubTitle>
          <span>{lastVisit.reason}</span>
        </Field>
      )}
      {lastVisit.additionalInfo && (
        <Field>
          <SubTitle>Additional information:</SubTitle>
          <span>{lastVisit.additionalInfo}</span>
        </Field>
      )}
      {lastVisit.treatment && (
        <Field>
          <SubTitle>Treatment plan:</SubTitle>
          <span>{lastVisit.treatment}</span>
        </Field>
      )}
      {lastVisit.postTreatment && (
        <Field>
          <SubTitle>Post-treatment evaluation:</SubTitle>
          <span>{lastVisit.postTreatment}</span>
        </Field>
      )}
      {lastVisit.futureThoughts && (
        <Field>
          <SubTitle>Future thoughts:</SubTitle>
          <span>{lastVisit.futureThoughts}</span>
        </Field>
      )}
    </Container>
  );
};

const Container = styled.div`
  color: ${colors.black};
`;

const SubTitle = styled.div`
  font-family: ${fonts.primary};
  font-size: 1.3em;
  margin-right: 0.5em;
`;

const Field = styled.div`
  font-family: ${fonts.primaryBold};
  margin-bottom: 1rem;

  > span {
    white-space: pre-line;
    font-size: 1.1em;
  }
`;

const FieldInline = styled.div`
  display: flex;
  flew-flow: row wrap;
  align-items: baseline;
  justify-content: flex-start;
  font-family: ${fonts.primaryBold};
  margin-bottom: 1rem;

  > span {
    font-size: 1.2em;
  }
`;

const Title = styled.div`
  font-family: ${fonts.secondaryBold};
  letter-spacing: -0.03em;
  font-size: 2em;
  margin-bottom: 3em;
`;

const ViewAllButton = {
  color: colors.blue,
  margin: "auto",
  marginLeft: "0.8em",
  marginBottom: "0.4em",
  fontFamily: fonts.primary,
  fontSize: "1.1em",
};

export default DisplayLastVisits;
