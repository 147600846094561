import React, { useContext } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";

import BodyImage from "../components/BodyImage";
import Button from "../components/Button";
import colors from "../config/colors";
import fonts from "../config/fonts";
import Name from "../components/PatientNameLink";
import PatientContext from "../context/patientContext";
import timeConverter from "../config/timeConverter";

const Visit = ({ match }) => {
  const history = useHistory();

  const { id, visits, deleteVisit, reports } = useContext(PatientContext);

  const visit = visits.find((v) => v.timestamp === match.params.timestamp);
  const report = reports.find((r) => r.timestamp === match.params.timestamp);

  const handleDelete = () => {
    deleteVisit(visit.timestamp).then(() =>
      history.push(`/patients/${id}/timeline`)
    );
  };

  if (visit === undefined) return null;

  return (
    <Container>
      <TitleDiv>
        <Title>
          <Name postfix="'s" />
          &nbsp;visit of {timeConverter(visit.timestamp)}
        </Title>
        <Button
          title="Edit visit"
          width="9em"
          color={colors.green}
          darkColor={colors.darkGreen}
          onClick={() => history.push(`/patients/${id}/edit/visits/${visit.timestamp}`)}
          textColor={colors.white}
          textSize="1.2em"
          icon="pen"
        />
        <Button
          title="Delete visit"
          width="9em"
          color={colors.copperRed}
          darkColor={colors.darkCopperRed}
          onClick={handleDelete}
          textColor={colors.white}
          textSize="1.2em"
          icon="trash"
        />
      </TitleDiv>

      {visit.tags?.length > 0 && (
        <SubContainer>
          <SubTitle>Tags:</SubTitle>
          <Text>{visit.tags.join(", ")}</Text>
        </SubContainer>
      )}
      {visit.reason && (
        <SubContainer>
          <SubTitle>Reason of the visit:</SubTitle>
          <Text>{visit.reason}</Text>
        </SubContainer>
      )}
      {visit.additionalInfo && (
        <SubContainer>
          <SubTitle>Additional info:</SubTitle>
          <Text>{visit.additionalInfo}</Text>
        </SubContainer>
      )}
      {visit.treatment && (
        <SubContainer>
          <SubTitle>Treatment plan:</SubTitle>
          <Text>{visit.treatment}</Text>
        </SubContainer>
      )}
      {visit.postTreatment && (
        <SubContainer>
          <SubTitle>Post-treatment evaluation:</SubTitle>
          <Text>{visit.postTreatment}</Text>
        </SubContainer>
      )}
      {visit.futureThoughts && (
        <SubContainer>
          <SubTitle>Future thoughts:</SubTitle>
          <Text>{visit.futureThoughts}</Text>
        </SubContainer>
      )}

      {report?.paths && <BodyImage report={report} />}
    </Container>
  );
};

const Container = styled.div`
  color: ${colors.black};
  flex: 1;
  min-height: 100vh;
  padding: 1em 2em;
`;

const SubContainer = styled.div`
  display: flex;
  margin: 2em 0;
`;

const SubTitle = styled.div`
  font-family: ${fonts.primary};
  font-size: 1.2em;
  flex: 1 1 8em;
  text-align: right;
  margin-right: 1.5em;
`;

const Text = styled.div`
  font-family: ${fonts.primaryBold};
  font-size: 1.2em;
  white-space: pre-line;
  flex: 7;
`;

const Title = styled.h1`
  font-family: ${fonts.secondaryBold};
  font-size: 3em;
  letter-spacing: -0.03em;
`;

const TitleDiv = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export default Visit;
