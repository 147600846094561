import React, { useState, useEffect, useRef, useContext } from "react";
import styled from "styled-components";
import { useHistory, useLocation } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import queryString from "query-string";

import colors from "../config/colors";
import Button from "../components/Button";
import fonts from "../config/fonts";
import TherapistContext from "../context/therapistContext";
import * as FB from "../Facebook";
import * as DB from "../DB";
import * as Storage from "../Storage";

const PatientList = () => {
  const { fbPageId, patients } = useContext(TherapistContext);
  const history = useHistory();
  const location = useLocation();
  const params = queryString.parse(location.hash.replace("#", "?"));

  const [search, setSearch] = useState("");

  const searchInput = useRef(null);

  const filteredPatients = patients.filter((patient) => {
    const fullname = `${patient.firstname} ${patient.lastname}`;
    const fullname2 = `${patient.lastname} ${patient.firstname}`;

    const searchRegExp = new RegExp(`${search}`, "i");

    return search === ""
      ? true
      : fullname.match(searchRegExp) ||
          fullname2.match(searchRegExp) ||
          patient.personnummer?.match(searchRegExp) ||
          patient.email?.match(searchRegExp);
  });

  const byLastname = (a, b) => a.lastname && b.lastname && a.lastname.localeCompare(b.lastname);

  const goToPatient = (id) => {
    history.push(`/patients/${id}`);
  };


  useEffect(() => {
    if (searchInput.current) searchInput.current.focus();

    const handleKeyPress = (event) => {
      if (event.keyCode === 13 && filteredPatients.length === 1) {
        history.push(`/patients/${filteredPatients[0].id}`);
      } else if (event.keyCode === 27) {
        setSearch("");
      }
    };

    document.addEventListener("keydown", handleKeyPress, false);

    return () => {
      document.removeEventListener("keydown", handleKeyPress, false);
    };
  }, [searchInput, filteredPatients, history]);

  useEffect(() => {
    FB.AUTH_ENABLED &&
      FB.login(params).then(() => {
        patients.forEach((p) => {
          if (fbPageId && p.fbPSID && !p.fbLink) {
            FB.getPatientLink(
              p.fbPSID,
              fbPageId,
              Storage.get("fbPageToken")
            ).then((link) => DB.setPatientFBLink(p.id, link));
          }
        });
      });
  }, [fbPageId, params, patients]);

  return (
    <Container>
      <TopContainer>
        <Title>
          <i className="fas fa-users" />
          &nbsp;&nbsp;Patients
        </Title>

        <Search>
          <i className="fas fa-search" />
          <Input
            ref={searchInput}
            type="text"
            placeholder="Search..."
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </Search>

        <Button
          color={colors.purple}
          darkColor={colors.darkPurple}
          link="/patients/new"
          textColor={colors.white}
          textSize="1em"
          title="Register new patient"
          width="13em"
          height="2.2em"
          icon="user-plus"
        />
      </TopContainer>

      <hr width="100%" style={{ margin: "1.5em 0 2em" }} />

      {!patients.length && (
        <Loading>
          <Spinner animation="border" size="xl" />
        </Loading>
      )}

      <CardContainer>
        {filteredPatients.sort(byLastname).map((patient, index) => {
          const sanitizedPN = patient.personnummer
            ? patient.personnummer.substring(0, patient.personnummer.length - 4)
            : undefined;

          return (
            <Card
              key={index}
              onClick={(e) => {
                e.preventDefault();
                goToPatient(patient.id);
              }}
            >
              <Name>
                {patient.lastname} {patient.firstname}
              </Name>
              <Personnummer>
                {sanitizedPN ? `${sanitizedPN}-XXXX` : "No personnummer"}
              </Personnummer>
              <IconRow onClick={(e) => e.stopPropagation()}>
                {patient?.fbLink ? (
                  <MessengerIconActive
                    target="_blank"
                    href={`https://facebook.com${patient.fbLink}`}
                  >
                    <i className="fab fa-facebook-messenger" /> Messenger
                  </MessengerIconActive>
                ) : (
                  <IconInactive>
                    <i className="fab fa-facebook-messenger" /> Messenger
                  </IconInactive>
                )}
                <div style={{ width: "1em" }} />
                {patient?.email ? (
                  <EmailIconActive href={`mailto:${patient.email}`}>
                    <i className="fas fa-envelope" /> Email
                  </EmailIconActive>
                ) : (
                  <IconInactive>
                    <i className="fas fa-envelope" /> Email
                  </IconInactive>
                )}
              </IconRow>
            </Card>
          );
        })}

        <FinalSpace />
      </CardContainer>
    </Container>
  );
};

const Card = styled.div`
  align-items: center;
  border: solid 1px ${colors.black};
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 1em;
  margin-right: 1em;
  padding: 1.5em;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &:hover {
    text-decoration: none;
    color: ${colors.blue};
  }
`;

const CardContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-flow: row wrap;
  margin-right: -1em;
`;

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  margin: 1em 2em;
`;

const EmailIconActive = styled.a`
  color: ${colors.blue};
  font-family: ${fonts.primary};
  font-size: 1em;

  &:hover {
    text-decoration: underline;
    color: ${colors.blue};
  }

  & i {
    color: ${colors.blue};
  }
`;

const IconInactive = styled.a`
  color: ${colors.grey};
  font-family: ${fonts.primary};
  font-size: 1em;

  &:hover {
    color: ${colors.grey};
    cursor: grab;
    text-decoration: none;
  }

  & i {
    color: ${colors.grey};
  }
`;

const MessengerIconActive = styled.a`
  color: ${colors.purple};
  font-family: ${fonts.primary};
  font-size: 1em;

  &:hover {
    text-decoration: underline;
    color: ${colors.purple};
  }

  & i {
    color: ${colors.purple};
  }
`;

const IconRow = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 0.5em;
`;

const Search = styled.div`
  display: flex;
  flex-grow: 1;
  margin: 0 10vw;

  & i {
    display: block;
    font-size: 1.3em;
    line-height: 1.6em;
    z-index: 2;
  }

  @media all and (max-width: 1050px) {
    margin: 0 4vw;
  }
`;

const Input = styled.input`
  border: 0;
  border-bottom: 3px solid ${colors.black};
  color: ${colors.black};
  display: flex;
  flex-grow: 1;
  font-family: ${fonts.primary};
  font-size: 1.5em;
  height: 1.5em;
  border-radius: 2px;
  padding-left: 1.5em;
  margin-left: -1em;
  z-index: 1;

  :focus {
    outline-style: none;
    border-radius: 2px;
  }
`;

const Loading = styled.div`
  width: 100%;
  text-align: center;
  padding-top: 2em;
`;

const Name = styled.div`
  color: ${colors.black};
  font-family: ${fonts.secondaryBold};
  font-size: 1.8em;
  letter-spacing: -0.02em;
`;

const Personnummer = styled.div`
  color: ${colors.black};
  font-family: ${fonts.primary};
  font-size: 0.85em;
`;

const FinalSpace = styled.div`
  flex-grow: 12;
`;

const Title = styled.div`
  color: ${colors.black};
  font-family: ${fonts.secondaryBold};
  font-size: 3em;
  line-height: 1em;
  letter-spacing: -0.03em;
  padding: 0;
  margin: 0;

  @media all and (max-width: 1050px) {
    font-size: 2em;
  }
`;

const TopContainer = styled.div`
  margin-top: 0.2em;
  align-items: center;
  display: flex;
  flex-directiong: row;
  justify-content: space-between;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

export default PatientList;
