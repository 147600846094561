import React from "react";
import { Spinner } from "react-bootstrap";
import styled from "styled-components";

const Loading = () => {
  return (
    <Container>
      <Spinner animation="border" size="xl" />
    </Container>
  );
};

const Container = styled.div`
  margin-top: 21em;
  text-align: center;
  flex: 1;
  overflow-x: hidden;
  min-height: 100vh;
  padding: 1em;
`;

export default Loading;
