import React, { useContext } from "react";
import styled from "styled-components";

import PatientContext from "../context/patientContext";

import colors from "../config/colors";
import fonts from "../config/fonts";

const ConnectedSelect = ({ dbKey, children }) => {
  const patient = useContext(PatientContext);

  const handleChange = (newValue) => {
    const updatedValue =
      newValue === "true" ? true : newValue === "false" ? false : newValue;

    patient.edit(dbKey, updatedValue)
  };

  return (
    <Select
      name={dbKey}
      value={patient[dbKey]}
      onChange={(e) => handleChange(e.target.value)}
    >
      <option value={undefined}></option>
      {children}
    </Select>
  );
};

const Select = styled.select`
  background-color: white;
  border: 1px ${colors.black} solid;
  border-radius: 0.3em;
  color: ${colors.black};
  font-family: ${fonts.primaryBold};
  font-size: 1.1em;
  flex-grow: 3;
  margin: 0 0.1em;
  max-width: 55%;
  padding: 0.1em 0.3em;
`;

export default ConnectedSelect;
