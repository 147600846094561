import React, { useContext } from "react";
import styled from "styled-components";

import { definePain, defineStress, scale } from "../Helpers";
import BodyImage from "./BodyImage";
import colors from "../config/colors";
import fonts from "../config/fonts";
import Link from "./Link";
import PatientContext from "../context/patientContext";
import timeConverter from "../config/timeConverter";

const DisplayLastUpdate = () => {
  const { id, reports } = useContext(PatientContext);

  const lastReport = reports
    .filter((r) => r.submittedBy === "patient")
    .sort((r1, r2) => parseInt(r2.timestamp) - parseInt(r1.timestamp))[0];

  if (!lastReport) return <Title>No report yet</Title>;

  return (
    <Container>
      {lastReport?.paths && (
        <BodyImage
          report={lastReport}
          to={`/patients/${id}/conditions/${lastReport.timestamp}`}
        />
      )}

      <br />

      <FieldInline>
        <SubTitle>Reported on:</SubTitle>
        <span>
          {timeConverter(lastReport.timestamp)}
          <Link
            style={ViewAllButton}
            hoverStyle={{ textDecoration: "underline" }}
            to={`/patients/${id}/timeline`}
          >
            (view timeline)
          </Link>
        </span>
      </FieldInline>

      {lastReport.tags?.length > 0 && (
        <FieldInline>
          <SubTitle>Regarding:</SubTitle>
          <span>{lastReport.tags.join(", ")}</span>
        </FieldInline>
      )}

      {lastReport.whatHappened && (
        <Field>
          <SubTitle>What happened?</SubTitle>
          <span>{lastReport.whatHappened}</span>
        </Field>
      )}
      {lastReport.painValue && (
        <Field>
          <SubTitle>How painful is it?</SubTitle>
          <span>{definePain(lastReport.painValue)} ({
            scale(lastReport.painValue)
          })</span>
        </Field>
      )}
      {lastReport.thoughts && (
        <Field>
          <SubTitle>How do you feel?</SubTitle>
          <span>{lastReport.thoughts}</span>
        </Field>
      )}
      {lastReport.stressValue && (
        <Field>
          <SubTitle>How painful is it?</SubTitle>
          <span>{defineStress(lastReport.stressValue)} ({
            scale(lastReport.stressValue)
          })</span>
        </Field>
      )}
    </Container>
  );
};

const Container = styled.div`
  color: ${colors.black};
`;

const SubTitle = styled.div`
  font-family: ${fonts.primary};
  font-size: 1.3em;
  margin-right: 0.5em;
`;

const Field = styled.div`
  font-family: ${fonts.primaryBold};
  margin-bottom: 1rem;

  > span {
    white-space: pre-line;
    font-size: 1.1em;
  }
`;

const FieldInline = styled.div`
  display: flex;
  flew-flow: row wrap;
  align-items: baseline;
  justify-content: flex-start;
  font-family: ${fonts.primaryBold};
  margin-bottom: 1rem;

  > span {
    font-size: 1.2em;
  }
`;

const Title = styled.div`
  font-family: ${fonts.secondaryBold};
  letter-spacing: -0.03em;
  font-size: 2em;
  margin-bottom: 3em;
`;

const ViewAllButton = {
  color: colors.blue,
  margin: "auto",
  marginLeft: "0.8em",
  marginBottom: "0.4em",
  fontFamily: fonts.primary,
  fontSize: "1.1em",
};

export default DisplayLastUpdate;
