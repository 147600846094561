import React, { useContext } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";

import PatientContext from "../context/patientContext";

import colors from "../config/colors";

const PatientNameLink = ({ postfix }) => {
  const patient = useContext(PatientContext);
  const history = useHistory();

  if (!patient) return null;

  const { firstname, lastname, id } = patient;

  return (
    <Name href={`/patients/${id}`}
      onClick={(e) => {
        e.preventDefault()
        history.push(`/patients/${id}`)
      }
    }>
      {firstname} {lastname}
      {postfix && postfix}
    </Name>
  );
};

const Name = styled.a`
  color: ${colors.blue};
  font-family: inherit;
  font-size: inherit;

  &:hover {
    color: ${colors.blue};
    cursor: pointer;
    text-decoration: underline;
  }
`;

export default PatientNameLink;
