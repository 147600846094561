import React, { useContext, useRef } from "react";
import styled from "styled-components";
import moment from 'moment';

import Button from "../components/Button";
import colors from "../config/colors";
import fonts from "../config/fonts";
import Link from "./Link";
import PatientContext from "../context/patientContext";

const PatientScreenTop = () => {
  const {
    firstname,
    lastname,
    fbLink,
    email,
    personnummer,
    registered_at,
    visits,
    id,
    upload,
    nextBooking,
  } = useContext(PatientContext);

  const inputFile = useRef(null);

  const now = new Date()
  const nextVisit = nextBooking && nextBooking > now ?
    moment(nextBooking).format("dddd, MMM DD @ HH:mm") : undefined

  const handleUpload = (e) => {
    upload(e.target.files)
  };

  return (
    <>
      <Container>
        <input
          multiple
          type="file"
          accept=".pdf,.PDF"
          id="doc"
          value=""
          ref={inputFile}
          onChange={handleUpload}
          style={{ display: "none" }}
        />
        <NameDiv>
          <Name>
            {firstname} {lastname}{" "}
            <Link
              style={ViewAllButton}
              hoverStyle={{ textDecoration: "underline" }}
              to={`/patients/${id}/patient-info`}
            >
              (view patient info)
            </Link>
          </Name>

          <NameSubDiv>
            <InfoDiv>
              <SubtitleDiv>
                <SubTitle>
                  Registered on:&nbsp;
                  <span style={{ fontFamily: `${fonts.primaryBold}` }}>
                    {new Date(registered_at).toLocaleDateString("se")}
                  </span>
                </SubTitle>
              </SubtitleDiv>

              <SubtitleDiv>
                <SubTitle>
                  Personnummer:&nbsp;
                  <span style={{ fontFamily: `${fonts.primaryBold}` }}>
                    {personnummer ? personnummer : <Link
                        style={ViewAllButton}
                        hoverStyle={{ textDecoration: "underline" }}
                        to={`/patients/${id}/patient-info#setPN`}
                      >
                        (set)
                      </Link>
                    }
                  </span>
                </SubTitle>
              </SubtitleDiv>
            </InfoDiv>

            <InfoDiv>
              <SubtitleDiv>
                <SubTitle>
                  Next visit:&nbsp;
                  <span style={{ fontFamily: `${fonts.primaryBold}` }}>
                    {nextVisit}
                  </span>
                </SubTitle>
              </SubtitleDiv>

              <SubtitleDiv>
                <SubTitle>
                  Total visits:&nbsp;
                  <span style={{ fontFamily: `${fonts.primaryBold}` }}>
                    {visits.length > 0 ? visits.length : 0}
                  </span>
                </SubTitle>
              </SubtitleDiv>
            </InfoDiv>
          </NameSubDiv>
        </NameDiv>

        <ActionContainer>
          <ButtonContainer>
            <Button
              disabled={!email}
              color={colors.blue}
              darkColor={colors.darkBlue}
              href={`mailto:${email}`}
              icon="envelope"
              textColor={colors.white}
              textSize="1.05rem"
              title="Contact via Email"
              width="13rem"
            />
            <Button
              disabled={!fbLink}
              color={colors.purple}
              darkColor={colors.darkPurple}
              href={`https://facebook.com${fbLink}`}
              external
              icon="facebook-messenger"
              iconFab
              textColor={colors.white}
              textSize="1.05rem"
              title="Contact on Messenger"
              width="13rem"
            />
            <Button
              color={colors.green}
              darkColor={colors.darkGreen}
              link={`/patients/${id}/visits/new`}
              icon="file-alt"
              textColor={colors.white}
              textSize="1.05rem"
              title="New visit report"
              width="13rem"
            />
            <Button
              color={colors.copperRed}
              darkColor={colors.darkCopperRed}
              link={`/patients/${id}/workouts/upload`}
              icon="upload"
              textColor={colors.white}
              textSize="1.05rem"
              title="Upload workout"
              width="13rem"
            />
          </ButtonContainer>
        </ActionContainer>
      </Container>
      <hr width="100%" />
    </>
  );
};

const ActionContainer = styled.div`
  height: 100%;
  flex: 1 0 10.6em;
`;

const ButtonContainer = styled.div`
  max-height: 7em;
  display: flex;
  flex-flow: column wrap;
  justify-content: space-between;
  align-items: flex-end;

  > div {
    margin-bottom: 0.8em;
  }

  > div:nth-child(2n) {
    margin-bottom: 0;
  }

  @media (max-width: 1050px) {
    justify-content: flex-end;
    max-height: 14em;

    > div:nth-child(2n) {
      margin-bottom: 0.8em;
    }

    > div:last-child {
      margin-bottom: 0;
    }
  }
`;

const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const ViewAllButton = {
  color: colors.blue,
  fontFamily: fonts.primary,
  fontSize: "1.2rem",
  marginLeft: "0.8em",
  letterSpacing: "0",
};

const Name = styled.h1`
  align-items: baseline;
  color: ${colors.black};
  display: flex;
  flex-flow: row wrap;
  font-family: ${fonts.secondaryBold};
  font-size: 3em;
  letter-spacing: -0.03em;
`;

const NameDiv = styled.div`
  display: flex;
  flex: 3;
  flex-direction: column;
`;

const InfoDiv = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 3em;
`;

const NameSubDiv = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;

  @media (max-width: 1050px) {
    flex-flow: column wrap;
  }
`;

const SubTitle = styled.div`
  color: ${colors.black};
  font-family: ${fonts.primary};
  font-size: 1.2em;
  flex: 1;
`;

const SubtitleDiv = styled.div`
  display: flex;
  flex: 5;
  flex-flow: row wrap;
  justify-content: flex-start;
`;

export default PatientScreenTop;
