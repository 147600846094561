import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import useWindowDimensions from "../hooks/useWindowDimensions";

import Button from "./Button";
import colors from "../config/colors";
import drawingColors from "../config/drawingColors";

function viewportToPixels(value) {
  var parts = value.match(/([0-9.]+)(vh|vw)/);
  var q = Number(parts[1]);
  var side =
    window[["innerHeight", "innerWidth"][["vh", "vw"].indexOf(parts[2])]];
  return side * (q / 100);
}

const ActionBarHorizontal = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin: 1em 0 0.5em;
`;

const ActionBarVertical = styled.div`
  align-items: center;
  flex-direction: column;
  display: flex;
  width: 10em;
  margin-left: 3em;
`;
// flex: 0.18;

const VerticalButtonDiv = styled.div`
  display: flex;
  flex-direction: column;
`;

const DrawAreaContainer = styled.div`
  background-image: url("/bodyimage.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  border: 1px solid black;
  border-radius: 8px;
  cursor: crosshair;
  height: 80vh;
  overflow: hidden;
  max-width: 130vh;
  max-height: 60vw;

  @media screen and (max-width: 1050px) {
    height: 80vh;
    max-width: 140vh;
    max-height: 60vw;
  }
`;

const Svg = styled.svg`
  width: 100%;
  height: 100%;
`;

const Spacer = styled.div`
  height: 1em;
`;

const Drawer = ({ tools, paths, setPaths }) => {
  const [color, setColor] = useState(drawingColors.red);
  const [tool, setTool] = useState("point");
  const { height, width } = useWindowDimensions();

  const undo = () => {
    if (paths.length > 0) {
      const lastPoints = paths[paths.length - 1].points;

      // Remove the last 5 paths (arrow) if it's an arrow
      const pathsToRemove =
        lastPoints.length === 1 && lastPoints[0].x === 0 ? 5 : 1;

      setPaths(paths.filter((l, i) => i < paths.length - pathsToRemove));
    }
  };

  const clear = () => setPaths([]);

  const Container = {
    display: "flex",
    flexDirection: width / height > 4 / 3 ? "row" : "column",
    justifyContent: "flex-start",
  };

  const diameter = width / height > 4 / 3 ? "3.5em" : "3em";
  const radius = width / height > 4 / 3 ? "2em" : "2em";
  const colorCircle = {
    alignItems: "center",
    backgroundColor: color,
    borderRadius: radius,
    color: color === drawingColors.yellow ? colors.black : colors.white,
    display: "flex",
    fontSize: "1.2em",
    justifyContent: "center",
    height: diameter,
    width: diameter,
  };

  //CHANGE GREEN BUTTON TO RED BUTTON

  return (
    <div id="Container" style={{ ...Container }}>
      <div
        //  style={{ flex: "1 0 80%" }}
        // style={{ minWidth: "1 0 80%" }}
        style={{ flexBasis: "130vh", maxHeight: "60vw" }}
      >
        <DrawArea setPaths={setPaths} paths={paths} color={color} tool={tool} />
      </div>

      {width / height > 4 / 3 ? (
        <>
          <ActionBarVertical id="ActionBarVertical">
            <div style={{ ...colorCircle }}>
              {tool === "point" ? (
                <i className="fas fa-pen" />
              ) : (
                <i className="fab fa-mixer" />
              )}
            </div>
            <Spacer />
            <Spacer />

            <Button
              color={drawingColors.red}
              selected={color === drawingColors.red}
              darkColor={drawingColors.redDark}
              onClick={() => setColor(drawingColors.red)}
              textColor={colors.white}
              textSize="1em"
              title="Sharp Pain"
              height="2em"
            />
            <Spacer />
            <Button
              color={drawingColors.green}
              selected={color === drawingColors.green}
              darkColor={drawingColors.greenDark}
              onClick={() => setColor(drawingColors.green)}
              textColor={colors.white}
              textSize="1em"
              title="Dull pain"
              height="2em"
            />
            <Spacer />
            <Button
              color={drawingColors.yellow}
              selected={color === drawingColors.yellow}
              darkColor={drawingColors.yellowDark}
              onClick={() => setColor(drawingColors.yellow)}
              textColor={colors.black}
              textSize="1em"
              title="Nerve Pain"
              height="2em"
            />
            <Spacer />
            <hr width="90%" />
            <Spacer />
            <Button
              color={colors.blue}
              selected={tool === "point"}
              darkColor={colors.darkBlue}
              icon="pen"
              onClick={() => setTool("point")}
              textColor={colors.white}
              textSize="1em"
              title="Draw"
              height="2em"
            />
            <Spacer />
            <Button
              color={colors.blue}
              selected={tool === "tightness"}
              darkColor={colors.darkBlue}
              icon="mixer"
              iconFab
              onClick={() => setTool("tightness")}
              textColor={colors.white}
              textSize="1em"
              title="Thightness"
              height="2em"
            />
            <Spacer />
            <hr width="90%" />
            <Spacer />
            <Button
              color={colors.copperRed}
              darkColor={colors.darkCopperRed}
              icon="undo"
              onClick={() => undo()}
              textColor={colors.white}
              textSize="1em"
              title="Undo"
              height="2em"
            />
            <Spacer />
            <Button
              color={colors.copperRed}
              darkColor={colors.darkCopperRed}
              icon="times"
              onClick={() => clear()}
              textColor={colors.white}
              textSize="1em"
              title="Clear"
              height="2em"
            />
            <Spacer />
          </ActionBarVertical>
          <div style={{ flexGrow: 1 }} />
        </>
      ) : (
        <>
          <ActionBarHorizontal id="ActionBarHorizontal">
            <div style={{ ...colorCircle }}>
              {tool === "point" ? (
                <i className="fas fa-pen" />
              ) : (
                <i className="fab fa-mixer" />
              )}
            </div>

            <VerticalButtonDiv>
              <Button
                color={drawingColors.red}
                selected={color === drawingColors.red}
                darkColor={drawingColors.redDark}
                onClick={() => setColor(drawingColors.red)}
                textColor={colors.white}
                textSize="1em"
                title="Sharp Pain"
                height="2em"
                width="10em"
              />
              <div style={{ height: "0.3em" }} />
              <Button
                color={drawingColors.green}
                selected={color === drawingColors.green}
                darkColor={drawingColors.greenDark}
                onClick={() => setColor(drawingColors.green)}
                textColor={colors.white}
                textSize="1em"
                title="Dull pain"
                height="2em"
                width="10em"
              />
              <div style={{ height: "0.3em" }} />
              <Button
                color={drawingColors.yellow}
                selected={color === drawingColors.yellow}
                darkColor={drawingColors.yellowDark}
                onClick={() => setColor(drawingColors.yellow)}
                textColor={colors.black}
                textSize="1em"
                title="Nerve Pain"
                height="2em"
                width="10em"
              />
            </VerticalButtonDiv>
            <VerticalButtonDiv>
              <Button
                color={colors.blue}
                selected={tool === "point"}
                darkColor={colors.darkBlue}
                icon="pen"
                onClick={() => setTool("point")}
                textColor={colors.white}
                textSize="1em"
                title="Draw"
                height="2em"
                width="10em"
              />
              <div style={{ height: "0.3em" }} />
              <Button
                color={colors.blue}
                selected={tool === "tightness"}
                darkColor={colors.darkBlue}
                icon="mixer"
                iconFab
                onClick={() => setTool("tightness")}
                textColor={colors.white}
                textSize="1em"
                title="Thightness"
                height="2em"
                width="10em"
              />
            </VerticalButtonDiv>
            <VerticalButtonDiv>
              <Button
                color={colors.copperRed}
                darkColor={colors.darkCopperRed}
                icon="undo"
                onClick={() => undo()}
                textColor={colors.white}
                textSize="1em"
                title="Undo"
                height="2em"
                width="10em"
              />
              <div style={{ height: "0.3em" }} />
              <Button
                color={colors.copperRed}
                darkColor={colors.darkCopperRed}
                icon="times"
                onClick={() => clear()}
                textColor={colors.white}
                textSize="1em"
                title="Clear"
                height="2em"
              />
            </VerticalButtonDiv>
          </ActionBarHorizontal>
          <hr width="100%" />
        </>
      )}
    </div>
  );
};

const DrawArea = ({ setPaths, paths, color, tool }) => {
  const [isDrawing, setIsDrawing] = useState(false);
  const drawArea = useRef(null);

  useEffect(() => {
    const da = drawArea.current;

    document.addEventListener("mouseup", handleMouseUp);
    document.addEventListener("touchend", handleTouchEnd);
    document.addEventListener("touchcancel", handleTouchCancel);
    da.addEventListener("touchstart", handleTouchStart, { passive: false });
    da.addEventListener("touchmove", handleTouchMove, { passive: false });

    return () => {
      document.removeEventListener("mouseup", handleMouseUp);
      document.removeEventListener("touchend", handleTouchEnd);
      document.removeEventListener("touchcancel", handleTouchCancel);
      da.removeEventListener("touchstart", handleTouchStart, {
        passive: false,
      });
      da.removeEventListener("touchmove", handleTouchMove, { passive: false });
    };
  });

  const handleTouchStart = (touchEvent) => {
    touchEvent.preventDefault();
    //touchEvent.stopImmediatePropagation();
    if (tool !== "tightness") {
      handleMouseDown(touchEvent);
    } else {
      handleClick(touchEvent);
    }
  };

  const handleTouchMove = (touchEvent) => {
    touchEvent.preventDefault();
    //touchEvent.stopImmediatePropagation();
    handleMouseMove(touchEvent);
  };

  const handleClick = (evt) => {
    evt.preventDefault();

    if (tool !== "tightness") {
      return;
    }

    const p = relativeCoordinatesForEvent(evt);

    const baseUnit = drawArea.current.clientHeight / 35;

    const doubleArrow = [
      [
        { x: p.x - baseUnit, y: p.y - baseUnit },
        { x: p.x - baseUnit / 4, y: p.y - baseUnit / 4 },
      ],
      [
        { x: p.x + baseUnit / 4, y: p.y + baseUnit / 4 },
        { x: p.x + baseUnit, y: p.y + baseUnit },
      ],
      [
        { x: p.x - baseUnit / 4, y: p.y - baseUnit },
        { x: p.x - baseUnit / 4, y: p.y - baseUnit / 4 },
        { x: p.x - baseUnit, y: p.y - baseUnit / 4 },
      ],
      [
        { x: p.x + baseUnit / 4, y: p.y + baseUnit },
        { x: p.x + baseUnit / 4, y: p.y + baseUnit / 4 },
        { x: p.x + baseUnit, y: p.y + baseUnit / 4 },
      ],
      [
        // Hack to undo the whole arrow at once
        { x: 0, y: 0 },
      ],
    ];

    setPaths([...paths, ...doubleArrow.map((a) => ({ color, points: a }))]);
  };

  const handleMouseDown = (evt) => {
    if (tool !== "point") {
      return;
    }

    evt.preventDefault();

    const point = relativeCoordinatesForEvent(evt);

    setIsDrawing(true);
    setPaths([...paths, { color, points: [point] }]);

    return;
  };

  const handleMouseMove = (evt) => {
    if (!isDrawing) {
      return;
    }

    evt.preventDefault();

    const point = relativeCoordinatesForEvent(evt);

    const lastPath = paths.find((l, i) => i === paths.length - 1);
    const allpathsButLast = paths.filter((l, i) => i !== paths.length - 1);

    setPaths([
      ...allpathsButLast,
      { ...lastPath, points: [...lastPath.points, point] },
    ]);
  };

  const handleMouseUp = () => setIsDrawing(false);
  const handleTouchEnd = () => setIsDrawing(false);
  const handleTouchCancel = () => setIsDrawing(false);

  const relativeCoordinatesForEvent = (evt) => {
    const boundingRect = drawArea.current.getBoundingClientRect();

    const touch = evt.touches ? true : false;

    const clientX = touch ? evt.touches[0].clientX : evt.clientX;
    const clientY = touch ? evt.touches[0].clientY : evt.clientY;

    return {
      x: clientX - boundingRect.left,
      y: clientY - boundingRect.top,
    };
  };

  return (
    <DrawAreaContainer
      ref={drawArea}
      id="drawArea"
      onMouseDown={handleMouseDown}
      onClick={handleClick}
      onMouseMove={handleMouseMove}
    >
      <Drawing paths={paths} />
    </DrawAreaContainer>
  );
};

function Drawing({ paths }) {
  return (
    <Svg className="drawing">
      {paths.map((path, index) => (
        <DrawingPath key={index} path={path} />
      ))}
    </Svg>
  );
}

function DrawingPath({ path }) {
  const strokeWidth = viewportToPixels("0.6vh");

  const pathData =
    "M " +
    path.points
      .map((p) => {
        return `${p.x} ${p.y}`;
      })
      .join(" L ");

  return (
    <path
      d={pathData}
      fill="none"
      stroke={path.color}
      strokeWidth={strokeWidth}
      strokeLinejoin="round"
      strokeLinecap="round"
    />
  );
}

export default Drawer;
