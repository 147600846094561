import React from "react";
import styled from "styled-components";

import colors from "../config/colors";
import fonts from "../config/fonts";
import Link from "./Link";
import timeConverter from "../config/timeConverter";

const DetailsCard = ({ id, data, type, handleEdit, handleDelete }) => {
  return (
    <Card>
      <CardTop>
        <Link
          to={
            type === "timeline"
              ? `/patients/${id}/visits/${data.timestamp}`
              : `/patients/${id}/conditions/${data.timestamp}`
          }
          style={Date}
          hoverStyle={{ textDecoration: "underline" }}
        >
          {data.date ? data.date : timeConverter(data.timestamp)}
        </Link>
        <CardButtons>
          <Edit onClick={handleEdit}>Edit</Edit>
          <Delete onClick={handleDelete}>Delete</Delete>
        </CardButtons>
      </CardTop>
      {data.report && (
        <Text>
          <BoldText>Report: </BoldText>
          {data.report.length > 125
            ? data.report.substring(0, 125) + " (...)"
            : data.report}
        </Text>
      )}
      {data.additionalInfo && (
        <Text>
          <BoldText>Additional info: </BoldText>
          {data.additionalInfo.length > 125
            ? data.additionalInfo.substring(0, 125) + " (...)"
            : data.additionalInfo}
        </Text>
      )}
      {data.treatment && (
        <Text>
          <BoldText>Treatment plan: </BoldText>
          {data.treatment.length > 125
            ? data.treatment.substring(0, 125) + " (...)"
            : data.treatment}
        </Text>
      )}
      {data.postTreatment && (
        <Text>
          <BoldText>Post-treatment evaluation: </BoldText>
          {data.postTreatment.length > 125
            ? data.postTreatment.substring(0, 125) + " (...)"
            : data.postTreatment}
        </Text>
      )}
      {data.futureThoughts && (
        <Text>
          <BoldText>Future thoughts: </BoldText>
          {data.futureThoughts.length > 125
            ? data.futureThoughts.substring(0, 125) + " (...)"
            : data.futureThoughts}
        </Text>
      )}
      {data.details && (
        <Text>
          <BoldText>Details: </BoldText>
          {data.details.length > 125
            ? data.details.substring(0, 125) + " (...)"
            : data.details}
        </Text>
      )}
    </Card>
  );
};

const Card = styled.div`
  border: 1px solid ${colors.black};
  border-radius: 8px;
  padding: 1.5em 1.8em;
  margin: 1.5em;
`;

const CardButtons = styled.div`
  text-align: right;
  padding: 0;
`;

const CardTop = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const Edit = styled.span`
  color: ${colors.blue};
  font-family: ${fonts.primaryBold};

  &:hover {
    cursor: pointer;
    color: ${colors.darkBlue};
  }
`;

const Date = {
  color: colors.blue,
  fontFamily: fonts.primaryBold,
  padding: 0,
};

const Delete = styled.span`
  color: ${colors.copperRed};
  font-family: ${fonts.primaryBold};
  margin-left: 10px;

  &:hover {
    cursor: pointer;
    color: ${colors.darkCopperRed};
  }
`;

const Text = styled.div`
  font-family: ${fonts.primary};
`;

const BoldText = styled.span`
  font-family: ${fonts.primaryBold};
`;

export default DetailsCard;
