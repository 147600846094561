import React, { useContext } from "react";
import styled from "styled-components";

import { definePain, defineStress, scale } from "../Helpers";
import BodyImage from "../components/BodyImage";
import colors from "../config/colors";
import fonts from "../config/fonts";
import Name from "../components/PatientNameLink";
import PatientContext from "../context/patientContext";
import timeConverter from "../config/timeConverter";

const Condition = ({ match }) => {
  const { reports } = useContext(PatientContext);

  const report = reports.find((r) => r.timestamp === match.params.timestamp);

  if (report === undefined) return null;

  return (
    <Container>
      <TitleDiv>
        <Title>
          <Name postfix="'s" />
          &nbsp;reported condition of {timeConverter(report.timestamp)}
        </Title>
      </TitleDiv>

      {report.tags?.length > 0 && (
        <SubContainer>
          <SubTitle>Regarding:</SubTitle>
          <Text>{report.tags.join(", ")}</Text>
        </SubContainer>
      )}
      {report.whatHappened && (
        <SubContainer>
          <SubTitle>What happened?</SubTitle>
          <Text>{report.whatHappened}</Text>
        </SubContainer>
      )}
      {report.painValue && (
        <SubContainer>
          <SubTitle>How painful is it?</SubTitle>
          <Text>{definePain(report.painValue)} ({scale(report.painValue)})</Text>
        </SubContainer>
      )}
      {report.thoughts && (
        <SubContainer>
          <SubTitle>How do you feel?</SubTitle>
          <Text>{report.thoughts}</Text>
        </SubContainer>
      )}
      {report.stressValue && (
        <SubContainer>
          <SubTitle>How stressful is it?</SubTitle>
          <Text>{defineStress(report.stressValue)} ({scale(report.stressValue)})</Text>
        </SubContainer>
      )}

      {report?.paths && <BodyImage report={report} />}
    </Container>
  );
};

const Container = styled.div`
  color: ${colors.black};
  font-family: ${fonts.primary};
  flex: 1;
  overflow-x: hidden;
  min-height: 100vh;
  padding: 1em 2em;
`;

const SubContainer = styled.div`
  display: flex;
  margin: 2em 0;
`;

const SubTitle = styled.div`
  font-family: ${fonts.primary};
  font-size: 1.2em;
  flex: 1 1 8em;
  text-align: right;
  margin-right: 1.5em;
`;

const Text = styled.div`
  font-family: ${fonts.primaryBold};
  font-size: 1.2em;
  white-space: pre-line;
  flex: 7;
`;

const Title = styled.h1`
  color: ${colors.black};
  font-family: ${fonts.secondaryBold};
  font-size: 3em;
  letter-spacing: -0.03em;
  padding: 0;
  margin: 0;

  @media all and (max-width: 1050px) {
    font-size: 2em;
  }
`;

const TitleDiv = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export default Condition;
