import React, { useContext, useState, useRef } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";

import colors from "../config/colors";
import fonts from "../config/fonts";
import Button from "../components/Button";
import Name from "../components/PatientNameLink";
import PatientContext from "../context/patientContext";
import NotificationContext from "../context/notificationContext";

const UploadWorkout = () => {
  const [preview, setPreview] = useState();
  const [pdf, setPDF] = useState();
  const [file, setFile] = useState();
  const [youtubeLink, setYoutubeLink] = useState();
  const [youtubeID, setYoutubeID] = useState();

  const history = useHistory();

  const notificationContext = useContext(NotificationContext);

  const {
    id,
    uploadPDF,
    addYoutubeWorkout,
  } = useContext(PatientContext);

  const inputFile = useRef(null);

  const pdfUploadPopup = () => {
    setPreview('pdf');
    inputFile.current.click();
  }

  const handlePDFChange = (e) => {
    setFile(e.target.files[0])
    setPDF(URL.createObjectURL(e.target.files[0]))
  }

  const handleYTChange = (e) => {
    const newYoutubeLink = e.target.value;
    const regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|shorts\/|watch\?v=|&v=)([^#&?]*).*/;

    const match = newYoutubeLink.match(regExp);
    if (match && match[2].length === 11) {
      setYoutubeID(match[2]);
    } else {
      setYoutubeID(null);
    }

    setYoutubeLink(newYoutubeLink);
  }

  const handleSave = () => {
    if (preview === 'pdf' && pdf && file) {
      uploadPDF([file]).then(() => notificationContext.onNotify("Workout saved"))
    }

    if (preview === 'youtube' && youtubeID) {
      addYoutubeWorkout(youtubeID).then(() => notificationContext.onNotify("Workout saved"))
    }

    history.push(`/patients/${id}`)
  };

 return (
   <Container>
     <input
       type="file"
       accept=".pdf,.PDF"
       id="doc"
       value=""
       ref={inputFile}
       onChange={handlePDFChange}
       style={{ display: "none" }}
     />
     <TitleDiv>
       <Title>
         New workout for <Name />
       </Title>
       { preview && (pdf || youtubeID) &&
         <Button
           title="Save"
           width="9em"
           color={colors.green}
           darkColor={colors.darkGreen}
           textColor={colors.white}
           onClick={() => handleSave()}
           textSize="1.2em"
         />
       }
     </TitleDiv>
     <ButtonContainer>
       <div>Choose: </div>
       <Button
         title="PDF format"
         width="9em"
         color={colors.copperRed}
         darkColor={colors.darkCopperRed}
         textColor={colors.white}
         onClick={() => pdfUploadPopup()}
         textSize="1.2em"
         icon="file-pdf"
       />
         <div>OR</div>
       <Button
         title="YouTube"
         width="9em"
         color={colors.copperRed}
         darkColor={colors.darkCopperRed}
         textColor={colors.white}
         onClick={() => setPreview('youtube')}
         textSize="1.2em"
         icon="file-video"
       />
       { preview === 'youtube' &&
         <Input
           type="text"
           value={youtubeLink}
           placeholder="Paste YouTube Video Link"
           onChange={handleYTChange}
         />
       }
     </ButtonContainer>
     { preview === 'pdf' && pdf && 
       <embed
         src={pdf}
         type="application/pdf"
         style={{
           width: "100%",
           minHeight: "100vh",
         }}
         zoom="page-width"
         page={1}
         navpanes={0}
       />
     }
     { preview === 'youtube' && youtubeID &&
       <div><iframe frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0"
         title="youtube"
         width="788.54" height="443" type="text/html"
         src={`https://www.youtube.com/embed/${youtubeID}?autoplay=0&fs=0&iv_load_policy=3&showinfo=0&rel=0&cc_load_policy=0&start=0&end=0&origin=http://youtubeembedcode.com`} />
       </div>
     }
   </Container>
 );
}

const Title = styled.div`
  font-family: ${fonts.secondaryBold};
  letter-spacing: -0.03em;
  font-size: 2em;
`;

const Container = styled.div`
  color: ${colors.black};
  flex: 1;
  min-height: 100vh;
  padding: 1em 2em;
`;

const ButtonContainer = styled.div`
  color: ${colors.black};
  display: flex;
  height: 5em;
  padding: 1em 2em;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-grow: 0;

  > div {
    margin-right: 0.8em;
  }

`;

const TitleDiv = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const Input = styled.input`
  border: 0;
  border-bottom: 1px ${colors.black} solid;
  color: ${colors.black};
  font-family: ${fonts.primaryBold};
  font-size: 1.2em;
  flex-grow: 1;
  min-width: 10em;
  transition: 200ms;

  :focus {
    outline-style: none;
  }

  :focus::placeholder {
    color: transparent;
  }

  ::placeholder {
    font-family: ${fonts.primary};
  }

  :invalid, &.invalid {
    border-bottom: 2px ${colors.purple} solid;
    color: ${colors.purple};
  }
`;

export default UploadWorkout;
