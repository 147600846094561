import React from "react";
import styled from "styled-components";
import { Spinner } from "react-bootstrap";
import { useHistory } from "react-router-dom";

import fonts from "../config/fonts";
import colors from "../config/colors";

const ButtonDiv = styled.div`
  border-radius: 0.3em;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &:hover {
    cursor: pointer;
  }

  &.selected {
    -moz-box-shadow:    inset 0 0 5px #333;
    -webkit-box-shadow: inset 0 0 5px #333;
    box-shadow:         inset 0 0 5px #333;
  }
`;

const Link = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;

  padding: 0.2em;

  &:hover {
    text-decoration: none;
    color: inherit;
  }
`;

const Button = ({
  color,
  darkColor,
  link,
  icon,
  iconFab,
  afterIcon,
  textColor,
  textSize,
  title,
  width,
  height,
  loading,
  loadingSize, // sm
  onClick,
  selected,
  disabled,
  href,
  external,
  ref,
  ...props
}) => {
  const history = useHistory();

  const c = loading || disabled ? colors.grey : color;
  const darkC = loading || disabled  ? colors.grey : darkColor;
  const textC = loading || disabled ? colors.lightGrey : textColor;

  const ButtonDivStyles = {
    backgroundColor: c,
    border: `solid ${darkC} 1px`,
    fontFamily: fonts.primaryBold,
    fontSize: textSize,
    textShadow: `1px 2px ${darkC}`,
    width: `${width || "100%"}`,
    height: `${height || "100%"}`,
  };

  const LinkStyles = {
    color: textC,
    cursor: loading ? "wait" : disabled ? "not-allowed" : "pointer",
  };

  const onClickAction = (e) => {
    if (loading) {
      e.preventDefault()
    } else if (href) {
      return;
    } else if (onClick) {
      onClick(e)
    } else {
      e.preventDefault()
      history.push(link);
    }
  }

  return (
    <ButtonDiv style={ButtonDivStyles} className={selected ? 'selected': ''} {...props}>
      <Link style={LinkStyles} onClick={onClickAction} href={href || link} target={external ? "_blank" : undefined} ref={ref}>
        {(icon && (
          <>
            <i className={`${iconFab ? "fab" : "fas"} fa-${icon}`} />
            <span>&nbsp;&nbsp;</span>
          </>
        )) ||
          (loading && (
            <>
              <Spinner
                as="span"
                animation="border"
                size={loadingSize}
                role="status"
                aria-hidden="true"
              />
              <span>&nbsp;&nbsp;</span>
            </>
          ))}

        {title}

        {afterIcon && (
          <>
            <i className={`fas fa-${afterIcon}`} />
            <span>&nbsp;&nbsp;</span>
          </>
        )}
      </Link>
    </ButtonDiv>
  );
};

export default Button;
