export const definePain = (value) => {
  const pain = 
    value > 0.9 ? 'Excruciating' :
    value > 0.7 ? 'Very painful' :
    value > 0.5 ? 'Painful' :
    value > 0.3 ? 'Uncomfortable' :
    value > 0.1 ? 'Barely noticeable' :
    'Painless'
  
  return pain
}
  
export const defineStress = (value) => {
  const stress = 
    value > 0.9 ? 'Overwhelmed' :
    value > 0.7 ? 'Completely stressed' :
    value > 0.5 ? 'Stressed' :
    value > 0.3 ? 'Somewhat stressed' :
    value > 0.1 ? 'Quite relaxed' :
    'Relaxed'
          
  return stress
}

export const scale = (value) => {
  const roundFloat = +(Math.round(value + "e+2")  + "e-2");
  const roundStr = roundFloat.toString();

  return roundStr === "1.00" ?  "10 out of 10" :
    `${roundStr[2]}.${roundStr[3]} out of 10`
}
