import React, { useContext, useState } from "react";
import { useLocation } from "react-router-dom";

import ConnectedSelect from "../ConnectedSelect";
import Input from "../ConnectedInput";
import Link from "../Link";
import colors from "../../config/colors";
import fonts from "../../config/fonts";

import PatientContext from "../../context/patientContext";
import PatientInfoContext from "../../context/patientInfoContext";

import PersonnummerModal from "../../components/PersonnummerModal";

const PersonalInfo = () => {
  const {
    id,
    firstname,
    lastname,
    gender,
    personnummer,
    address,
    city,
    postal_code,
    phone,
    children,
    occupation,
    working_hours_per_week,
    email,
  } = useContext(PatientContext);


  const location = useLocation();

  const [pnModalShown, showModal] = useState(location.hash === "#setPN");

  const {
    getHeader,
    opened,
    editing,
    Container,
    InfoContainer,
    InfoDiv,
    InfoEdit,
    Info,
    Text,
  } = useContext(PatientInfoContext);

  const category = "Personal information";
  const isOpen = opened(category);
  const isEditing = editing(category);
  const Header = () => getHeader(category, isOpen);

  return (
    <>
    <Container>
      <Header />

      {isOpen && !isEditing && (
        <InfoContainer>
          <InfoDiv>
            <Text>Name:</Text>
            <Info>
              {firstname} {lastname}
            </Info>
          </InfoDiv>
          <InfoDiv>
            <Text>Gender:</Text>
            <Info>{gender}</Info>
          </InfoDiv>
          <InfoDiv>
            <Text>Personnummer:</Text>
            <Info>{personnummer} <Link
                style={ViewAllButton}
                hoverStyle={{ textDecoration: "underline" }}
                onClick={() => showModal(true)}
                to={`/patients/${id}/patient-info#setPN`}
              >
                (change)
              </Link>
            </Info>
          </InfoDiv>
          <InfoDiv>
            <Text>Address:</Text>
            <Info>{address}</Info>
          </InfoDiv>
          <InfoDiv>
            <Text>City:</Text>
            <Info>{city}</Info>
          </InfoDiv>
          <InfoDiv>
            <Text>Postal Code:</Text>
            <Info>{postal_code}</Info>
          </InfoDiv>
          <InfoDiv>
            <Text>Phone:</Text>
            <Info>{phone}</Info>
          </InfoDiv>
          <InfoDiv>
            <Text>Children:</Text>
            <Info>{children}</Info>
          </InfoDiv>
          <InfoDiv>
            <Text>Occupation:</Text>
            <Info>{occupation}</Info>
          </InfoDiv>
          <InfoDiv>
            <Text>Working hours per week:</Text>
            <Info>{working_hours_per_week}</Info>
          </InfoDiv>
          <InfoDiv>
            <Text>Email:</Text>
            <Info>{email}</Info>
          </InfoDiv>
          <InfoDiv>
          </InfoDiv>
        </InfoContainer>
      )}

      {isOpen && isEditing && (
        <InfoContainer>
          <InfoEdit>
            <Text>Name</Text>
            <Input style={{ maxWidth: "37%" }} dbKey="firstname" />
            <Input style={{ maxWidth: "37%" }} dbKey="lastname" />
          </InfoEdit>
          <InfoEdit>
            <Text>Gender</Text>
            <ConnectedSelect dbKey="gender">
              <option value="Female">Female</option>
              <option value="Male">Male</option>
              <option value="Other">Other</option>
            </ConnectedSelect>
          </InfoEdit>
          <InfoEdit>
            <Text>Personnummer</Text>
            <Input disabled dbKey="personnummer" />
          </InfoEdit>
          <InfoEdit>
            <Text>Address</Text>
            <Input dbKey="address" />
          </InfoEdit>
          <InfoEdit>
            <Text>City</Text>
            <Input dbKey="city" />
          </InfoEdit>
          <InfoEdit>
            <Text>Postal Code</Text>
            <Input dbKey="postal_code" />
          </InfoEdit>
          <InfoEdit>
            <Text>Phone</Text>
            <Input dbKey="phone" />
          </InfoEdit>
          <InfoEdit>
            <Text>Children</Text>
            <Input dbKey="children" />
          </InfoEdit>
          <InfoEdit>
            <Text>Occupation</Text>
            <Input dbKey="occupation" />
          </InfoEdit>
          <InfoEdit>
            <Text>Working hours per week</Text>
            <Input dbKey="working_hours_per_week" />
          </InfoEdit>
          <InfoEdit>
            <Text>Email</Text>
            <Input dbKey="email" />
          </InfoEdit>
          <InfoEdit></InfoEdit>
        </InfoContainer>
      )}
      <hr width="99%" />
    </Container>
    { pnModalShown && <PersonnummerModal onClose={() => showModal(false)} /> }
    </>
  );
};

const ViewAllButton = {
  color: colors.blue,
  fontFamily: fonts.primary,
  fontSize: "1rem",
  marginLeft: "0.8em",
  letterSpacing: "0",
};


export default PersonalInfo;
