// Imports
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "./assets/fonts/Pelita Std-Regular.otf";
import ___CSS_LOADER_URL_IMPORT_1___ from "./assets/fonts/Pelita Std-Bold.otf";
import ___CSS_LOADER_URL_IMPORT_2___ from "./assets/fonts/Pelita Grande Std-Regular.otf";
import ___CSS_LOADER_URL_IMPORT_3___ from "./assets/fonts/Pelita Grande Std-Bold.otf";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face {\n  font-family: \"Pelita Std-Regular\";\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n}\n\n@font-face {\n  font-family: \"Pelita Std-Bold\";\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n}\n\n@font-face {\n  font-family: \"Pelita Grande Std-Regular\";\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ");\n}\n\n@font-face {\n  font-family: \"Pelita Grande Std-Bold\";\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ");\n}\n", "",{"version":3,"sources":["webpack://src/index.css"],"names":[],"mappings":"AAAA;EACE,iCAAiC;EACjC,4CAAkD;AACpD;;AAEA;EACE,8BAA8B;EAC9B,4CAA+C;AACjD;;AAEA;EACE,wCAAwC;EACxC,4CAA0D;AAC5D;;AAEA;EACE,qCAAqC;EACrC,4CAAuD;AACzD","sourcesContent":["@font-face {\n  font-family: \"Pelita Std-Regular\";\n  src: url(\"./assets/fonts/Pelita\\ Std-Regular.otf\");\n}\n\n@font-face {\n  font-family: \"Pelita Std-Bold\";\n  src: url(\"./assets/fonts/Pelita\\ Std-Bold.otf\");\n}\n\n@font-face {\n  font-family: \"Pelita Grande Std-Regular\";\n  src: url(\"./assets/fonts/Pelita\\ Grande\\ Std-Regular.otf\");\n}\n\n@font-face {\n  font-family: \"Pelita Grande Std-Bold\";\n  src: url(\"./assets/fonts/Pelita\\ Grande\\ Std-Bold.otf\");\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
