import React, { useContext } from "react";

import ConnectedSelect from "../ConnectedSelect";
import Input from "../ConnectedInput";

import PatientContext from "../../context/patientContext";
import PatientInfoContext from "../../context/patientInfoContext";

const HealthInfo = () => {
  const {
    been_injured,
    injury_details,
    had_surgery,
    surgery_details,
    has_acne,
    has_burns,
    has_corns,
    has_eczema,
    has_seizures,
    has_skin_problem,
    has_dermatitis,
    has_blood_clots,
    has_fibromyalgia,
    has_circulatory_problem,
    has_high_or_low_blood_pressure,
    had_stroke,
    has_abdominal_issues,
    has_reflux,
    has_irritable_bowel_syndrome,
    has_asthma,
    has_phlegm,
    has_pnumonia,
    has_hearing_problem,
    has_vision_problem,
    had_trauma,
    has_hyper_hypo_flexibility,
    has_dystrophy,
    has_compartment_syndrome,
    has_anxiety,
    has_depression,
    had_motor_vehicle_accident,
    other1,
    other2,
    other3,
    boxed_items_details,
    has_prescribed_medications,
    medication_details,
    had_acupuncture,
    had_chinese_herbs,
    had_deep_tissue,
    had_lymphatic_drainage,
    had_physiotherapy,
    had_osteopathy,
    had_psychology,
    had_natropathy,
    had_reflexology,
    had_remedial_massage,
    had_chiropractics,
    other_treatment1,
    other_treatment2,
  } = useContext(PatientContext);

  const {
    getHeader,
    opened,
    editing,
    Container,
    InfoContainer,
    InfoDiv,
    InfoDivBig,
    InfoEdit,
    InfoEditBig,
    Info,
    Text,
    SubTitle,
  } = useContext(PatientInfoContext);

  const category = "Health information";
  const isOpen = opened(category);
  const isEditing = editing(category);
  const Header = () => getHeader(category, isOpen);

  const bool = (v) => (v === true ? "Yes" : v === false ? "No" : "");

  return (
    <Container>
      <Header />

      {isOpen && !isEditing && (
        <InfoContainer>
          <InfoDiv>
            <Text>Has the patient been injured in any accident(s):</Text>
            <Info>{bool(been_injured)}</Info>
          </InfoDiv>
          {been_injured && (
            <InfoDivBig>
              <Text>When / How / Area Injured:</Text>
              <Info>{injury_details}</Info>
            </InfoDivBig>
          )}
          <InfoDiv>
            <Text>Has the patient had surgery:</Text>
            <Info>{bool(had_surgery)}</Info>
          </InfoDiv>
          {had_surgery && (
            <InfoDivBig>
              <Text>When / Why:</Text>
              <Info>{surgery_details}</Info>
            </InfoDivBig>
          )}

          <SubTitle>Skin Conditions</SubTitle>
          <InfoDiv>
            <Text>Acne:</Text>
            <Info>{bool(has_acne)}</Info>
          </InfoDiv>
          <InfoDiv>
            <Text>Burns:</Text>
            <Info>{bool(has_burns)}</Info>
          </InfoDiv>
          <InfoDiv>
            <Text>Corns:</Text>
            <Info>{bool(has_corns)}</Info>
          </InfoDiv>
          <InfoDiv>
            <Text>Eczema:</Text>
            <Info>{bool(has_eczema)}</Info>
          </InfoDiv>
          <InfoDiv>
            <Text>Seizures:</Text>
            <Info>{bool(has_seizures)}</Info>
          </InfoDiv>
          <InfoDiv>
            <Text>Skin Problems:</Text>
            <Info>{bool(has_skin_problem)}</Info>
          </InfoDiv>
          <InfoDiv>
            <Text>Dermatitis:</Text>
            <Info>{bool(has_dermatitis)}</Info>
          </InfoDiv>

          <SubTitle>Nervous / Circulatory</SubTitle>
          <InfoDiv>
            <Text>Blood Clots / DVT:</Text>
            <Info>{bool(has_blood_clots)}</Info>
          </InfoDiv>
          <InfoDiv>
            <Text>Fibromyalgia:</Text>
            <Info>{bool(has_fibromyalgia)}</Info>
          </InfoDiv>
          <InfoDiv>
            <Text>Heart, circulatory problems:</Text>
            <Info>{bool(has_circulatory_problem)}</Info>
          </InfoDiv>
          <InfoDiv>
            <Text>High / Low blood pressure:</Text>
            <Info>{bool(has_high_or_low_blood_pressure)}</Info>
          </InfoDiv>
          <InfoDiv>
            <Text>Had Stroke:</Text>
            <Info>{bool(had_stroke)}</Info>
          </InfoDiv>

          <SubTitle>Digestive</SubTitle>
          <InfoDiv>
            <Text>Abdominal issues:</Text>
            <Info>{bool(has_abdominal_issues)}</Info>
          </InfoDiv>
          <InfoDiv>
            <Text>Reflux:</Text>
            <Info>{bool(has_reflux)}</Info>
          </InfoDiv>
          <InfoDiv>
            <Text>Irritable Bowel Syndrome:</Text>
            <Info>{bool(has_irritable_bowel_syndrome)}</Info>
          </InfoDiv>

          <SubTitle>Respiratory / Hearing / Sight</SubTitle>
          <InfoDiv>
            <Text>Asthma:</Text>
            <Info>{bool(has_asthma)}</Info>
          </InfoDiv>
          <InfoDiv>
            <Text>Phlegm:</Text>
            <Info>{bool(has_phlegm)}</Info>
          </InfoDiv>
          <InfoDiv>
            <Text>TB / Pneunomia:</Text>
            <Info>{bool(has_pnumonia)}</Info>
          </InfoDiv>
          <InfoDiv>
            <Text>Hearing Problems:</Text>
            <Info>{bool(has_hearing_problem)}</Info>
          </InfoDiv>
          <InfoDiv>
            <Text>Vision Problems:</Text>
            <Info>{bool(has_vision_problem)}</Info>
          </InfoDiv>

          <SubTitle>Muscular / Skeletal</SubTitle>
          <InfoDiv>
            <Text>Accident / Trauma:</Text>
            <Info>{bool(had_trauma)}</Info>
          </InfoDiv>
          <InfoDiv>
            <Text>Hyper / Hypo-fexibility:</Text>
            <Info>{bool(has_hyper_hypo_flexibility)}</Info>
          </InfoDiv>
          <InfoDiv>
            <Text>Dystrophy:</Text>
            <Info>{bool(has_dystrophy)}</Info>
          </InfoDiv>
          <InfoDiv>
            <Text>Compartment syndrome:</Text>
            <Info>{bool(has_compartment_syndrome)}</Info>
          </InfoDiv>

          <SubTitle>Other</SubTitle>
          <InfoDiv>
            <Text>Anxiety:</Text>
            <Info>{bool(has_anxiety)}</Info>
          </InfoDiv>
          <InfoDiv>
            <Text>Depression:</Text>
            <Info>{bool(has_depression)}</Info>
          </InfoDiv>
          <InfoDiv>
            <Text>Motor Vehicle Accident:</Text>
            <Info>{bool(had_motor_vehicle_accident)}</Info>
          </InfoDiv>
          {other1?.length > 0 && (
            <InfoDiv>
              <Text>Other non-listed condition:</Text>
              <Info>{other1}</Info>
            </InfoDiv>
          )}
          {other1?.length > 0 && other2?.length > 0 && (
            <InfoDiv>
              <Text>Other non-listed condition:</Text>
              <Info>{other2}</Info>
            </InfoDiv>
          )}
          {other1?.length > 0 && other2?.length > 0 && other3?.length > 0 && (
            <InfoDiv>
              <Text>Other non-listed condition:</Text>
              <Info>{other3}</Info>
            </InfoDiv>
          )}
          {boxed_items_details?.length > 0 && (
            <InfoDiv>
              <Text>Details for any of the above:</Text>
              <Info>{boxed_items_details}</Info>
            </InfoDiv>
          )}
          <InfoDiv>
            <Text>
              Any perscribed/non-prescribed medications or supplements:
            </Text>
            <Info>{bool(has_prescribed_medications)}</Info>
          </InfoDiv>
          {has_prescribed_medications && (
            <InfoDiv>
              <Text>
                Any perscribed/non-prescribed medications or supplements:
              </Text>
              <Info>{bool(medication_details)}</Info>
            </InfoDiv>
          )}

          <SubTitle>Previous Treatments</SubTitle>
          <InfoDiv>
            <Text>Acupuncture:</Text>
            <Info>{bool(had_acupuncture)}</Info>
          </InfoDiv>
          <InfoDiv>
            <Text>Chinese Herbs:</Text>
            <Info>{bool(had_chinese_herbs)}</Info>
          </InfoDiv>
          <InfoDiv>
            <Text>Sports Massage:</Text>
            <Info>{bool(had_deep_tissue)}</Info>
          </InfoDiv>
          <InfoDiv>
            <Text>Lymphatic Drainage:</Text>
            <Info>{bool(had_lymphatic_drainage)}</Info>
          </InfoDiv>
          <InfoDiv>
            <Text>Physiotherapy:</Text>
            <Info>{bool(had_physiotherapy)}</Info>
          </InfoDiv>
          <InfoDiv>
            <Text>Osteopathy:</Text>
            <Info>{bool(had_osteopathy)}</Info>
          </InfoDiv>
          <InfoDiv>
            <Text>Psychology:</Text>
            <Info>{bool(had_psychology)}</Info>
          </InfoDiv>
          <InfoDiv>
            <Text>Naprapathy:</Text>
            <Info>{bool(had_natropathy)}</Info>
          </InfoDiv>
          <InfoDiv>
            <Text>Reflexology:</Text>
            <Info>{bool(had_reflexology)}</Info>
          </InfoDiv>
          <InfoDiv>
            <Text>Remedial Massage:</Text>
            <Info>{bool(had_remedial_massage)}</Info>
          </InfoDiv>
          <InfoDiv>
            <Text>Chiropractics:</Text>
            <Info>{bool(had_chiropractics)}</Info>
          </InfoDiv>
          {other_treatment1?.length > 0 && (
            <InfoDiv>
              <Text>Other non-listed treatment:</Text>
              <Info>{other_treatment1}</Info>
            </InfoDiv>
          )}
          {other_treatment2?.length > 0 && (
            <InfoDiv>
              <Text>Other non-listed treatment:</Text>
              <Info>{other_treatment2}</Info>
            </InfoDiv>
          )}
        </InfoContainer>
      )}

      {isOpen && isEditing && (
        <InfoContainer>
          <InfoEdit>
            <Text>Has the patient been injured in any accident(s)</Text>
            <ConnectedSelect dbKey="been_injured">
              <option value={true}>Yes</option>
              <option value={false}>No</option>
            </ConnectedSelect>
          </InfoEdit>
          <InfoEditBig>
            <Text>When / How / Area Injured</Text>
            <Input textarea dbKey="injury_details" />
          </InfoEditBig>
          <InfoEdit>
            <Text>Has the patient had surgery</Text>
            <ConnectedSelect dbKey="had_surgery">
              <option value={true}>Yes</option>
              <option value={false}>No</option>
            </ConnectedSelect>
          </InfoEdit>
          <InfoEditBig>
            <Text>When / Why</Text>
            <Input textarea dbKey="surgery_details" />
          </InfoEditBig>

          <SubTitle>Skin Conditions</SubTitle>
          <InfoEdit>
            <Text>Acne</Text>
            <ConnectedSelect dbKey="has_acne">
              <option value={true}>Yes</option>
              <option value={false}>No</option>
            </ConnectedSelect>
          </InfoEdit>
          <InfoEdit>
            <Text>Burns</Text>
            <ConnectedSelect dbKey="has_burns">
              <option value={true}>Yes</option>
              <option value={false}>No</option>
            </ConnectedSelect>
          </InfoEdit>
          <InfoEdit>
            <Text>Corns</Text>
            <ConnectedSelect dbKey="has_corns">
              <option value={true}>Yes</option>
              <option value={false}>No</option>
            </ConnectedSelect>
          </InfoEdit>
          <InfoEdit>
            <Text>Eczema</Text>
            <ConnectedSelect dbKey="has_eczema">
              <option value={true}>Yes</option>
              <option value={false}>No</option>
            </ConnectedSelect>
          </InfoEdit>
          <InfoEdit>
            <Text>Seizures</Text>
            <ConnectedSelect dbKey="has_seizures">
              <option value={true}>Yes</option>
              <option value={false}>No</option>
            </ConnectedSelect>
          </InfoEdit>
          <InfoEdit>
            <Text>Skin Problems</Text>
            <ConnectedSelect dbKey="has_skin_problem">
              <option value={true}>Yes</option>
              <option value={false}>No</option>
            </ConnectedSelect>
          </InfoEdit>
          <InfoEdit>
            <Text>Dermatitis</Text>
            <ConnectedSelect dbKey="has_dermatitis">
              <option value={true}>Yes</option>
              <option value={false}>No</option>
            </ConnectedSelect>
          </InfoEdit>
          <InfoEdit />

          <SubTitle>Nervous / Circulatory</SubTitle>
          <InfoEdit>
            <Text>Blood Clots / DVT</Text>
            <ConnectedSelect dbKey="has_blood_clots">
              <option value={true}>Yes</option>
              <option value={false}>No</option>
            </ConnectedSelect>
          </InfoEdit>
          <InfoEdit>
            <Text>Fibromyalgia</Text>
            <ConnectedSelect dbKey="has_fibromyalgia">
              <option value={true}>Yes</option>
              <option value={false}>No</option>
            </ConnectedSelect>
          </InfoEdit>
          <InfoEdit>
            <Text>Heart, circulatory problems</Text>
            <ConnectedSelect dbKey="has_circulatory_problem">
              <option value={true}>Yes</option>
              <option value={false}>No</option>
            </ConnectedSelect>
          </InfoEdit>
          <InfoEdit>
            <Text>High / Low blood pressure</Text>
            <ConnectedSelect dbKey="has_high_or_low_blood_pressure">
              <option value={true}>Yes</option>
              <option value={false}>No</option>
            </ConnectedSelect>
          </InfoEdit>
          <InfoEdit>
            <Text>Had Stroke</Text>
            <ConnectedSelect dbKey="had_stroke">
              <option value={true}>Yes</option>
              <option value={false}>No</option>
            </ConnectedSelect>
          </InfoEdit>
          <InfoEdit />

          <SubTitle>Digestive</SubTitle>
          <InfoEdit>
            <Text>Abdominal issues</Text>
            <ConnectedSelect dbKey="has_abdominal_issues">
              <option value={true}>Yes</option>
              <option value={false}>No</option>
            </ConnectedSelect>
          </InfoEdit>
          <InfoEdit>
            <Text>Reflux</Text>
            <ConnectedSelect dbKey="has_reflux">
              <option value={true}>Yes</option>
              <option value={false}>No</option>
            </ConnectedSelect>
          </InfoEdit>
          <InfoEdit>
            <Text>Irritable Bowel Syndrome</Text>
            <ConnectedSelect dbKey="has_irritable_bowel_syndrome">
              <option value={true}>Yes</option>
              <option value={false}>No</option>
            </ConnectedSelect>
          </InfoEdit>
          <InfoEdit />

          <SubTitle>Respiratory / Hearing / Sight</SubTitle>
          <InfoEdit>
            <Text>Asthma</Text>
            <ConnectedSelect dbKey="has_asthma">
              <option value={true}>Yes</option>
              <option value={false}>No</option>
            </ConnectedSelect>
          </InfoEdit>
          <InfoEdit>
            <Text>Phlegm</Text>
            <ConnectedSelect dbKey="has_phlegm">
              <option value={true}>Yes</option>
              <option value={false}>No</option>
            </ConnectedSelect>
          </InfoEdit>
          <InfoEdit>
            <Text>TB / Pneunomia</Text>
            <ConnectedSelect dbKey="has_pnumonia">
              <option value={true}>Yes</option>
              <option value={false}>No</option>
            </ConnectedSelect>
          </InfoEdit>
          <InfoEdit>
            <Text>Hearing Problems</Text>
            <ConnectedSelect dbKey="has_hearing_problem">
              <option value={true}>Yes</option>
              <option value={false}>No</option>
            </ConnectedSelect>
          </InfoEdit>
          <InfoEdit>
            <Text>Vision Problems</Text>
            <ConnectedSelect dbKey="has_vision_problem">
              <option value={true}>Yes</option>
              <option value={false}>No</option>
            </ConnectedSelect>
          </InfoEdit>
          <InfoEdit />

          <SubTitle>Muscular / Skeletal</SubTitle>
          <InfoEdit>
            <Text>Accident / Trauma</Text>
            <ConnectedSelect dbKey="had_trauma">
              <option value={true}>Yes</option>
              <option value={false}>No</option>
            </ConnectedSelect>
          </InfoEdit>
          <InfoEdit>
            <Text>Hyper / Hypo-fexibility</Text>
            <ConnectedSelect dbKey="has_hyper_hypo_flexibility">
              <option value={true}>Yes</option>
              <option value={false}>No</option>
            </ConnectedSelect>
          </InfoEdit>
          <InfoEdit>
            <Text>Dystrophy</Text>
            <ConnectedSelect dbKey="has_dystrophy">
              <option value={true}>Yes</option>
              <option value={false}>No</option>
            </ConnectedSelect>
          </InfoEdit>
          <InfoEdit>
            <Text>Compartment syndrome</Text>
            <ConnectedSelect dbKey="has_compartment_syndrome">
              <option value={true}>Yes</option>
              <option value={false}>No</option>
            </ConnectedSelect>
          </InfoEdit>

          <SubTitle>Other</SubTitle>
          <InfoEdit>
            <Text>Anxiety</Text>
            <ConnectedSelect dbKey="has_anxiety">
              <option value={true}>Yes</option>
              <option value={false}>No</option>
            </ConnectedSelect>
          </InfoEdit>
          <InfoEdit>
            <Text>Depression</Text>
            <ConnectedSelect dbKey="has_depression">
              <option value={true}>Yes</option>
              <option value={false}>No</option>
            </ConnectedSelect>
          </InfoEdit>
          <InfoEdit>
            <Text>Motor Vehicle Accident</Text>
            <ConnectedSelect dbKey="had_motor_vehicle_accident">
              <option value={true}>Yes</option>
              <option value={false}>No</option>
            </ConnectedSelect>
          </InfoEdit>
          <InfoEdit />
          <InfoEdit>
            <Text>Other non-listed condition</Text>
            <Input dbKey="other1" />
          </InfoEdit>
          {other1?.length > 0 && (
            <InfoEdit>
              <Text>Other non-listed condition</Text>
              <Input dbKey="other2" />
            </InfoEdit>
          )}
          {other1?.length > 0 && other2?.length > 0 && (
            <InfoEdit>
              <Text>Other non-listed condition</Text>
              <Input dbKey="other3" />
            </InfoEdit>
          )}
          <InfoEditBig>
            <Text>Details of {other2?.length || other3?.length ?
            'those other conditions' : 'this other condition'}</Text> 
            <Input textarea dbKey="boxed_items_details" />
          </InfoEditBig>
          <InfoEdit>
            <Text>
              Any perscribed/non-prescribed medications or supplements
            </Text>
            <ConnectedSelect dbKey="has_prescribed_medications">
              <option value={true}>Yes</option>
              <option value={false}>No</option>
            </ConnectedSelect>
          </InfoEdit>
          <InfoEdit>
            <Text>
              Any perscribed/non-prescribed medications or supplements
            </Text>
            <ConnectedSelect dbKey="medication_details">
              <option value={true}>Yes</option>
              <option value={false}>No</option>
            </ConnectedSelect>
          </InfoEdit>
          <InfoEdit />

          <SubTitle>Previous Treatments</SubTitle>
          <InfoEdit>
            <Text>Acupuncture</Text>
            <ConnectedSelect dbKey="had_acupuncture">
              <option value={true}>Yes</option>
              <option value={false}>No</option>
            </ConnectedSelect>
          </InfoEdit>
          <InfoEdit>
            <Text>Chinese Herbs</Text>
            <ConnectedSelect dbKey="had_chinese_herbs">
              <option value={true}>Yes</option>
              <option value={false}>No</option>
            </ConnectedSelect>
          </InfoEdit>
          <InfoEdit>
            <Text>Sports Massage</Text>
            <ConnectedSelect dbKey="had_deep_tissue">
              <option value={true}>Yes</option>
              <option value={false}>No</option>
            </ConnectedSelect>
          </InfoEdit>
          <InfoEdit>
            <Text>Lymphatic Drainage</Text>
            <ConnectedSelect dbKey="had_lymphatic_drainage">
              <option value={true}>Yes</option>
              <option value={false}>No</option>
            </ConnectedSelect>
          </InfoEdit>
          <InfoEdit>
            <Text>Physiotherapy</Text>
            <ConnectedSelect dbKey="had_physiotherapy">
              <option value={true}>Yes</option>
              <option value={false}>No</option>
            </ConnectedSelect>
          </InfoEdit>
          <InfoEdit>
            <Text>Osteopathy</Text>
            <ConnectedSelect dbKey="had_osteopathy">
              <option value={true}>Yes</option>
              <option value={false}>No</option>
            </ConnectedSelect>
          </InfoEdit>
          <InfoEdit>
            <Text>Psychology</Text>
            <ConnectedSelect dbKey="had_psychology">
              <option value={true}>Yes</option>
              <option value={false}>No</option>
            </ConnectedSelect>
          </InfoEdit>
          <InfoEdit>
            <Text>Naprapathy</Text>
            <ConnectedSelect dbKey="had_natropathy">
              <option value={true}>Yes</option>
              <option value={false}>No</option>
            </ConnectedSelect>
          </InfoEdit>
          <InfoEdit>
            <Text>Reflexology</Text>
            <ConnectedSelect dbKey="had_reflexology">
              <option value={true}>Yes</option>
              <option value={false}>No</option>
            </ConnectedSelect>
          </InfoEdit>
          <InfoEdit>
            <Text>Remedial Massage</Text>
            <ConnectedSelect dbKey="had_remedial_massage">
              <option value={true}>Yes</option>
              <option value={false}>No</option>
            </ConnectedSelect>
          </InfoEdit>
          <InfoEdit>
            <Text>Chiropractics</Text>
            <ConnectedSelect dbKey="had_chiropractics">
              <option value={true}>Yes</option>
              <option value={false}>No</option>
            </ConnectedSelect>
          </InfoEdit>
          <InfoEdit>
            <Text>Other non-listed treatment</Text>
            <Input dbKey="other_treatment1" />
          </InfoEdit>
          {other_treatment1?.length > 0 && (
            <InfoEdit>
              <Text>Other non-listed treatment</Text>
              <Input dbKey="other_treatment2" />
            </InfoEdit>
          )}

          <InfoEdit />
        </InfoContainer>
      )}
      <hr width="99%" />
    </Container>
  );
};

export default HealthInfo;
