import React, { useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import Personnummer from "personnummer";

import Button from "../components/Button";
import colors from "../config/colors";
import fonts from "../config/fonts";
import * as API from "../API";
import * as Storage from "../Storage";

import TherapistContext from "../context/therapistContext";

const NewPatient = () => {
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [phone, setPhone] = useState("");
  const [phoneValid, setPhoneValid] = useState(false);
  const [personnummer, setPersonnummer] = useState("");
  const [PNValid, setPNValid] = useState(false);
  const [loading, setLoading] = useState(false);

  const history = useHistory();

  const { updateTherapistsAndUsers } = useContext(TherapistContext);

  const handlePhoneChange = (e) => {
    const newPhone = e.target.value;

    if (newPhone.startsWith("07")) {
      setPhone(newPhone.replace("07", "+46 7"));
    } else if (newPhone.startsWith("00")) {
      setPhone(newPhone.replace("00", "+"));
    } else {
      setPhone(newPhone);
    }

    if (/^\+?[0-9]{10,12}/.test(newPhone.replace(/\s/g, ""))) {
      setPhoneValid(true)
    } else if (/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(newPhone.toLowerCase())) {
      setPhoneValid(true)
    } else {
      setPhoneValid(false)
    }
  };

  const handlePNChange = (e) => {
    const newPN = e.target.value;

    if (newPN.length === 10 &&
      (Personnummer.valid(newPN) || newPN.endsWith("0000"))
    ) {
      setPNValid(true)
      setPersonnummer(`19${newPN}`)
    } else if (Personnummer.valid(newPN) || newPN.endsWith("0000")) {
      setPNValid(true)
      setPersonnummer(newPN)
    } else {
      setPNValid(false)
      setPersonnummer(newPN)
    }
  }

  const register = () => {
    if (!firstname || !lastname || !personnummer || !phone ||
      !PNValid || !phoneValid) {
      window.alert("Make sure all fields are filled and valid.\nIf the patient has no personnummer, use their birthdate followed by 0000.");
      return
    }

    setLoading(true);

    API.registerNewPatient({
      firstname,
      lastname,
      phone: phone.toLowerCase().replace(/\s/g, ""),
      personnummer,
      therapist: Storage.get("Name"),
    })
      .then((id) =>
        updateTherapistsAndUsers().then(() => {
          history.push(`/patients/${id}`);
        })
      )
      .catch((e) => console.error(e))
      .finally(() => setLoading(false));
  };

  return (
    <Container>
      <SubContainer>
        <Title>
          <i className="fas fa-user-plus" />
          &nbsp;&nbsp;Register a new patient
        </Title>

        <InfoRow>
          <Input
            required
            type="text"
            value={firstname}
            placeholder="First name"
            onChange={(e) => setFirstname(e.target.value)}
          />
          <div style={{ marginRight: "2em" }} />
          <Input
            required
            type="text"
            value={lastname}
            placeholder="Last name"
            onChange={(e) => setLastname(e.target.value)}
          />
        </InfoRow>

        <div style={{ height: "1em" }} />

        <InfoRow>
          <Input
            required={PNValid}
            type="text"
            value={personnummer}
            className={PNValid ? '' : 'invalid'}
            minlength="10"
            maxlength="12"
            placeholder="Personnummer"
            onChange={handlePNChange}
          />
          <div style={{ marginRight: "2em" }} />
          <Input
            required
            type="tel"
            className={phoneValid ? '' : 'invalid'}
            value={phone}
            placeholder="Mobile phone or email"
            onChange={handlePhoneChange}
          />
        </InfoRow>

        <div style={{ height: "4em" }} />

        <div style={{ margin: "auto" }}>
          <Button
            color={colors.purple}
            darkColor={colors.darkPurple}
            textColor={colors.white}
            textSize="1.5em"
            title="Register"
            loading={loading}
            loadingSize="sm"
            height="1.8em"
            onClick={() => register()}
            width="8em"
          />
        </div>
      </SubContainer>
    </Container>
  );
};

const Container = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: center;
  overflow-x: hidden;
`;

const Input = styled.input`
  border: 0;
  border-bottom: 1px ${colors.black} solid;
  color: ${colors.black};
  font-family: ${fonts.primaryBold};
  font-size: 1.5em;
  margin-bottom: 1em;
  width: 20em;
  transition: 200ms;

  :focus {
    outline-style: none;
  }

  :focus::placeholder {
    color: transparent;
  }

  ::placeholder {
    font-family: ${fonts.primary};
  }

  :invalid, &.invalid {
    border-bottom: 2px ${colors.purple} solid;
    color: ${colors.purple};
  }
`;

const InfoRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const SubContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1em 2em;
  max-height: 85vh;
  max-width: 50vw;
  overflow-x: hidden;

  border: 1px solid ${colors.black};
  border-radius: 8px;
  padding: 4em;

  @media all and (max-width: 1560px) {
    max-width: 60vw;
    padding: 2.5em;
  }

  @media all and (max-width: 1050px) {
    max-width: 75vw;
    padding: 2.5em;
  }
`;

const Title = styled.div`
  font-family: ${fonts.primaryBold};
  font-size: 2.2em;
  margin-bottom: 2em;
`;
export default NewPatient;
