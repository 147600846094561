import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import FullCalendar from '@fullcalendar/react';
import momentPlugin from '@fullcalendar/moment';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
//import svLocale from '@fullcalendar/core/locales/sv';
import moment from 'moment';
import Swal from 'sweetalert2';
import SweetAlert2 from 'react-sweetalert2';
import { isMobile } from 'react-device-detect';

import Loading from "./Loading";
import colors from "../config/colors";
import fonts from "../config/fonts";
import * as PublicAPI from "../PublicAPI";

const PublicBooking = ({ match }) => {
  const [view, setView] = useState(
    match.params?.initialView === 'list' || isMobile ? 'listWeek' : 'timeGridWeek'
  );
  const [helpShown, setHelpShown] = useState(true);
  const [pnHelpShown, setPnHelpShown] = useState(false);
  const [updatingTherapist, setUpdatingTherapist] = useState(true);
  const [updatingBookings, setUpdatingBookings] = useState(true);
  const [updatingWorkshops, setUpdatingWorkshops] = useState(true);
  const [therapist, setTherapist] = useState({});
  const [currentStep, setCurrentStep] = useState(0);
  const [personnummer, setPersonnummer] = useState('');
  const [personnummerError, setPersonnummerError] = useState(false);
  const [patient, setPatient] = useState(null);
  const [firstname, setFirstname] = useState('');
  const [firstnameError, setFirstnameError] = useState(false);
  const [lastname, setLastname] = useState('');
  const [lastnameError, setLastnameError] = useState(false);
  const [preferredNotification, setPreferredNotification] = useState(null);
  const [notificationError, setNotificationError] = useState(false);
  const [phone, setPhone] = useState('');
  const [phoneError, setPhoneError] = useState(false);
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [consent, setConsent] = useState(false);
  const [consentError, setConsentError] = useState(false);
  const [bookings, setBookings] = useState([]);
  const [workshops, setWorkshops] = useState([]);
  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const event = selectedEvent

  const calendarRef = useRef(null);
  const personnummerRef = useRef(null);
  const firstnameRef = useRef(null);
  const lastnameRef = useRef(null);
  const preferredRef = useRef(null);
  const phoneRef = useRef(null);
  const emailRef = useRef(null);
  const publicName = match.params.therapistPublicName;

  const steps = ['1', '2', '3']
  const swalProps = {
    show: true,
    progressSteps: steps,
    currentProgressStep: currentStep,
    confirmButtonText: 'Next',
    cancelButtonText: 'Previous',
    showCancelButton: true,
    reverseButtons: true,
    showClass: { backdrop: 'swal2-noanimation' },
    hideClass: { backdrop: 'swal2-noanimation' },
    preConfirm: () => {
      if (personnummerRef.current) {
        const pn = personnummerRef.current.value

        return PublicAPI.getPatientInfo(pn).catch(e => {
          if (e.status && e.status === 404) {
            return { newPatient: true }
          } else if (e.status && e.status === 400) {
            setPersonnummerError(true);
            return false
          } else {
            return false
          }
        })
      }

      if (firstnameRef.current && lastnameRef.current) {
        const preferred = preferredRef.current.value
        const fn = firstnameRef.current.value
        const ln = lastnameRef.current.value
        const phon = preferred=== 'phone' ?
          phoneRef.current.value : undefined
        const mail = preferred === 'email' ?
          emailRef.current.value : undefined

        setFirstname(fn)
        setLastname(ln)
        setPhone(phon)
        setEmail(mail)

        return PublicAPI.registerPatient(
          publicName,
          fn,
          ln,
          phon,
          mail,
          personnummer
        ).catch(e => {
          if (e.status && e.status === 400) {
            if (e.msg.select_preference) {
              if (preferred === 'phone' && !phon) {
                setPhoneError(true)
              } else if (preferred === 'email' && !mail) {
                setEmailError(true)
              } else {
                setNotificationError(true)
              }
            }
            if (e.msg.invalid_firstname) {
              setFirstnameError(true)
            }
            if (e.msg.invalid_lastname) {
              setLastnameError(true)
            }
            if (e.msg.invalid_email) {
              setEmailError(true)
            }
            if (e.msg.invalid_phone) {
              setPhoneError(true)
            }
          } else {
            Swal.fire({
              text: `Án unexpected error happened`,
              icon: 'error',
            })
            setSelectedEvent(null)
            setCurrentStep(0)
          }
          return false
        })
      } else {
        return true
      }
    },

    onResolve: (res) => {
      if (res.isConfirmed) {

        // after personnummer step
        if (currentStep === 1) {
          if (selectedEvent.extendedProps?.workshop) {
            setPatient(res.value)
            setCurrentStep(currentStep+1)
          } else if (res.value.newPatient) {
            if (!selectedEvent.extendedProps?.allowNewPatient) {
              Swal.fire({
                text: `Only existing patient can book this time.`,
                icon: 'error',
              })
              setSelectedEvent(null)
              setCurrentStep(0)
              return null
            } else if (res.value.firstVisitBooked) {
              const whatToCheck = res.value.phone ?
                `The other booking confirmation SMS was sent to ${res.value.phone}` :
                `The other booking confirmation email was sent to ${res.value.email}`

              Swal.fire({
                html: `<p>You have already booked your first visit.</p>
                <p>Cancel your other booking if you'd like to book this one.</p>
                <small>${whatToCheck}</small>`,
                icon: 'warning',
              })
              setSelectedEvent(null)
              setCurrentStep(0)
              return null
            }
            setPatient(res.value)
            setCurrentStep(currentStep+1)
          } else {
            if (!selectedEvent.extendedProps?.allowExistingPatient) {
              Swal.fire({
                text: `Only new patient can book this time.`,
                icon: 'error',
              })
              setSelectedEvent(null)
              setCurrentStep(0)
              return null
            }
            setPatient(res.value)
            setCurrentStep(currentStep+1)
          }
        }
        // Last step
        if (currentStep === steps.length-1) {
          const type = selectedEvent.extendedProps.workshop ?
            'workshop' : 'visit'

          setSelectedEvent(null)
          setCurrentStep(0)

          PublicAPI.book(
            type,
            publicName,
            selectedEvent.id,
            personnummer
          ).then(res => {
            Swal.fire({
              title: `Successfully booked`,
              text: `A confirmation notice has been sent via ${
                preferredNotification === 'phone' ? 'SMS' : 'email'}.`,
              icon: 'success',
            })
          }).catch(e => {
            const msg = e.msg ? e.msg : 'Something went wrong, please try again'
            Swal.fire({
              text: msg,
              icon: 'error',
            })
          })

          const newBookings = bookings.filter(b => b.id !== selectedEvent.id)
          setBookings(newBookings)
        } else {
          setCurrentStep(currentStep+1)
        }

      } else if (res.isDismissed) {

        if (currentStep === 0 || res.dismiss === "backdrop") {
          setSelectedEvent(null)
          setCurrentStep(0)
        } else {
          setCurrentStep(currentStep-1)
        }
      }
    }
  }

  useEffect(() => {
    console.log(`Fetching bookings and workshops`);

    setUpdatingTherapist(true)
    setUpdatingBookings(true)
    setUpdatingWorkshops(true)

    PublicAPI
      .getTherapistInfo(publicName)
      .then((therapistInfo) => {
        setTherapist(therapistInfo)
        setUpdatingTherapist(false)
      })

    PublicAPI
      .getUpcomingBookings(publicName)
      .then((bookings) => {
        setBookings(bookings)
        setUpdatingBookings(false)
      })

    PublicAPI
      .getUpcomingWorkshops(publicName)
      .then((workshops) => {
        setWorkshops(workshops)
        setUpdatingWorkshops(false)
      })
  }, [publicName]);

  useEffect(() => {
    if (!updatingBookings && !updatingWorkshops) {
      setEvents([
        ...bookings,
        ...workshops,
      ])
    }
  }, [updatingBookings, updatingWorkshops, bookings, workshops])


  const handleEventClick = async (clickInfo) => {
    setSelectedEvent(clickInfo.event)
    setCurrentStep(0)
  }

  const selectClassNames = (arg) => {
    if (arg.event.extendedProps?.openBooking) {
      return ['open-booking']
    } if (arg.event.extendedProps?.workshop) {
      return ['workshop']
    } else {
      return []
    }
  }

  const renderEventContent = (eventInfo) => {
    if (eventInfo.event.extendedProps?.openBooking) {
      const { allowNewPatient, allowExistingPatient, description} = eventInfo.event.extendedProps
      const subtitle = allowNewPatient && !allowExistingPatient ? "Open for new visitor" :
        !allowNewPatient && allowExistingPatient ? "Open for existing patient" :
        "Open for booking"

      return <>
        <span>&nbsp;{eventInfo.timeText}</span>
        {description ? <>
          <br />
          <b>&nbsp;{description}</b></> : ''}
        <br />
        <i>&nbsp;{subtitle}</i>
      </>

    } else if (eventInfo.event.extendedProps?.workshop) {
      return <>
        <span>&nbsp;{eventInfo.timeText}</span>
        <br />
        {eventInfo.event.extendedProps.workshopURL && <b>&nbsp;
          <EventTitleLink target="_blank"
           href={eventInfo.event.extendedProps.workshopURL}>Workshop</EventTitleLink>
        </b>}
        {!eventInfo.event.extendedProps.workshopURL && <b>&nbsp;Workshop</b>}
        <br />
        <span>&nbsp;{eventInfo.event.extendedProps.workshopTitle}</span>
        <br />
        <span>&nbsp;<i>{eventInfo.event.extendedProps.spotsLeft === 0 ? 'Fully booked' : ''}</i></span>
      </>
    }
  }

  const renderListEventContent = (eventInfo) => {
    if (eventInfo.event.extendedProps?.openBooking) {
      const { allowNewPatient, allowExistingPatient, description } = eventInfo.event.extendedProps
      const title = description ? ` ${description} - `: ''
      const subtitle = allowNewPatient && !allowExistingPatient ? "Open for new visitor" :
        !allowNewPatient && allowExistingPatient ? "Open for existing patient" :
        "Open for booking"

      return <ListItem>
        <div className="fc-event-time">{eventInfo.timeText}</div>
        <div className="fc-event-title">{title}<i>{subtitle}</i></div>
      </ListItem>

    } else if (eventInfo.event.extendedProps?.workshop) {
      const { spotsLeft, workshopTitle } = eventInfo.event.extendedProps
      const title = workshopTitle ? `- ${workshopTitle}` : ''
      const subtitle = spotsLeft === 0 ? `- Fully booked` : ''

      return <ListItem>
        <div className="fc-event-time">{eventInfo.timeText}</div>
        <div className="fc-event-title">Workshop {title} {subtitle}</div>
      </ListItem>
    }
  }

  useEffect(() => {
    if(!updatingBookings && !updatingWorkshops && !updatingTherapist && helpShown) {
      if (workshops.length === 0 && bookings.length === 0) {
        Swal.fire({
          html: `${therapist.firstname} doesn't have any open booking at the moment.<br />Feel free to come back later!`,
          icon: 'info',
          didClose: () => setHelpShown(false)
        })
      } else {
        Swal.fire({
          title: `${therapist.firstname}'s available sessions`,
          html: `Find and click on an <span style="background-color: ${therapist.color}; color: white; padding: 0.2em 0.3em 0.3em 0.3em; border-radius: 0.3em; font-family: ${fonts.secondaryBold}">event</span> to see more information and book a spot.`,
          icon: 'info',
          didClose: () => setHelpShown(false)
        })
      }
    }
  }, [
    updatingTherapist,
    updatingBookings,
    updatingWorkshops,
    therapist,
    bookings,
    workshops,
    helpShown,
    setHelpShown,
  ])

  useEffect(() => {
    if(pnHelpShown) {
      Swal.fire({
        html: `If you don't have a swedish personnummer, contact the therapist directly in order to book.`,
        icon: 'warning',
        didClose: () => setPnHelpShown(false)
      })
    }
  }, [
    pnHelpShown,
  ])

  if (updatingTherapist || updatingBookings || updatingWorkshops) return <Loading />

  return (<Container>
    <NavBar>
      <Title>
        <Logo src="/logo-small-white.png" />
        <Webster>Webster</Webster>
      </Title>

      <div>
        <Color style={{ backgroundColor: therapist.color }} />
        {therapist.firstname} {therapist.lastname}
      </div>
    </NavBar>
    <CalendarContainer>
      { view === 'timeGridWeek' && <>
        <FullCalendar
          ref={calendarRef}
          headerToolbar={{
            left: 'prev,next',
            center: 'title',
            right: 'timeGridWeek,listWeek'
          }}
          plugins={[ timeGridPlugin, listPlugin, momentPlugin ]}
          firstDay={1}
          droppable={true}
          weekends={true}
          slotLabelFormat={{
            hour: 'numeric',
            minute: '2-digit',
            omitZeroMinute: false,
            meridiem: false,
            hour12: false,
          }}
          eventTimeFormat={{
            hour: 'numeric',
            minute: '2-digit',
            omitZeroMinute: false,
            meridiem: false,
            hour12: false,
          }}
          dayHeaderFormat={(info) => {
            return moment(info.date.marker).format("dddd DD")
          }}
          initialView={view}
          viewDidMount={(info) => {
            setView(info.view.type)
          }}
          hiddenDays={[0]}
          nowIndicator={true}
          dayMaxEvents={true}
          events={events}
          eventColor={therapist.color}
          initialDate={match.params.initialDate}
          datesSet={(info) => window.history.pushState(
            '', '', `/public/booking/${publicName}/week/${info.startStr.slice(0,10)}`
          )}
          allDaySlot={false}
          eventMaxStack={3}
          slotMinTime={"06:00:00"}
          slotMaxTime={"22:00:00"}
          slotDuration={"00:10:00"}
          scrollTime={"08:00:00"}
          scrollTimeReset={false}
          expandRows={true}
          eventContent={renderEventContent} // custom render function
          eventClassNames={selectClassNames}
          eventClick={handleEventClick}
          weekNumbers={true}
        />
        </>
      }

      { view === 'listWeek' && <>
        <FullCalendar
          ref={calendarRef}
          headerToolbar={{
            left: 'prev,next',
            center: isMobile ? '' : 'title',
            right: isMobile ? 'title' : 'timeGridWeek,listWeek'
          }}
          plugins={[ timeGridPlugin, listPlugin, momentPlugin ]}
          firstDay={1}
          weekends={true}
          slotLabelFormat={{
            hour: 'numeric',
            minute: '2-digit',
            omitZeroMinute: false,
            meridiem: false,
            hour12: false,
          }}
          eventTimeFormat={{
            hour: 'numeric',
            minute: '2-digit',
            omitZeroMinute: false,
            meridiem: false,
            hour12: false,
          }}
          initialView={view}
          viewDidMount={(info) => {
            setView(info.view.type)
          }}
          dayMaxEvents={true}
          hiddenDays={[0]}
          events={events}
          eventContent={renderListEventContent} // custom render function
          eventColor={therapist.color}
          allDaySlot={false}
          eventMaxStack={3}
          expandRows={true}
          initialDate={match.params.initialDate}
          datesSet={(info) => window.history.pushState(
            '', '', `/public/booking/${publicName}/list/${info.startStr.slice(0,10)}`
          )}
          eventClick={handleEventClick}
        />
        </>
      }
    </CalendarContainer>

    <Bubble
      onClick={(e) => {
        e.preventDefault();
        setHelpShown(true);
      }}
    >
      <span>?</span>
    </Bubble>

    { selectedEvent && currentStep === 0 && <SweetAlert2 {...swalProps}
      cancelButtonText='Cancel'
      showConfirmButton={
        event.extendedProps.spotsLeft === undefined ||
        event.extendedProps.spotsLeft > 0
      }
    ><PopUpContainer>
      { event.extendedProps.openBooking && <>
        <p>You are about to book a visit with {therapist.firstname}</p>
        <br />
      </> }
      {event.extendedProps.description && <>
          <p><b>Description:</b> {event.extendedProps.description}</p><br />
      </>}
      { event.extendedProps.workshop && (
        event.extendedProps.spotsLeft === undefined || event.extendedProps.spotsLeft > 0
      ) && <>
        <p>You are about to book a spot at {therapist.firstname}'s workshop:</p>
        <br />
      </> }

      {event.extendedProps.spotsLeft === 0 &&
        <><p><b>This workshop is fully booked</b></p><br /></>
      }

      {event.extendedProps.workshopTitle && <>
        <p><b>{event.extendedProps.workshopTitle}</b></p><br />
      </>}

      {event.extendedProps.workshopURL && <>
          <p><b>More info:</b> <a target="_blank" rel="noreferrer" href={
            event.extendedProps.workshopURL}>{event.extendedProps.workshopURL}</a>
          </p><br />
      </>}

      <p><b>On:</b> {moment(event.start).format("dddd")}, {
        new Date(event.start).toLocaleDateString('se-SE')}</p>
      <p><b>From:</b> {moment(event.start).format("HH:mm")}</p>
      <p><b>To:</b> {moment(event.end).format("HH:mm")}</p>
      <br />

      {event.extendedProps.allowNewPatient && !event.extendedProps.allowExistingPatient &&
        <><p><b>Note:</b> This session is open for new patients <b>only</b>.</p><br /></>
      }

      {!event.extendedProps.allowNewPatient && event.extendedProps.allowExistingPatient &&
        <><p><b>Note:</b> This session is open for registered patients <b>only</b>.</p><br /></>
      }

      {event.extendedProps.spotsLeft !== undefined && event.extendedProps.spotsLeft > 0 &&
        <><p><b>Spots left:</b> {event.extendedProps.spotsLeft}</p><br /></>
      }

      {event.extendedProps.allowNewPatient || event.extendedProps.workshop &&
        (event.extendedProps.spotsLeft === undefined || event.extendedProps.spotsLeft > 0) &&
        <p>You will be asked some personal information.</p>
      }
    </PopUpContainer></SweetAlert2> }

    { selectedEvent && currentStep === 1 && <SweetAlert2 {...swalProps}>
    <PopUpContainer>
      <p>Indicate your swedish personal number in order to verify your identity</p>
      <InputContainer>
        <input
          required
          autoFocus
          ref={personnummerRef}
          onFocus={e => e.currentTarget.select()}
          type="text"
          style={personnummerError ? { borderColor: "red" } : {}}
          defaultValue={personnummer}
          onChange={() => { setPersonnummerError(false) }}
          onBlur={e => { setPersonnummer(e.target.value) }}
          onKeyPress={event => {
            if (event.key === 'Enter') {
              setPersonnummer(event.target.value)
              // Hack: launch next step manually...
              swalProps.preConfirm().then(value => {
                const result = {
                  isDismissed: false,
                  isDenied: value === false,
                  isConfirmed: value !== false,
                  value
                }

                return swalProps.onResolve(result)
              })
            }
          }}

        />
        <label className={ personnummer !== '' ? 'filled' : ''}>
          Personnummer (yyyymmdd-xxxx)
        </label>
      </InputContainer>
      { personnummerError && <Error>
        Invalid personnummer
      </Error>}
      <br />
      <NoPersonnummer onClick={e => {
        e.preventDefault();
        setPnHelpShown(true);
      }}>I don't have a personnummer</NoPersonnummer>
    </PopUpContainer></SweetAlert2> }

    { selectedEvent && currentStep === 2 && !patient.firstname && <SweetAlert2 {...swalProps}
      confirmButtonText="Book"
    ><PopUpContainer>
      <InputContainer>
        <input
          type="text"
          ref={firstnameRef}
          style={firstnameError ? { borderColor: "red" } : {}}
          defaultValue={firstname}
          onChange={() => { setFirstnameError(false) }}
          onBlur={(e) => { setFirstname(e.target.value) }}
        />
        <label className={ firstname !== '' ? 'filled' : ''}>
          Firstname
        </label>
      </InputContainer>
      { firstnameError && <Error>
        Invalid firstname
      </Error>}
      <InputContainer>
        <input
          type="text"
          ref={lastnameRef}
          style={lastnameError ? { borderColor: "red" } : {}}
          defaultValue={lastname}
          onChange={() => { setLastnameError(false) }}
          onBlur={(e) => { setLastname(e.target.value) }}
        />
        <label className={ lastname !== '' ? 'filled' : ''}>
          Lastname
        </label>
      </InputContainer>
      { lastnameError && <Error>
        Invalid lastname
      </Error>}
      <br />
      <p>I prefer being notified by &nbsp;<select
        ref={preferredRef}
        value={preferredNotification}
        style={notificationError ? { borderColor: "red" } : {}}
        onChange={(e) => {
          setPreferredNotification(e.target.value)
          setNotificationError(false)
        }}
      >
        <option />
        <option value="phone">SMS</option>
        <option value="email">email</option>
      </select></p>
      { notificationError && <Error>
        Please choose a notification preference
      </Error>}
      { preferredNotification === "phone" && <>
        <InputContainer>
          <input
            type="text"
            ref={phoneRef}
            style={phoneError ? { borderColor: "red" } : {}}
            defaultValue={phone}
            onChange={() => { setPhoneError(false) }}
            onBlur={(e) => { setPhone(e.target.value) }}
          />
          <label className={ phone !== '' ? 'filled' : ''}>
            Phone number
          </label>
        </InputContainer>
        { phoneError && <Error>
          Invalid phone number
        </Error>}
        </>
      }
      { preferredNotification === "email" && <>
        <InputContainer>
          <input
            type="text"
            ref={emailRef}
            style={emailError ? { borderColor: "red" } : {}}
            defaultValue={email}
            onChange={() => { setEmailError(false) }}
            onBlur={(e) => { setEmail(e.target.value) }}
          />
          <label className={ email !== '' ? 'filled' : ''}>
            Email address
          </label>
        </InputContainer>
        { emailError && <Error>
          Invalid email address
        </Error>}
        </>
      }
      <br />
      <p><input
        type="checkbox"
        checked={consent}
        onChange={() => { setConsent(!consent) }}
      />
        &nbsp;&nbsp; I understand that Webster will store this data in its booking system.
      </p>
      <p>
        <small>Only notifications related to your bookings will be sent to you.</small>
      </p>
      { consentError && <Error>
        Please consent
      </Error>}
    </PopUpContainer></SweetAlert2> }

    { selectedEvent && currentStep === 2 && patient.firstname && <SweetAlert2 {...swalProps}
      confirmButtonText="Book"
    ><PopUpContainer>
        <p>You will receive a booking confirmation by {
          patient.phone ? 'SMS' : 'email' } at {
          patient.phone ? patient.phone : patient.email }.
        </p>
    </PopUpContainer></SweetAlert2> }
  </Container>);
};

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  flex: 1;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;

  & .swal2-container {
    font-family: ${fonts.primary} !important;
  }
`;

const EventTitleLink = styled.a`
  color: white !important;

  :hover, :focus {
    color: white;
  }
`

const CalendarContainer = styled.div`
  width: 100vw;
  height: 100vh;

  & div.fc-media-screen {
    font-family: ${fonts.primary};
    flex: 1;
    padding: 1em;
    height: 100%;
  }

  & .fc-scroller {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  & .fc-scroller::-webkit-scrollbar { 
    display: none; 
  }

  & a {
    color: ${colors.black};
    cursor: pointer;
  }

  & .fc .fc-button-primary {
    background-color: ${colors.black};
    border-color: ${colors.black};
  }

  & .fc .fc-button-primary:not(:disabled):active, .fc .fc-button-primary:not(:disabled).fc-button-active {
    background-color: ${colors.darkGrey};
    border-color: ${colors.black};
  }

  & .fc .fc-toolbar-title {
    font-family: ${fonts.secondaryBold};
    font-size: 2.5em;
    @media (max-width: 768px) {
      font-size: 1.6em;
    }
  }

  & .fc-col-header-cell {
    font-family: ${fonts.secondaryBold};
  }

  & .fc-timegrid-slot {
    font-family: ${fonts.secondary};
  }

  & .fc-event.open-booking {
    filter: brightness(150%);
  }

  & .fc-event.workshop {
  }
`

const NavBar = styled.div`
  background-color: ${colors.black};
  width: 100%;
  padding: 0.5rem 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  
  > div {
    font-family: ${fonts.primary};
    color: ${colors.white};
  }
`

const Title = styled.div`
`;

const Logo = styled.img`
  width: 25px;
  margin-right: 3px;
  margin-bottom: 10px;
`;

const Webster = styled.span`
  font-size: 1.6em;
  font-family: ${fonts.secondary};
  letter-spacing: -0.02em;
  color: ${colors.white};
`;

const Bubble = styled.div`
  position: fixed;
  z-index: 100;
  bottom: 35px;
  right: 35px;
  width: 50px;
  height: 50px;
  border-radius: 25px;
  background-color: #7066e0;
  color: ${colors.white};
  font-family: ${fonts.secondaryBold};
  font-size: 25px;
  box-shadow: 0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%), 0px 11px 15px -7px rgb(0 0 0 / 20%);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const Color = styled.div`
  display: inline-block;
  width: 16px;
  height: 16px;
  border-radius: 8px;
  background-color: #7066e0;
  border: 1px solid white;
  margin-right: 0.5em;
  margin-bottom: -0.1em;
`;

const ListItem = styled.div`
  cursor: pointer;
`;

const NoPersonnummer = styled.p`
  display: inline;
  font-size: 0.8em;
  cursor: pointer;
  text-decoration: none;
  color: #7066e0;

  :hover {
    text-decoration: underline;
    filter: brightness(80%);
  }
`;

const Error = styled.p`
  font-size: 0.8em;
  color: red;
`;

const InputContainer = styled.div`
   position: relative;
   display: flex;
   flex-direction: column;
   margin: 1.5rem 0.5rem 0.5rem;

   &:focus-within label {
     transform: translate(0, -1rem) scale(0.8);
     color: #7066e0;
   }

   & .filled {
     transform: translate(0, -1rem) scale(0.8);
   }

   & label {
     position: absolute;
     pointer-events: none;
     transform: translate(0, 0.5rem) scale(1);
     transform-origin: top left;
     transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
     color: #6F81A5;
     font-size: 1rem;
     font-family: ${fonts.primary};
     line-height: 1;
     left: 1rem;
   }

   & input {
     height: 2rem;
     border-radius: 0.25rem;
     border: 1px solid #6F81A5;
     padding: 0.25rem 1rem 0.25rem 1rem;
     font-size: 1rem;
     font-family: ${fonts.primaryBold};
     line-height: 1;
     outline: none;
     box-shadow: none;
     transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
   }

   & input:focus {
     border: 1px solid #7066e0;
   }
`;

const PopUpContainer = styled.div`
  text-align: left;

  & p {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    margin-bottom: 0.5rem;
  }
`;

export default PublicBooking;
