import React, { useState, useContext, useRef, useEffect, memo } from "react";
import { usePopper } from 'react-popper';
import styled from "styled-components";
import FullCalendar from '@fullcalendar/react';
import momentPlugin from '@fullcalendar/moment';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin, { Draggable } from '@fullcalendar/interaction';
//import svLocale from '@fullcalendar/core/locales/sv';
import { v4 as uuidv4 } from 'uuid';
import Autocomplete from 'react-autocomplete';
import moment from 'moment';
import Swal from 'sweetalert2';

import Button from "../components/Button";
import Loading from "./Loading";
import colors from "../config/colors";
import fonts from "../config/fonts";
import * as DB from "../DB";
import * as API from "../API";

import TherapistContext from "../context/therapistContext";

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

const patientSearch = (p, search) => {
  const fullname = `${p.firstname} ${p.lastname}`;
  const fullname2 = `${p.lastname} ${p.firstname}`;

  const searchRegExp = new RegExp(`${search}`, "i");

  return search === ""
    ? true
    : fullname.match(searchRegExp) ||
        fullname2.match(searchRegExp) ||
        p.personnummer?.match(searchRegExp) ||
        p.email?.match(searchRegExp);
}

const ExternalEvent = memo(({ event }) => {
  let elRef = useRef(null);

  useEffect(() => {
    let draggable = new Draggable(elRef.current, {
      eventData: function () {
        return { ...event };
      }
    });

    // a cleanup function
    return () => draggable.destroy();
  });

  return (
    <DraggableEvent
      ref={elRef}
      className="fc-event"
      title="Grab the event to place it on the calendar"
    >
      <i className="fas fa-arrows-alt" /> {event.title}
    </DraggableEvent>
  );
});

const Booking = ({ match }) => {
  const [view, setView] = useState(
    match.params?.initialView === 'month' ?  'dayGridMonth' :
    match.params.initialView === 'list' ? 'listWeek' :
    'timeGridWeek'
  );
  const [needsUpdate, setNeedsUpdate] = useState(false);
  const [colleagues, setColleagues] = useState([]);
  const [updatingBookings, setUpdatingBookings] = useState(false);
  const [updatingColleagues, setUpdatingColleagues] = useState(false);
  const [personalEvents, setPersonalEvents] = useState([]);
  const [disabledTherapists, setDisabledTherapists] = useState([]);
  const [colleagueEvents, setColleagueEvents] = useState([]);
  const [events, setEvents] = useState([]);
  const [startStr, setStartStr] = useState(null);
  const previousStart = usePrevious(startStr);
  const [endStr, setEndStr] = useState(null);
  const previousEnd = usePrevious(endStr);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [selectedPatientId, setSelectedPatientId] = useState(null);
  const [workshopURL, setWorkshopURL] = useState(null);
  const [workshopTitle, setWorkshopTitle] = useState(null);
  const [totalSpots, setTotalSpots] = useState(null);
  const [description, setDescription] = useState(null);
  const [allowNewPatient, setAllowNewPatient] = useState(null);
  const [allowExistingPatient, setAllowExistingPatient] = useState(null);
  const [legendOpened, setLegendOpened] = useState(true);
  const [search, setSearch] = useState("");
  const [popRefEl, setPopRefEl] = useState(null);
  const [popEl, setPopEl] = useState(null);
  const [arrowEl, setArrowEl] = useState(null);
  const { styles, attributes } = usePopper(popRefEl, popEl, {
    placement: "left",
    modifiers: [
      { name: 'arrow', options: { element: arrowEl, }},
      { name: "offset", options: { offset: [-50, 0] }},
      { name: 'preventOverflow', enabled: true }
    ],
  });

  const therapist = useContext(TherapistContext);

  const patients = therapist.patients;

  const searchInput = useRef(null);
  const calendarRef = useRef(null);
  const toolbarRef = useRef(null);

  const selectedEventType = selectedEvent ?
    selectedEvent.extendedProps?.patientId ? 'booking' :
    selectedEvent.extendedProps?.openBooking === true ? 'openBooking' :
    selectedEvent.extendedProps?.workshop === true ? 'workshop' :
    'newBooking' : null

  const isNewEvent = selectedEventType === 'newBooking'

  const selectedEventPatient = !isNewEvent && selectedEvent && selectedEvent.extendedProps?.patientId ?
    patients.find(p => p.id === selectedEvent.extendedProps.patientId) : null

  const newEventPatient = isNewEvent && selectedPatientId ?
    patients.find(p => p.id === selectedPatientId) : null

  const eventPatient = isNewEvent ? newEventPatient : selectedEventPatient

  const notificationMedium = eventPatient ?
    eventPatient.fbPSID ? "Messenger" : eventPatient.phone ? "SMS" : "email" : "email"

  useEffect(() => {
    console.log(`Initialize DB`);

    setNeedsUpdate(true)

    API.getColleagues(therapist.id).then((c) => {
      setColleagues(c)
    })

    DB.pull(`${therapist.id}_bookings`, (info) => {
      if (info && info.docs_read > 0) {
        setNeedsUpdate(true)
      }
      return Promise.resolve()

    }, 0, {fields: ['start']})

    // Start push live update
    DB.push(`${therapist.id}_bookings`)

    return function cleanup() {
      DB.clean()
    }
  }, [therapist]);


  useEffect(() => {
    //if ((needsUpdate === true && startStr && endStr) || (
    //  previousStart !== startStr || previousEnd !== endStr
    //)) {
    if ((previousStart === null || previousEnd === null) && (
      previousStart !== startStr || previousEnd !== endStr
    )) {
      setUpdatingBookings(true)

      let twoWeeksAgo = new Date(startStr)
      let inThreeWeeks = new Date(endStr)

      twoWeeksAgo.setDate(twoWeeksAgo.getDate() - 14)
      inThreeWeeks.setDate(inThreeWeeks.getDate() + 21)

      DB.getEvents(twoWeeksAgo.toISOString(), inThreeWeeks.toISOString()).then((docs) => {
        setPersonalEvents(docs)
        setUpdatingBookings(false)
        setNeedsUpdate(false)
      })

    }
  }, [needsUpdate, startStr, endStr, previousStart, previousEnd]);

  useEffect(() => {
    console.log(`Fetching colleagues bookings`);

    setUpdatingColleagues(true)

    let oneWeekAgo = new Date(startStr)
    let inOneWeek = new Date(endStr)

    oneWeekAgo.setDate(oneWeekAgo.getDate() - 7)
    inOneWeek.setDate(inOneWeek.getDate() + 7)


    const fetchColleagueBookings = () => {
      Promise.all(
        colleagues.map(colleague =>
          API.getColleagueBookings(colleague.id, oneWeekAgo.toISOString(), inOneWeek.toISOString())
          .then(colleagueBookings =>
            colleagueBookings.map(e => ({
              ...e,
              color: colleague.color,
              therapist: colleague.id,
              editable: false,
            }))
          )
        )
      )
      .then(coloredEvents => {
        setColleagueEvents(coloredEvents.flatMap(c => c))
        setUpdatingColleagues(false)
      })
    }

    fetchColleagueBookings()
    const fetchColleagueTimeout = setInterval(fetchColleagueBookings, 5 * 60 * 1000) // 5 minutes

    return () => {
      clearInterval(fetchColleagueTimeout)
    }
  }, [colleagues, startStr, endStr]);

  useEffect(() => {
    if (!updatingBookings && !updatingColleagues) {
      const includedPersonalEvents = disabledTherapists.includes(therapist.id) ? [] : personalEvents
      const includedColleagueEvents = colleagueEvents.filter(e => !disabledTherapists.includes(e.therapist))

      setEvents([
        ...includedPersonalEvents,
        ...includedColleagueEvents,
      ])
    }
  }, [updatingColleagues, updatingBookings, personalEvents, colleagueEvents, disabledTherapists, therapist]);

  const handleDateSelect = (selectInfo) => {
    const event = {
      id: uuidv4(),
      start: selectInfo.startStr,
      end: selectInfo.endStr,
      allDay: selectInfo.allDay,
      extendedProps: {
        newEvent: true,
        therapist: therapist.id
      }
    }

    DB.createEvent(event).then(() => {
      setPersonalEvents([...personalEvents, event])
    })
  }

  const handleEventReceive = (dropInfo) => {
    const event = {
      id: uuidv4(),
      start: dropInfo.event.startStr,
      end: dropInfo.event.endStr,
      allDay: dropInfo.event.allDay,
      extendedProps: {
        ...dropInfo.event.extendedProps
      }
    }

    const now = new Date()
    if (now > new Date(dropInfo.event.endStr)) {
      Swal.fire({
        text: `You dropped the event at a time in the past. Make sure to move it in the future if you want it to show on your public booking page.`,
        icon: 'warning',
      })
    }

    DB.createEvent(event).then(() => {
      setPersonalEvents([...personalEvents, event])
      dropInfo.revert()
    })

  }

  const handleOnClick = (el) => {
    //console.log(el.target)
    if (el.target.className !== "fc-event-main" &&
      !el.target.attributes["data-popper-placement"] &&
      !el.target.attributes["type"] &&
      !el.target.attributes["role"] &&
      el.target.className !== "list-item" &&
      el.target.className !== "keep-popup"
    ) {
      //setPopRefEl(null)
      setSelectedEvent(null)
    }
  }

  const handleEventDidMount = (info) => {
    if (info.event.extendedProps?.newEvent && info.event.extendedProps?.therapist === therapist.id) {
      setPopRefEl(info.el)
      setSelectedEvent(info.event)
    }
  }

  const handleEventClick = (clickInfo) => {
    if (clickInfo.event.startEditable !== false) {
      setPopRefEl(clickInfo.el)
      setSelectedEvent(selectedEvent?.id === clickInfo.event.id ? null : clickInfo.event)
    }
  }

  const toggleColleagueBookings = (colleagueID) => {
    setDisabledTherapists(
      disabledTherapists.includes(colleagueID) ?
        disabledTherapists.filter(c => c !== colleagueID) :
        [...disabledTherapists ,colleagueID]
    )
  }

  const selectClassNames = (arg) => {
    if (arg.event.extendedProps?.openBooking && !arg.event.extendedProps.patientId) {
      return ['open-booking']
    } if (arg.event.extendedProps?.workshop) {
      return ['workshop']
    } else {
      return []
    }
  }

  const renderEventContent = (eventInfo) => {
    const event = eventInfo.event

    if (event.extendedProps.patientId) {
      const pID = event.extendedProps.patientId
      const patient = patients.find(p => p.id === pID)
      const name = patient ? `${patient.lastname} ${patient.firstname}` : 'Unknown patient (please reload)'

      return (
        <>
          <span>&nbsp;{eventInfo.timeText}</span>
          <br />
          <b>&nbsp;<EventTitleLink target="_blank" href={`/patients/${pID}`}>{name}</EventTitleLink></b>
          {patient && patient.newPatient && <>
            <br />
            <i>&nbsp;First visit</i>
          </>}
          {patient && !patient.newPatient && event.extendedProps?.openBooking && <>
            <br />
            <i>&nbsp;Booked by the patient</i>
          </>}
        </>
      )
    } else if (event.extendedProps?.openBooking) {
      const title = event.extendedProps.description
      const subtitle = event.extendedProps?.allowNewPatient &&
        !event.extendedProps?.allowExistingPatient ?
          "Open for new patient" :
        !event.extendedProps?.allowNewPatient &&
        event.extendedProps?.allowExistingPatient ?
          "Open for existing patient" :
          "Open for booking"

      return (
        <>
          <span>&nbsp;{eventInfo.timeText}</span>
          {title ? <>
            <br/><b>&nbsp;{title}</b></>: '' }
          <br />
          <i>&nbsp;{subtitle}</i>
        </>
      )
    } else if (event.extendedProps?.workshop) {
      return (
        <>
          <span>&nbsp;{eventInfo.timeText}</span>
          <br />
          {event.extendedProps.workshopURL && <b>&nbsp;
            <EventTitleLink target="_blank"
             href={event.extendedProps.workshopURL}>Workshop</EventTitleLink>
          </b>}
          {!event.extendedProps.workshopURL && <b>&nbsp;Workshop</b>}
          <br />
          <span>&nbsp;{event.extendedProps.workshopTitle}</span>
        </>
      )
    }
  }

  const renderMonthEventContent = (eventInfo) => {
    const pID = eventInfo.event.extendedProps.patientId
    const patient = patients.find(p => p.id === pID)
    const name = patient ? `${patient.lastname} ${patient.firstname}` : ""

    return (
      <>
        &nbsp;
        <div className="fc-event-time">{eventInfo.timeText}</div>
        <div className="fc-event-title"><PatientLinkMonth target="_blank" href={`/patients/${pID}`}>{name}</PatientLinkMonth></div>
      </>
    )
  }

  const renderListEventContent = (eventInfo) => {
    const pID = eventInfo.event.extendedProps.patientId
    const patient = patients.find(p => p.id === pID)
    const name = patient ? `${patient.lastname} ${patient.firstname}` : ""

    return (
      <>
        <div className="fc-event-time">{eventInfo.timeText}</div>
        <div className="fc-event-title"><PatientLinkMonth target="_blank" href={`/patients/${pID}`}>{name}</PatientLinkMonth></div>
      </>
    )
  }


  const saveSelectedEvent = async () => {
    const event = events.find(e => e.id === selectedEvent.id)
    const shouldNotify = isNewEvent && new Date(event.start) > new Date()

    const notify = shouldNotify && await Swal.fire({
      title: `Notify ${newEventPatient.firstname}?`,
      text: `The booking confirmation will be sent via ${notificationMedium}.`,
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#333333',
      confirmButtonText: 'Yes, send the notification',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.isConfirmed) {
        API.notifyPatient(newEventPatient.id,
          `Your booking information`,
          `This is a booking confirmation from Webster Body Therapy.

Booked: ${moment(selectedEvent.start).format("dddd")}, ${new Date(selectedEvent.start).toLocaleDateString('se-SE')}
From: ${moment(selectedEvent.start).format("HH:mm")}
To: ${moment(selectedEvent.end).format("HH:mm")}

With: ${therapist.firstname}

Address:
Webster Body Therapy
Klangfärgsgatan 14
426 52 Västra Frölunda

Please make contact a minimum of 24hrs before to make a rebooking or cancellation. 

Have a great day,
Webster Body Therapy
`).then(() => Swal.fire(
          `${capitalizeFirstLetter(notificationMedium)} sent!`,
          `${newEventPatient.firstname} has been notified.`,
          'success'
        )).catch(e => Swal.fire(
          `${capitalizeFirstLetter(notificationMedium)} notification failed`,
          `${e}`,
          'error'
        ))
      }

      return result.isConfirmed
    })

    const filteredPersonalEvents = personalEvents.filter(e => e.id !== selectedEvent.id)
    const updatedEvent = {
      ...event,
      extendedProps: {
        ...event.extendedProps,
        patientId: newEventPatient.id,
        newEvent: false,
        notify,
      }
    }

    return DB.modifyEvent(updatedEvent).then(() => {
      setPersonalEvents([...filteredPersonalEvents, updatedEvent])
    })
  }

  const saveWorkshopInfo = () => {
    const event = events.find(e => e.id === selectedEvent.id)

    const filteredPersonalEvents = personalEvents.filter(e => e.id !== selectedEvent.id)
    const updatedEvent = {
      ...event,
      extendedProps: {
        ...event.extendedProps,
        workshopTitle: workshopTitle === null ? event.extendedProps.workshopTitle : workshopTitle,
        workshopURL: workshopURL === null ? event.extendedProps.workshopURL : workshopURL,
        totalSpots: totalSpots === null ? event.extendedProps.totalSpots : parseInt(totalSpots),
      }
    }

    return DB.modifyEvent(updatedEvent).then(() => {
      setPersonalEvents([...filteredPersonalEvents, updatedEvent])
    })
  }

  const saveOpenBookingInfo = () => {
    const event = events.find(e => e.id === selectedEvent.id)

    const filteredPersonalEvents = personalEvents.filter(e => e.id !== selectedEvent.id)
    const updatedEvent = {
      ...event,
      extendedProps: {
        ...event.extendedProps,
        allowNewPatient: allowNewPatient === null ?
          event.extendedProps.allowNewPatient : allowNewPatient,
        allowExistingPatient: allowExistingPatient === null ?
          event.extendedProps.allowExistingPatient : allowExistingPatient,
        description: description === null ? event.extendedProps.description : description,
      }
    }

    return DB.modifyEvent(updatedEvent).then(() => {
      setPersonalEvents([...filteredPersonalEvents, updatedEvent])
    })
  }

  const handleEventModification = (info) => {
    const { event } = info

    const shouldNotify = event.extendedProps?.notify === true && new Date(event.start) > new Date() && event.extendedProps.patientId

    if (shouldNotify) {
      const eventPatient = patients.find(p => p.id === event.extendedProps.patientId)
      const eventNotificationMedium = eventPatient ? eventPatient.fbPSID ? "Messenger" : eventPatient.phone ? "SMS" : "email" : "email"
      Swal.fire({
        title: `Notify ${eventPatient.firstname}?`,
        text: `The booking modification notice will be sent via ${eventNotificationMedium}.`,
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#333333',
        confirmButtonText: 'Yes, send the notification',
        cancelButtonText: 'No'
      }).then((result) => {
        if (result.isConfirmed) {
          API.notifyPatient(eventPatient.id,
            `Your new booking information`,
            `This is a booking modification notice from Webster Body Therapy.

Booked: ${moment(event.start).format("dddd")}, ${new Date(event.start).toLocaleDateString('se-SE')}
From: ${moment(event.start).format("HH:mm")}
To: ${moment(event.end).format("HH:mm")}

With: ${therapist.firstname}

Address:
Webster Body Therapy
Klangfärgsgatan 14
426 52 Västra Frölunda

Please make contact a minimum of 24hrs before to make a rebooking or cancellation. 

Have a great day,
Webster Body Therapy
`).then(() => Swal.fire(
            `${capitalizeFirstLetter(eventNotificationMedium)} sent!`,
            `${eventPatient.firstname} has been notified.`,
            'success'
          )).catch(e => Swal.fire(
            `${capitalizeFirstLetter(eventNotificationMedium)} notification failed`,
            `${e}`,
            'error'
          ))
        }
      })
    }

    const updatedEvent = event.toPlainObject()
    const filteredPersonalEvents = personalEvents.filter(e => e.id !== updatedEvent.id)

    return DB.modifyEvent(updatedEvent).then(() => {
      setPersonalEvents([...filteredPersonalEvents, updatedEvent])
    })
  }

  const closeOpenBooking = (cb) => {
    if (!selectedEvent) return cb && cb(false)

    if (new Date() > new Date(selectedEvent.start)) {
      return DB.removeEvent(selectedEvent.id).then(() => {
        setPersonalEvents(personalEvents.filter(e => e.id !== selectedEvent.id))
        setSelectedEvent(null)
        cb && cb(true)
      })
    }

    Swal.fire({
      title: `Close this booking time?`,
      html: `
      <p><b>From:</b> ${new Date(selectedEvent.start).toLocaleString('se-SE')}</p>
      <p><b>To:</b> ${new Date(selectedEvent.end).toLocaleString('se-SE')}</p>
      <br />
      <p>It won't show on your public booking page anymore.</p>
      `,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#dc3545',
      cancelButtonColor: '#333333',
      confirmButtonText: 'Yes, close this time',
      cancelButtonText: 'Nevermind'
    }).then((result) => {
      if (result.isConfirmed) {
        return DB.removeEvent(selectedEvent.id).then(() => {
          setPersonalEvents(personalEvents.filter(e => e.id !== selectedEvent.id))
          setSelectedEvent(null)
          cb && cb(true)
        })
      } else {
        return cb && cb(false)
      }
    })
  }

  const cancelWorkshop = (cb) => {
    if (!selectedEvent) return cb && cb(false)
    console.log(selectedEvent)
    const title = selectedEvent.extendedProps.workshopTitle

    Swal.fire({
      title: `Cancel the workshop${title ? ` "${title}"` : ''}?`,
      html: `
      <p><b>From:</b> ${new Date(selectedEvent.start).toLocaleString('se-SE')}</p>
      <p><b>To:</b> ${new Date(selectedEvent.end).toLocaleString('se-SE')}</p>
      <br />
      <p>The participants will be notified.</p>
      `,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#dc3545',
      cancelButtonColor: '#333333',
      confirmButtonText: 'Yes, cancel the workshop',
      cancelButtonText: 'Nevermind'
    }).then((result) => {
      if (result.isConfirmed) {
        //TODO: notify participants?
        return DB.removeEvent(selectedEvent.id).then(() => {
          setPersonalEvents(personalEvents.filter(e => e.id !== selectedEvent.id))
          setSelectedEvent(null)
          cb && cb(true)
        })
      } else {
        return cb && cb(false)
      }
    })
  }

  const cancelSelectedEvent = (cb) => {
    if (!selectedEvent) return cb && cb(false)
    if (isNewEvent) {
      return DB.removeEvent(selectedEvent.id).then(() => {
        setPersonalEvents(personalEvents.filter(e => e.id !== selectedEvent.id))
        setSelectedEvent(null)
        cb && cb(true)
      })
    }

    const date = new Date(selectedEvent.start)

    Swal.fire({
      title: `Cancel ${selectedEventPatient.firstname}'s booking?`,
      text: `on ${date.toLocaleDateString('se-SE')}`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#dc3545',
      cancelButtonColor: '#333333',
      confirmButtonText: 'Yes, cancel the booking',
      cancelButtonText: 'Nevermind'
    }).then(async result => {
      if (result.isConfirmed) {
        const shouldNotify = selectedEvent.extendedProps?.notify === true && new Date(selectedEvent.start) > new Date()
        await DB.resetNextAppointment(selectedEventPatient.id)

        shouldNotify && Swal.fire({
          title: `Notify ${selectedEventPatient.firstname}?`,
          text: `The booking cancellation notice will be sent via ${notificationMedium}.`,
          icon: 'question',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#333333',
          confirmButtonText: 'Yes, send the notification',
          cancelButtonText: 'No'
        }).then((result) => {
          if (result.isConfirmed) {
            API.notifyPatient(selectedEventPatient.id,
              `Booking cancelation`,
              `This is a booking cancelation notice from Webster Body Therapy.

Canceled: ${moment(selectedEvent.start).format("dddd")}, ${new Date(selectedEvent.start).toLocaleDateString('se-SE')} at ${moment(selectedEvent.start).format("HH:mm")}


Please make contact with ${therapist.firstname} if this seems wrong.

Have a great day,
Webster Body Therapy
`).then(() => Swal.fire(
              `${capitalizeFirstLetter(notificationMedium)} sent!`,
              `${selectedEventPatient.firstname} has been notified.`,
              'success'
            )).catch(e => Swal.fire(
              `${capitalizeFirstLetter(notificationMedium)} notification failed`,
              `${e}`,
              'error'
            ))
          }
        })

        if (selectedEventPatient.newPatient) {
          await API.updatePatientInfo(selectedEventPatient.id,
            { firstVisitBooked: false })
        }

        return DB.removeEvent(selectedEvent.id).then(() => {
          setPersonalEvents(personalEvents.filter(e => e.id !== selectedEvent.id))
          setSelectedEvent(null)
          cb && cb(true)
        })
      } else {
        cb && cb(false)
      }
    })
  }

  useEffect(() => {
    if (!selectedEvent) {
      setWorkshopTitle(null)
      setWorkshopURL(null)
      setTotalSpots(null)
      setDescription(null)
      setAllowNewPatient(null)
      setAllowExistingPatient(null)
    }
  }, [selectedEvent])

  useEffect(() => {
    if (selectedEvent && searchInput.current) {
      searchInput.current.focus()

      const handleKeyPress = async (event) => {
        const searchFieldFocused = document.activeElement === searchInput.current.refs.input

        if (event.keyCode === 13) {
          if (searchFieldFocused && search !== "" && search.length > 2) {
            searchInput.current.blur()
          }
        } else if (event.keyCode === 27) { // ESC
          if (searchFieldFocused && search !== "") {
            setSearch("")
            setSelectedPatientId(null)
          } else {
            setSelectedEvent(null)
          }
        }
      };

      document.addEventListener("keydown", handleKeyPress, false);

      return () => {
        document.removeEventListener("keydown", handleKeyPress, false);
      };
    } else {
      setSearch("")
    }
  }, [selectedEvent, search, searchInput])

  const renderPopUp = () => {
    if (!selectedEvent) return null

    const autoCompStyles = {
      top: '1.5rem',
      left: 0,
      maxHeight: '300px',
      overflowY: 'scroll',
      overflowX: 'hidden',
      position: 'absolute',
      display: search !== "" &&
        search.length > 2 &&
        searchInput.current &&
        document.activeElement === searchInput.current.refs.input ?
          'block': 'none',
    }

    return <PopUp ref={setPopEl} style={styles.popper} {...attributes.popper} classNames="popup">
      <TopRow>
        <PopUpTitle>{
          selectedEventType === "openBooking" ?
            selectedEvent.extendedProps?.allowNewPatient &&
            !selectedEvent.extendedProps?.allowExistingPatient ?
              "Open for new patient" :
            !selectedEvent.extendedProps?.allowNewPatient &&
            selectedEvent.extendedProps?.allowExistingPatient ?
              "Open for existing patient" :
              "Open for booking" :
          selectedEventType === "workshop"    ? "Workshop" :
          selectedEventType === "booking"     ? "Booking" :
                                                "Book patient"
        }</PopUpTitle>
        <CloseButton
          onClick={() => { 
            if (isNewEvent) {
              cancelSelectedEvent()
            } else {
              setSelectedEvent(null)
            }
          }
          }>✕</CloseButton>
      </TopRow>
      <MiddleRow>
        <InfoRow>
          <i className="fas fa-calendar-alt" />
          <span>{
            new Date(selectedEvent.start).toLocaleDateString('se-SE')
          } at {
            new Date(selectedEvent.start).toLocaleTimeString('se-SE')
          }</span>
        </InfoRow>
        <InfoRow>
          <i className="fas fa-clock" />
          <span>{ (new Date(selectedEvent.end).getTime() - new Date(selectedEvent.start).getTime()) / 1000 / 60 } minutes</span>
        </InfoRow>
        { selectedEventType === "workshop" && <InfoRow>
          <i className="fas fa-pen" />
          <Input
            type="text"
            placeholder="Title..."
            value={workshopTitle !== null ? workshopTitle : selectedEvent.extendedProps.workshopTitle}
            onChange={(e) => setWorkshopTitle(e.target.value)}
          />
        </InfoRow>}
        { selectedEventType === "workshop" && <InfoRow>
          <i className="fas fa-globe" />
          <Input
            type="text"
            placeholder="Information URL..."
            value={workshopURL !== null ? workshopURL : selectedEvent.extendedProps.workshopURL}
            onChange={(e) => setWorkshopURL(e.target.value)}
          />
        </InfoRow>}
        { selectedEventType === "workshop" && <InfoRow>
          <i className="fas fa-users" />
          <ParticipantsRow title={
            selectedEvent.extendedProps.participants.map(id => {
              const p = patients.find(p => id === p.id)
              return `${p.firstname} ${p.lastname}
`
            }).join('')
          }><Participants>{
            selectedEvent.extendedProps.participants.length
          }</Participants> participant{ selectedEvent.extendedProps.participants.length > 1 ? 's' : ''} / <TotalSpots
            type="text"
            placeholder="?"
            value={totalSpots !== null ? totalSpots : selectedEvent.extendedProps.totalSpots}
            onChange={(e) => setTotalSpots(e.target.value)}
          />total
          </ParticipantsRow>
        </InfoRow>}
        { selectedEventType === "openBooking" && <InfoRow>
          <i className="fas fa-pen" />
          <Input
            type="text"
            placeholder="Description..."
            value={description !== null ? description : selectedEvent.extendedProps.description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </InfoRow>}
        { selectedEventType === "openBooking" && <InfoRow>
          <span>&nbsp;
            <input
              name="allowNewPatient"
              type="checkbox"
              checked={allowNewPatient !== null ?
                allowNewPatient : selectedEvent.extendedProps.allowNewPatient}
              onChange={(e) => setAllowNewPatient(e.target.checked)} />
           &nbsp;&nbsp;&nbsp; Allow a new patient to book
          </span>
        </InfoRow>}
        { selectedEventType === "openBooking" && <InfoRow>
          <span>&nbsp;
            <input
              name="allowExistingPatient"
              type="checkbox"
              checked={allowExistingPatient !== null ?
                allowExistingPatient : selectedEvent.extendedProps.allowExistingPatient}
              onChange={(e) => setAllowExistingPatient(e.target.checked)} />
           &nbsp;&nbsp;&nbsp; Allow an exsiting patient to book
          </span>
        </InfoRow>}
        { ["booking", "newBooking"].includes(selectedEventType) && <InfoRow>
          <i className="fas fa-user" />
          { !isNewEvent && <span>{selectedEventPatient.lastname} {selectedEventPatient.firstname}</span> }
          { isNewEvent && <InputRow>
            <Autocomplete
              ref={searchInput}
              selectOnBlur={true}
              items={patients}
              shouldItemRender={patientSearch}
              getItemValue={(p) => `${p.lastname} ${p.firstname}`}
              sortItems={(a, b) => a.lastname && b.lastname && a.lastname.localeCompare(b.lastname)}
              autoHighlightValueMatches={(itemValue, value) => {
                return value !== "" && value.length > 2
              }}
              renderItem={(p, isHighlighted) =>
                <div
                  key={p.id}
                  style={{ background: isHighlighted ? 'lightgray' : 'white', cursor: 'pointer' }}
                  className="list-item"
                >
                  {`${p.lastname} ${p.firstname}`}
                </div>
              }
              renderMenu={(items, value, style) => <AutoCompMenu style={{ ...style, ...autoCompStyles}} children={items}/> }
              renderInput={(props) => <Input {...props} placeholder="Name..." />}
              value={newEventPatient && search === "" ? `${newEventPatient.lastname} ${newEventPatient.firstname}` : search}
              onChange={(e) => {
                setSearch(e.target.value)
                if (e.target.value === "") {
                  setSelectedPatientId(null)
                }
              }}
              onSelect={(val, p) => {
                setSearch(val)
                setSelectedPatientId(p.id)
              }}
            />
          </InputRow>}
        </InfoRow> }
        { eventPatient && eventPatient.phone && <InfoRow>
          <i className="fas fa-phone" />
          <InfoDiv href={"tel:" + eventPatient.phone}>{eventPatient.phone}</InfoDiv>
        </InfoRow> }
        { eventPatient && !eventPatient.phone && eventPatient.email && <InfoRow>
          <i className="fas fa-envelope" />
          <InfoDiv href={"mailto:" + eventPatient.email}>{eventPatient.email}</InfoDiv>
        </InfoRow> }
      </MiddleRow>
      <BottomRow>
        { selectedEventType === "newBooking" &&
        <Button
          disabled={!newEventPatient || search === "" || (newEventPatient && search !== `${newEventPatient.lastname} ${newEventPatient.firstname}`)}
          color={colors.purple}
          darkColor={colors.darkPurple}
          onClick={(e) => { e.preventDefault(); newEventPatient && saveSelectedEvent(); }}
          textColor={colors.white}
          textSize="1em"
          title={"Book"}
          className="keep-popup"
        />}

        { selectedEventType === "booking" &&
        <Button
          color={colors.copperRed}
          darkColor={colors.darkCopperRed}
          onClick={(e) => { e.preventDefault(); cancelSelectedEvent() }}
          textColor={colors.white}
          textSize="1em"
          title={"Cancel booking"}
          className="keep-popup"
        />
        }

        { selectedEventType === "workshop" && <>
          <Button
            color={colors.copperRed}
            darkColor={colors.darkCopperRed}
            onClick={(e) => { e.preventDefault(); cancelWorkshop(); }}
            textColor={colors.white}
            textSize="1em"
            title={"Cancel Workshop"}
            className="keep-popup"
          />
          <ButtonSpacer />
          <Button
            disabled={workshopTitle === null && workshopURL === null && totalSpots === null}
            color={colors.purple}
            darkColor={colors.darkPurple}
            onClick={(e) => { e.preventDefault(); saveWorkshopInfo(); }}
            textColor={colors.white}
            textSize="1em"
            title={"Save and publish"}
            className="keep-popup"
          />
        </>}

        { selectedEventType === "openBooking" && <>
          <Button
            color={colors.copperRed}
            darkColor={colors.darkCopperRed}
            onClick={(e) => { e.preventDefault(); closeOpenBooking() }}
            textColor={colors.white}
            textSize="1em"
            title={"Close this time"}
            className="keep-popup"
          />
          <ButtonSpacer />
          <Button
            disabled={allowNewPatient === null && allowExistingPatient === null && description === null}
            color={colors.purple}
            darkColor={colors.darkPurple}
            onClick={(e) => { e.preventDefault(); saveOpenBookingInfo(); }}
            textColor={colors.white}
            textSize="1em"
            title={"Save modifications"}
            className="keep-popup"
          />
        </>}
      </BottomRow>
      <div ref={setArrowEl} style={styles.arrow} className="arrow" />
    </PopUp>
  }

  return (
    <Container onClick={handleOnClick}>
      { view === 'timeGridWeek' && <>
        <FullCalendar
          ref={calendarRef}
          headerToolbar={{
            left: 'prev,next',
            center: 'title',
            right: 'dayGridMonth,timeGridWeek,listWeek'
          }}
          plugins={[ timeGridPlugin, dayGridPlugin, listPlugin, interactionPlugin, momentPlugin ]}
          firstDay={1}
          droppable={true}
          weekends={true}
          slotLabelFormat={{
            hour: 'numeric',
            minute: '2-digit',
            omitZeroMinute: false,
            meridiem: false,
            hour12: false,
          }}
          eventTimeFormat={{
            hour: 'numeric',
            minute: '2-digit',
            omitZeroMinute: false,
            meridiem: false,
            hour12: false,
          }}
          dayHeaderFormat={(info) => {
            return moment(info.date.marker).format("dddd DD")
          }}
          initialView={view}
          viewDidMount={(info) => {
            setView(info.view.type)
          }}
          hiddenDays={[0]}
          nowIndicator={true}
          editable={true}
          selectable={true}
          selectMirror={true}
          dayMaxEvents={true}
          events={events}
          eventColor={therapist.color}
          initialDate={startStr ? startStr : match.params.initialDate}
          datesSet={(info) => {
            window.history.pushState('', '', `/booking/week/${info.startStr.slice(0,10)}`)
            setStartStr(info.startStr)
            setEndStr(info.endStr)
          }}
          allDaySlot={false}
          eventMaxStack={3}
          slotMinTime={"06:00:00"}
          slotMaxTime={"22:00:00"}
          slotDuration={"00:10:00"}
          scrollTime={"08:00:00"}
          scrollTimeReset={false}
          expandRows={true}
          select={handleDateSelect}
          eventDidMount={handleEventDidMount}
          eventContent={renderEventContent} // custom render function
          eventClassNames={selectClassNames}
          eventClick={handleEventClick}
          eventDrop={handleEventModification}
          eventReceive={handleEventReceive}
          weekNumbers={true}
        //eventResizeStop={handleEventModification}
          eventResize={handleEventModification}
          eventsSet={function(){}} // called after events are initialized/added/changed/removed
          /* you can update a remote database when these fire:
          eventAdd={function(){}}
          eventChange={handleEventChange}
          eventRemove={function(){}}
          */
        />
        { renderPopUp() }
        </>
      }

      { view === 'dayGridMonth' && <>
        <FullCalendar
          ref={calendarRef}
          headerToolbar={{
            left: 'prev,next',
            center: 'title',
            right: 'dayGridMonth,timeGridWeek,listWeek'
          }}
          plugins={[ timeGridPlugin, dayGridPlugin, listPlugin, interactionPlugin, momentPlugin ]}
          firstDay={1}
          weekends={true}
          slotLabelFormat={{
            hour: 'numeric',
            minute: '2-digit',
            omitZeroMinute: false,
            meridiem: false,
            hour12: false,
          }}
          eventTimeFormat={{
            hour: 'numeric',
            minute: '2-digit',
            omitZeroMinute: false,
            meridiem: false,
            hour12: false,
          }}
          initialView={view}
          viewDidMount={(info) => {
            setView(info.view.type)
          }}
          dayMaxEvents={true}
          initialDate={startStr ? startStr : match.params.initialDate}
          datesSet={(info) => {
            window.history.pushState('', '', `/booking/month/${info.startStr.slice(0,10)}`)
            setStartStr(info.startStr)
            setEndStr(info.endStr)
          }}
          events={personalEvents}
          eventContent={renderMonthEventContent} // custom render function
          eventColor={therapist.color}
          allDaySlot={false}
          eventMaxStack={3}
          expandRows={true}
          weekNumbers={true}
        />
        </>
      }

      { view === 'listWeek' && <>
        <FullCalendar
          ref={calendarRef}
          headerToolbar={{
            left: 'prev,next',
            center: 'title',
            right: 'dayGridMonth,timeGridWeek,listWeek'
          }}
          plugins={[ timeGridPlugin, dayGridPlugin, listPlugin, interactionPlugin, momentPlugin ]}
          firstDay={1}
          weekends={true}
          slotLabelFormat={{
            hour: 'numeric',
            minute: '2-digit',
            omitZeroMinute: false,
            meridiem: false,
            hour12: false,
          }}
          eventTimeFormat={{
            hour: 'numeric',
            minute: '2-digit',
            omitZeroMinute: false,
            meridiem: false,
            hour12: false,
          }}
          initialView={view}
          viewDidMount={(info) => {
            setView(info.view.type)
          }}
          dayMaxEvents={true}
          hiddenDays={[0]}
          events={events}
          eventContent={renderListEventContent} // custom render function
          eventColor={therapist.color}
          allDaySlot={false}
          eventMaxStack={3}
          expandRows={true}
          initialDate={startStr ? startStr : match.params.initialDate}
          datesSet={(info) => {
            window.history.pushState('', '', `/booking/list/${info.startStr.slice(0,10)}`)
            setStartStr(info.startStr)
            setEndStr(info.endStr)
          }}
        />
        </>
      }

      { view !== 'dayGridMonth' && colleagues.length > 0 && <TherapistLegend
        style={{height: `${legendOpened ? (colleagues.length + 3) * 1.8 : 2.7}em`}}
      >
        <LegendTitle onClick={() => setLegendOpened(!legendOpened)}>
          <i className={`fa ${legendOpened ? 'fa-chevron-down' : 'fa-chevron-right'}`} aria-hidden="true"></i>&nbsp;&nbsp;
          <span>Therapists</span>
        </LegendTitle>

        {legendOpened && <LegendRows>
          <LegendTherapist
            onClick={() => { toggleColleagueBookings(therapist.id) }}
          >
            <LegendDot style={{
              backgroundColor: !disabledTherapists.includes(therapist.id) ? therapist.color : 'transparent',
              borderColor: therapist.color
            }} />&nbsp;&nbsp;
            {`${therapist.firstname} ${therapist.lastname}`}
          </LegendTherapist>

          {colleagues.map(c =>
            <LegendTherapist
              onClick={() => { toggleColleagueBookings(c.id) }}
            >
              <LegendDot style={{
                backgroundColor: !disabledTherapists.includes(c.id) ? c.color : 'transparent',
                borderColor: c.color
              }} />&nbsp;&nbsp;
              {c.name}
            </LegendTherapist>
          )}
        </LegendRows>}

      </TherapistLegend>}

      { view === 'timeGridWeek' && <BottomBar>
        <DraggableEvents id="draggable-events" ref={toolbarRef}>

          <ExternalEvent event={{
            title: "Open for public booking",
            duration: "01:30",
            public: true,
            openBooking: true,
            allowNewPatient: true,
            allowExistingPatient: true,
          }} />
          <ExternalEvent event={{
            title: "Open workshop",
            duration: "02:00",
            public: true,
            workshop: true,
            participants: [],
          }} />

        </DraggableEvents>
      </BottomBar>}
      { updatingBookings && <LoadingContainer>
        <Loading />
      </LoadingContainer>}
    </Container>
  );
};

const PopUp = styled.div`
  font-family: ${fonts.primary};
  z-index: 90;
  background-color: white;
  box-shadow: 0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%), 0px 11px 15px -7px rgb(0 0 0 / 20%);
  border-radius: 10px;
  width: 21rem;
  min-height: 18rem;
  display: flex;
  flex-direction: column;

  animation:mymove 0.25s ease-out forwards;
  animation-iteration-count:1;
  
  /* Safari and Chrome */
  -webkit-animation:mymove 0.25s;
  -webkit-animation-iteration-count:1;

  @keyframes mymove {
    from {top:20px; opacity: 0;}
    to {top:0px; opacity: 1;}
  }
  
  @-webkit-keyframes mymove {
    from {top:20px; opacity: 0;}
    to {top:0px; opacity: 1;}
  }

  .arrow {
    position: absolute;
    width: 10px;
    height: 10px;
    z-index: 89;

    &:after {
      content: " ";
      position: absolute;
      top: 0;
      right: -20.8rem;
      transform: rotate(135deg);
      width: 16px;
      height: 16px;
      background-color: white;
      box-shadow: 0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%), 0px 11px 15px -7px rgb(0 0 0 / 20%);
      box-shadow: -1px -1px 1px rgba(0, 0, 0, 0.2);
    }
  }

  &[data-popper-reference-hidden^='true'] {
    visibility: hidden;
    pointer-events: none;
  }

  &[data-popper-placement^='right'] {
  }

  &[data-popper-placement^='right'] > .arrow {
    &:after {
      right: 2px;
      box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
    }
  }
`

const Container = styled.div`
  flex: 1;
  display: flex;
  width: 100%;
  height: 100%;

  & div.fc-media-screen {
    font-family: ${fonts.primary};
    flex: 1;
    padding: 1em;
    height: 100vh;
  }

  & .fc-scroller {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  & .fc-scroller::-webkit-scrollbar { 
    display: none; 
  }

  & a {
    color: ${colors.black};
    cursor: pointer;
  }

  & .fc .fc-button-primary {
    background-color: ${colors.black};
    border-color: ${colors.black};
  }

  & .fc .fc-button-primary:not(:disabled):active, .fc .fc-button-primary:not(:disabled).fc-button-active {
    background-color: ${colors.darkGrey};
    border-color: ${colors.black};
  }

  & .fc .fc-toolbar-title {
    font-family: ${fonts.secondaryBold};
    font-size: 2.5em;
  }

  & .fc-col-header-cell {
    font-family: ${fonts.secondaryBold};
  }

  & .fc-timegrid-slot {
    font-family: ${fonts.secondary};
  }

  & .fc-event.open-booking {
    filter: brightness(150%);
  }

  & .fc-event.workshop {
    filter: brightness(150%);
  }
`;

const AutoCompMenu = styled.div`
  -ms-overflow-style: none;
  scrollbar-width: none;

  > div {
    padding: 0 0.2rem;
  }

  &::-webkit-scrollbar { 
    display: none; 
  }
`

const Input = styled.input`
  border: 0;
  color: ${colors.black};
  display: flex;
  flex-grow: 1;
  height: 1.2em;
  border-radius: 2px;

  :focus {
    outline-style: none;
    border-radius: 2px;
    border-bottom: 2px solid ${colors.black};
  }
`;

const EventTitleLink = styled.a`
  color: white !important;

  :hover, :focus {
    color: white;
  }
`

const PatientLinkMonth = styled.a`
  color: ${colors.calendarBlue};

  :hover, :focus {
    color: ${colors.calendarBlue};
  }
`

const CloseButton = styled.div`
  color: ${colors.grey};
  cursor: pointer;
  self-align: right;
  font-size: 1.5em;
  
  :hover {
    color: ${colors.black};
  }
`

const PopUpTitle = styled.div`
  font-family: ${fonts.secondaryBold};
  font-size: 1.1em;
`

const TopRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #333333;
  padding: 0.2rem 1rem;
  border-radius: 10px 10px 0 0;
  color: #f7f7f7;
`

const MiddleRow = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1.5rem 1.5rem;
  font-size: 1.1rem;

  > div {
    flex-grow: 1;
  }
`

const InfoRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 1.3rem;
  width: 100%;

  > span {
    flex-grow: 1;
  }
  > i {
    margin-right: 1rem;
  }
`

const InputRow = styled.div`
  position: relative;
  width: 100%;
`

const BottomRow = styled.div`
  flex-grow: 0;
  height: 3rem;
  padding: 0 1rem 1rem 1rem;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
`

const InfoDiv = styled.a`
  cursor: pointer;
  flex-grow: 1;

  &:hover {
    color: ${colors.black};
  }
`

const TherapistLegend = styled.div`
  font-family: ${fonts.primary};
  z-index: 100;
  background-color: ${colors.black};
  color: ${colors.white};
  box-shadow: 0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%), 0px 11px 15px -7px rgb(0 0 0 / 20%);
  border-radius: 10px 0 0 0;
  width: 250px;
  max-height: 350px;
  padding: 10px 16px;
  display: flex;
  flex-direction: column;
  position: fixed;
  bottom: 0px;
  right: 0px;

  transition: all 0.3s ease-out;
`

const LegendTitle = styled.div`
  font-family: ${fonts.secondaryBold};
  font-size: 1.1em;
  cursor: pointer;
`

const LegendRows = styled.div`
  font-family: ${fonts.primary};
  font-size: 1em;
`

const LegendTherapist = styled.div`
  font-family: ${fonts.primary};
  margin-top: 0.3em;
  font-size: 1em;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  :hover {
    color: ${colors.grey};

  }
`

const LegendDot = styled.span`
  height: 1.1em;
  width: 1.1em;
  border-radius: 50%;
  border 3px solid white;
  display: inline-block;
  vertical-align: middle;
`

const BottomBar = styled.div`
  display: flex;
  flex-direction: row;
  position: fixed;
  bottom: 20px;
  align-items: center;
  justify-content: center;
  z-index: 100;
`


const DraggableEvents = styled.div`
  max-width: 550px;
  max-height: 100px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`

const DraggableEvent = styled.div`
  font-family: ${fonts.primary};
  z-index: 100;
  background-color: ${colors.black};
  color: ${colors.white};
  box-shadow: 0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%), 0px 11px 15px -7px rgb(0 0 0 / 20%);
  border-radius: 10px;
  padding: 10px;
  margin-left: 30px;
  cursor: grab;
`

const ButtonSpacer = styled.div`
  width: 2rem;
`

const LoadingContainer = styled.div`
  position: fixed;
  width: 100%;
  top: 15vh;
  z-index: 100;
`

const ParticipantsRow = styled.span`
  flex-grow: 1

  & input {
    display: inline !important;
    width: 150px !important;
  }
`

const Participants = styled.span`
  display: inline;
`

const TotalSpots = styled.input`
  border: 0;
  color: ${colors.black};
  display: inline;
  height: 1.2em;
  width: 1.5em;
  border-radius: 2px;
  text-align: right;
  padding-right: 0.5em;


  :focus {
    outline-style: none;
    border-radius: 2px;
    border-bottom: 2px solid ${colors.black};
  }
`;

export default Booking;
