import React, { useContext } from "react";

import ConnectedSelect from "../ConnectedSelect";
import Input from "../ConnectedInput";

import PatientContext from "../../context/patientContext";
import PatientInfoContext from "../../context/patientInfoContext";

const Lifestyle = () => {
  const {
    weight,
    height,
    activities,
    interests,
    sleep_well,
    sleep_hours_per_night,
    drink_alcohol,
    alcohol_frequency,
    smoker,
    healthy_diet,
    drink_water,
    water_quantity,
    dominant_hand,
    contact_lenses,
    glasses,
    orthotics,
    pregnant,
    due_date,
    allergies,
  } = useContext(PatientContext);

  const {
    getHeader,
    opened,
    editing,
    Container,
    InfoContainer,
    InfoDiv,
    InfoDivBig,
    InfoEdit,
    InfoEditBig,
    Info,
    Text,
  } = useContext(PatientInfoContext);

  const category = "Lifestyle";
  const isOpen = opened(category);
  const isEditing = editing(category);
  const Header = () => getHeader(category, isOpen);

  const bool = (v) => (v === true ? "Yes" : v === false ? "No" : "");

  return (
    <Container>
      <Header />

      {isOpen && !isEditing && (
        <InfoContainer>
          <InfoDiv>
            <Text>Current weight:</Text>
            <Info>{weight}</Info>
          </InfoDiv>
          <InfoDiv>
            <Text>Current height:</Text>
            <Info>{height}</Info>
          </InfoDiv>
          <InfoDivBig>
            <Text>
              Sports / Activities / Exercises. How often and how long for:
            </Text>
            <Info>{activities}</Info>
          </InfoDivBig>
          <InfoDivBig>
            <Text>Hobbies / Interests:</Text>
            <Info>{interests}</Info>
          </InfoDivBig>
          <InfoDiv>
            <Text>Does the patient sleep well:</Text>
            <Info>{bool(sleep_well)}</Info>
          </InfoDiv>
          <InfoDiv>
            <Text>Hours per night:</Text>
            <Info>{sleep_hours_per_night}</Info>
          </InfoDiv>
          <InfoDiv>
            <Text>Does the patient drink alcohol:</Text>
            <Info>{bool(drink_alcohol)}</Info>
          </InfoDiv>
          {drink_alcohol === true && (
            <InfoDiv>
              <Text>How often they drink alcohol:</Text>
              <Info>{alcohol_frequency}</Info>
            </InfoDiv>
          )}
          <InfoDiv>
            <Text>Does the patient smoke:</Text>
            <Info>{bool(smoker)}</Info>
          </InfoDiv>
          <InfoDiv>
            <Text>Does the patient have a healthy diet:</Text>
            <Info>{bool(healthy_diet)}</Info>
          </InfoDiv>
          <InfoDiv>
            <Text>Does the patient drink water:</Text>
            <Info>{bool(drink_water)}</Info>
          </InfoDiv>
          {drink_water === true && (
            <InfoDiv>
              <Text>How much water:</Text>
              <Info>{water_quantity}</Info>
            </InfoDiv>
          )}
          <InfoDiv>
            <Text>Patient's dominant hand:</Text>
            <Info>
              {dominant_hand === "right"
                ? "Right"
                : dominant_hand === "left"
                ? "Left"
                : ""}
            </Info>
          </InfoDiv>
          <InfoDiv>
            <Text>Does the patient wear contacts:</Text>
            <Info>{bool(contact_lenses)}</Info>
          </InfoDiv>
          <InfoDiv>
            <Text>Does the patient wear glasses:</Text>
            <Info>{bool(glasses)}</Info>
          </InfoDiv>
          <InfoDiv>
            <Text>Does the patient wear Orthotics (skor-inlägg):</Text>
            <Info>{bool(orthotics)}</Info>
          </InfoDiv>
          <InfoDiv>
            <Text>Is the patient pregnant:</Text>
            <Info>{bool(pregnant)}</Info>
          </InfoDiv>
          {pregnant === true && (
            <InfoDiv>
              <Text>Patient's due date:</Text>
              <Info>{due_date}</Info>
            </InfoDiv>
          )}
          {allergies && (
            <InfoDivBig>
              <Text>Patient's allergies and reactions:</Text>
              <Info>{allergies}</Info>
            </InfoDivBig>
          )}
        </InfoContainer>
      )}

      {isOpen && isEditing && (
        <InfoContainer>
          <InfoEdit>
            <Text>Current weight</Text>
            <Input dbKey="weight" />
          </InfoEdit>
          <InfoEdit>
            <Text>Current height</Text>
            <Input dbKey="height" />
          </InfoEdit>
          <InfoEditBig>
            <Text>
              Sports / Activities / Exercises. How often and how long for:
            </Text>
            <Input textarea dbKey="activities" />
          </InfoEditBig>
          <InfoEditBig>
            <Text>Hobbies / Interests:</Text>
            <Input textarea dbKey="interests" />
          </InfoEditBig>
          <InfoEdit>
            <Text>Does the patient sleep well</Text>
            <ConnectedSelect dbKey="sleep_well">
              <option value={true}>Yes</option>
              <option value={false}>No</option>
            </ConnectedSelect>
          </InfoEdit>

          <InfoEdit>
            <Text>Hours per night</Text>
            <Input dbKey="sleep_hours_per_night" />
          </InfoEdit>
          <InfoEdit>
            <Text>Does the patient drink alcohol</Text>
            <ConnectedSelect dbKey="drink_alcohol">
              <option value={true}>Yes</option>
              <option value={false}>No</option>
            </ConnectedSelect>
          </InfoEdit>
          <InfoEdit>
            <Text>How often they drink alcohol</Text>
            <Input dbKey="alcohol_frequency" />
          </InfoEdit>
          <InfoEdit>
            <Text>Does the patient smoke</Text>
            <ConnectedSelect dbKey="smoker">
              <option value={true}>Yes</option>
              <option value={false}>No</option>
            </ConnectedSelect>
          </InfoEdit>
          <InfoEdit>
            <Text>Does the patient have a healthy diet</Text>
            <ConnectedSelect dbKey="healthy_diet">
              <option value={true}>Yes</option>
              <option value={false}>No</option>
            </ConnectedSelect>
          </InfoEdit>
          <InfoEdit>
            <Text>Does the patient drink water</Text>
            <ConnectedSelect dbKey="drink_water">
              <option value={true}>Yes</option>
              <option value={false}>No</option>
            </ConnectedSelect>
          </InfoEdit>
          <InfoEdit>
            <Text>How much water</Text>
            <Input dbKey="water_quantity" />
          </InfoEdit>
          <InfoEdit>
            <Text>Patient's dominant hand</Text>
            <ConnectedSelect dbKey="dominant_hand">
              <option value="Right">Right</option>
              <option value="Left">Left</option>
            </ConnectedSelect>
          </InfoEdit>
          <InfoEdit>
            <Text>Does the patient wear contacts</Text>
            <ConnectedSelect dbKey="contact_lenses">
              <option value={true}>Yes</option>
              <option value={false}>No</option>
            </ConnectedSelect>
          </InfoEdit>
          <InfoEdit>
            <Text>Does the patient wear glasses</Text>
            <ConnectedSelect dbKey="glasses">
              <option value={true}>Yes</option>
              <option value={false}>No</option>
            </ConnectedSelect>
          </InfoEdit>
          <InfoEdit>
            <Text>Does the patient wear Orthotics (skor-inlägg)</Text>
            <ConnectedSelect dbKey="orthotics">
              <option value={true}>Yes</option>
              <option value={false}>No</option>
            </ConnectedSelect>
          </InfoEdit>
          <InfoEdit>
            <Text>Is the patient pregnant</Text>
            <ConnectedSelect dbKey="pregnant">
              <option value={true}>Yes</option>
              <option value={false}>No</option>
            </ConnectedSelect>
          </InfoEdit>
          <InfoEdit>
            <Text>Patient's due date</Text>
            <Input dbKey="due_date" />
          </InfoEdit>
          <InfoEditBig>
            <Text>Patient's allergies and reactions</Text>
            <Input dbKey="allergies" />
          </InfoEditBig>
          <InfoEdit />
        </InfoContainer>
      )}
      <hr width="99%" />
    </Container>
  );
};

export default Lifestyle;
